<template>
    <div>
        <v-data-table style="font-family: 'khmer mef1';text-align: center;"
                      class="elevation-1"
                      item-key="_id"
                      :headers="headers"
                      :items="getErrorUserList && getErrorUserList.object ? getErrorUserList.object : []"
                      :footer-props="footerProps"
                      :server-items-length="getErrorUserList ? getErrorUserList.objectCount : null"
                      :page.sync="data.page"
                      :items-per-page.sync="data.rowsPerPage"
                      :sort-desc.sync="data.descendingFormat"
                      :sort-by.sync="data.sortByFormat"
                      @update:page="getData"
                      @update:items-per-page="getData"
                      @update:sort-desc="getData"
                      :loading="getLoading"
                      mobile-breakpoint="0">
            <template v-slot:top>
                <Header :darkmode="darkmode"
                        :params="data"
                        :listModule="getModule"
                        :user-permission="userAccessPermission"
                        @child-checkbox="checkboxValue"
                        v-on:child-checkbox="updateParent"
                        />
            </template>

            <template v-slot:[`item`]="{ item }">
                <tr>
                    <td>{{ getErrorUserList.object.indexOf(item) + 1 }}</td>
                    <td>{{ item.date }}</td>
                    <td>{{ item.group_no }}</td>
                    <td>{{ item.fight_no }}</td>
                    <td>{{ item.user_id }}</td>
                    <td>{{ item.user_name }}</td>
                    <td>{{ item.balance }}</td>
                    <td>
                        <v-btn small class="ml-5" color="success" @click="_refreshSyncData(item.user_id)">
                            {{ $t('button.sync') }}
                        </v-btn>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapMutations } from "vuex";
    import headers from "./_api/columns";
    import headersDetail from "./_api/columns_detail";

    export default {
        components: {
            // ActionComponents: () => import("./_components/action"),
            Header: () => import("./_components/header.vue"),
            
        },
        data: () => ({
            valid: true,
            headers: headers,
            headersDetail: headersDetail,
            footerProps: {
                'items-per-page-options': [10, 20, 50, 100, 200]
            },
            dataRequest:{
                user_id: "",
                module_id: process.env.VUE_APP_COCKFIGHT
            },
            data: {
                descendingFormat: [true],
                sortByFormat: ['_id'],
                descending: true,
                sortBy: '_id',
                page: 1,
                rowsPerPage: 10,
                fields: ["user_name","_id"],
                search: '',
                channel_type: 1,
                module_id: process.env.VUE_APP_COCKFIGHT
            },
            dialog: false,
            loading: false,
            user_iid: null,
            getLoadingDetail:false,
            detailData:[],
            detailDataCount:[],
        }),
        computed: {
            // headers() {
            //     return [
            //         { text: this.$t('header.user_name'), align: 'start', value: 'user_name' },
            //         { text: this.$t('header.status'), value: 'status', sortable: false },
            //         { text: this.$t('header.actions'), value: 'actions', sortable: false }
            //     ];
            // },
            ...mapGetters("$_modules", { darkmode: "getDarkMode" }),
            ...mapGetters("$_menu", ["getTreeMenu"]),
            ...mapGetters("$_errorUserList", ["getErrorUserList", "getLoading","getModule"]),
        },
        async created() {
            await this.fetchUserAccessPermission(this.$route.path)
            await this.fetchModule();
            await this.getData()
        },
        methods: {

            async getData() {
                console.log("data",this.data);
                this.checkUserPermission('VIEW') ? await this.fetchListErrorUserList(this.data) : [];
            },
            _refreshSyncData(user_id){
                console.log(user_id);
                this.user_iid = user_id;
                this.loadRefreshSyncData(user_id);
            },
            checkboxValue: function() {
                
             },
            updateParent(payload){
                
                this.data.module_id = payload.module_id;
                this.data.channel_type = payload.channel_type;
               
            },
            async loadRefreshSyncData(user_id){
                this.dataRequest.user_id = user_id;
                this.dataRequest.module_id = this.data.module_id;
                await this.$request
                    .post({
                    url: "sync-module/syncErrorUser",
                    data: this.dataRequest,
                    })
                    .then((res) => {
                    if (res.data.code) {
                         //window.location.reload();
                    }else{
                        this.getLoadingDetail = false;
                    }
                    });
                await this.getData();
                },
        
            ...mapMutations("$_errorUserList", ["UPDATE_MODULES","LIST_MODULE"]),
            ...mapActions("$_errorUserList", ["fetchListErrorUserList","fetchModule"]),
        },
        async beforeRouteLeave(to, from, next) {
            this.UPDATE_MODULES([])
            next()
        },
    };
</script>
<style lang="scss" scoped>

.to-detail{
  cursor: pointer;
  text-decoration: underline;
}
.amount-bet-color {
  color: rgb(28, 143, 236) !important;
}
</style>
