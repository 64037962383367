/* eslint-disable no-unused-vars */
import Request from '@/services/axios'

let request = new Request;

const fetchListSyncModule = async({ commit }, data) => {
    commit('UPDATE_LOADING', true)
    data.sortByFormat.length < 1 ? data.sortBy = '_id' : data.sortBy = data.sortByFormat[0]
    data.descendingFormat.length > 0 ? data.descending = data.descendingFormat[0] : data.descending = true
    const response = await request.post({
        url: 'sync-module/getAll',
        data
    });
    if (response.data.code) {
        commit('UPDATE_MODULES', response.data.data);
        commit('UPDATE_LOADING', false)
    }
};

export default {
    fetchListSyncModule,
};
