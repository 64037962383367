<template>
    <div>
        <form-login></form-login>
    </div>
</template>

<script>
    import FormLogin from "./_components/login";

    export default {
        components: {
            FormLogin,
        },
    };
</script>

<style lang="scss">
    input:-internal-autofill-selected {
        background: transparent !important;
    }
</style>