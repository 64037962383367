export default {
	home: 'ផ្ទះ',
	invoice: 'វិក័យប័ត្រ',
	patients: 'អ្នកជំងឺ',
	doctors: 'គ្រូពេទ្យ',
	stock: 'ស្តុក',
	services: 'សេវាកម្ម',
	settings: 'ការកំណត់',
	contact: 'ទំនាក់ទំនង',
	new_invoice: 'បង្កើតវិក្កយបត្រថ្មី',
	detail_invoice: 'វិក្កយបត្រលំអិត',
	logout: 'ចាកចេញ'
};
