import statementAgentKhCock from '@/modules/cockFightReport/statementAgentKh';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/statementAgentKhCock',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: statementAgentKhCock,
				name: 'statementAgentKhCock'
			}
		]
	}
];

export default routes;
