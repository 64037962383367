import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    configUserData: {
        _id: null,
        total_bet_a_fight: null,
        total_bet_win_a_day: null,
        user_id: null,
        user_name: null,
    },
    users: [{
        user_id: null,
        user_name :null
    }],
    currentConfigUserData:{},
    loading: false,
    popUp: false,
    deleteItems: [],
    userConfigData:[],
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};