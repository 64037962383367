import statementCompanyUSDM from '@/modules/machineGameReport/statementCompanyUSD';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/statementCompanyUSDM',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: statementCompanyUSDM,
				name: 'statementCompanyUSDM'
			}
		]
	}
];

export default routes;
