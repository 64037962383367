export default {
    search: 'Search',
    permission_name: 'Permission Name',
    role_name: 'Role Name',
    menu_name: 'Menu Name',
    description: 'Description',
    order: 'Order',
    route_name: 'Route Name',
    url_name: 'URL Name',
    icon: 'Icon',
    parent: 'Parent',
    permission: 'Permission',
    visible: 'Visible',
    user_name: 'Username',
    bank_account: 'Bank Account',
    user_role: 'User Role',
    password: 'Password',
    total_bet_a_fight: 'Total Bet In A Match',
    total_bet_win_a_day:'Total Bet In A Day',
    user:"Select User",
    default_bet_a_fight:"Default Bet a Fight",
    default_bet_win_a_day:"Default Bet a Day",
    agent_bet_a_fight:"Set Limit to Agent for their Player In a Fight",
    agent_bet_win_a_day:"Set Limit to Agent for their Player In a Day",
    target :  "Target",
    percentage_meron :  "Percentage of Meron",
    percentage_wala :  "Percenntage of Wala",
    meron_name : "Name of Meron",
    wala_name : "Name of Wala",
    start_time :  "Start Time",
    end_time :  "End Time",
    meron : "Meron",
    child_role_balance: "Banlance of Child",
    child_role_balance_d: "Banlance of Child USD",
    child_role_balance_b: "Banlance of Child Baht",
    child_role_balance_r: "Banlance of Child RIEL",
    child_role_name: "Name Of Child",
    chose_type: "Choose Currency",
    bet_win_a_day: "Total Win a Day",
    bet_win_a_Fight: "Total Win a Match",
    enable:"Enable",
    listAllCompany:"Select Company",
    channel_type:"Select channel Type",
    name: "Name",
    is_enable: "Is Enable",
    is_start: "Is Start",
    updatedAt: "Updated At",
    percentage: "Percentage",
    win_lose_type: "Win Lose Type",
    one_day: "1 Day",
    one_month: "1 Month",
    max_win: "Max Win",
    max_loose: "Max Loose",
    module_name: "ឈ្មោះ",
    sub_link: "Sub Link",
    app_id: "App Id",
    listModule: "List Module",
    small_icon: "Small Icon",
    large_icon: "Large Icon",
    front_player_link: "Front player link",
    api_player_link: "API player link",
    api_admin_link: "API admin link",
    background_icon: "Background icon",
    reverse_result: "Reverse Result",
    select_module: "Select Module",
    start_date: "Start Date",
    end_date: "End Date",
    updateConfig : "Limit Betting",
    channel1:"Channel 1",
    channel2:"Channel 2",
    channel3:"Channel 3",
    channel4:"Channel 4",
    select_currency:"Currency Type",
    barcode: "Barcode",
    dealer_name: "Username",
    dealer_dob: "Date of birth",
    profile_image: "Profile",
    is_pr: "Is PR",
    is_pitbox: "IS PITBOX",

};
