<template>
  <div>
    <div class="image-show">
    <img :src="logoImage" />
  </div>
  <div class="login-container">
  
  <v-form ref="form" lazy-validation @submit.prevent="login">
    <h1 class="text-13 font-bold">Login</h1>
    <span class="text-9 opacity-50">Sign In to your account</span>
    <hr class="my-5 bg-gray-400 opacity-50" />
    <div v-if="error" class="mb-4 text-danger text-center">
      Invalid username or password
    </div>
    <Input
      v-model="user.user_name"
      label="Username"
      :rules="rules"
      required
      placeholder="Enter your username"
      @input="error = false"
    />
    <Input
      v-model="user.password"
      class="mt-3"
      label="Password"
      :rules="rules"
      required
      type="password"
      placeholder="Enter your username"
      @input="error = false"
    />
    <div class="flex justify-end mt-4">
      <v-btn
        :loading="loading"
        type="submit"
        :disabled="loading"
        color="primary"
        style="background-color:#13558B"
      >
        Login
      </v-btn>
    </div>
  </v-form>

  </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    logoImage:"", //images/logo.png
    user: {
      user_name: null,
      password: null,
    },
    rules: [(value) => !!value || "Required."],
    loading: false,
    error: false,
    isOTPShow: false,
    tmpChannelUserId : null,
    show: false,
    isOTPError: false,
    otpErrorMgs:"Wrong OTP",
    tempChannelUser: null
  }),
  methods: {
    reset() {
      this.$refs.form.reset();
    },
    async handleOnComplete(value) {
      try {
        let otpData = {
          user_id : this.tmpChannelUserId,
          otp : value
        }
        const response = await this.fetchVerifyOtp(otpData)
        if (response.data.code) {
            //success
            this.$cookies.set("token", response.data.data.token);
            this.$cookies.set("channel-type", 1);
            this.$cookies.set("local", 'kh');
            this.$cookies.set("user", this.tempChannelUser);
            this.$router.push({ path: "/dashboard" });
        }else{
          this.$refs.otpInput.clearInput();
          this.otpErrorMgs = response.data.message.descriptions;
          this.isOTPError = true;
        }

      }catch(error){
        this.$refs.otpInput.clearInput();
        this.otpErrorMgs = error.message;
        this.isOTPError = true;
      }
    },
    handleOnChange() {
      this.isOTPError = false;
    },
    async login() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          const response = await this.fetchLogIn(this.user);
          if (response.data.code) {
            const { token, uuid, user } = response.data.data;
            if( token == "" ){
              this.$cookies.set("uuid", uuid);
              this.tempChannelUser = user
              this.tmpChannelUserId =  user._id
              this.isOTPShow = true
            } else {
              let rolee = user.role
              this.$cookies.set("token", token);
              this.$cookies.set("user_role", rolee);
              this.$cookies.set("channel-type", 1);
              this.$cookies.set("local", 'kh');
              this.$cookies.set("user", user);
              this.$router.push({ path: "/users" });
            }
        
          } else {
            this.error = true;
          }
        } catch (error) {
          this.error = true;
          return error;
        } finally {
          this.loading = false;
        }
      }
    },
    ...mapActions("$_auth", ["fetchLogIn", "fetchVerifyOtp"]),
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&family=Siemreap&display=swap');
</style>

<style lang="scss" >
.siepream{
  font-family:  'Roboto', 'Siemreap', cursive, sans-serif;
  
}
.info{
  color:#28546C; font-weight:bold; padding-left:25px;
}
.info-top{
  padding-top:10px
}

.otp-layout {
    border: 1px solid hsla(0, 0%, 100%, 0.05);
    border-radius: 4px;
    padding: 20px;
    margin: auto;
    background-color: #1E1E1E;
    width: fit-content; 
    height: fit-content;
  }
  .otp-label{
    color: white;
    text-shadow: #fff;
    font-size: 18px;
    text-align: center;
    margin: 8px;
    margin-bottom:16px;
  }
  .otp-error{
    color: #f34141;
    text-shadow: #f8f6f6;
    font-size: 13px;
    text-align: center;
    margin: 8px;
  }
  .otp-input {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 0 10px;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    &.error {
      border: 1px solid red !important;
    }
  }
  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .login-body {
    background-color: #f39f86;
    background-image: linear-gradient(315deg, #f39f86 0%, #f9d976 74%);
    padding: 0;
    margin: 0;
  }
  .image-show {
  display: flex;
  justify-content: center;
  width: 100%;
}
img {
  width: 41%;
  text-align: center;
  position: absolute;
  top: -160px;
}
.app {
  background-color: #ffe6c2 !important;
}

</style>