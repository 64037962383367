import statementMasterUSDKD from '@/modules/kdReport/statementMasterUSD';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/statementMasterUSDKD',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: statementMasterUSDKD,
				name: 'statementMasterUSDKD'
			}
		]
	}
];

export default routes;
