const headers = [
    // { text: 'សកម្មភាព', align: 'start', value: 'actions', sortable: false },
    {
        text: 'ល.រ',
        align: 'start',
        value: 'no',
    },
    { text: 'IP', value: '' },
    { text: 'កាលបរិច្ឆេទ', value: '' },
    { text: 'ប្រភេទប៉ុស', value: 'channel_type' },
    { text: 'Game No', value: '' },
    { text: 'ប្រភេទចាក់', value: '' },
    { text: 'លទ្ធិផល', value: '' },
    { text: 'សរុបប្រាក់ភ្នាល់', value: '' },
    { text: 'Payout', value: '' },
    { text: 'Bonus', value: '' },
    { text: 'សរុបឈ្នះ/ចាញ់', value: '' },
    { text: 'ប្រាក់សងត្រឡប់', value: '' },
];

export default headers;