/* eslint-disable no-unused-vars */
import Request from '@/services/axios'


let request = new Request;

const fetchGetAllConfigUser = async ({ commit }, data) => {
   
    const response = await request.post({
        url: 'limitBetting/getAll',
        data
    });
    if (response.data.code) {
        commit('UPDATE_CONFIG_USER_DATA', response.data.data);
    }
};
const fetchGetConfigUserByUserId = async ({ commit }, d) => {
    let data = { "user_id": d }
    const response = await request.post({
        url: 'configUser/getConfigUserByUserId',
        data
    });
    if (response.data.code) {
        commit('UPDATE_CONFIG_USER_BY_ID', response.data.data);
    }
};


const createConfigData = async ({ commit }, { vm, data }) => {
    commit('LOADINGRESULT', true);
    const response = await request.post({
        url: 'limitBetting/create',
        data
    });
    if (response.data.code) {
        commit('UPDATE_CONFIG_USER_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }

};



const updateLimit = async ({ commit }, { vm, data, _id }) => {
    commit('LOADINGRESULT', true);
    const response = await request.post({
        url: 'limitBetting/update/'+_id,
        data
    });
    if (response.data.code) {
        commit('UPDATE_CONFIG_USER_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }

};

const fetchPopUp = async ({commit}, payload) => {
    commit('UPDATE_POPUP', payload);
};
const fetchCurrentConfigData = async ({commit}, payload) => {
    commit('UPDATE_CURRENT_DATA', payload);
};

const fetchListUsers = async ({commit}, data) => {
    const response = await request.post({
        url: 'users/getAllByUserId',
        data
    });
    if (response.data.code) {
        commit('UPDATE_USERS', response.data.data.object);
    }
};
const deleteData = async ({commit}, {vm, data}) => {
    const response = await request.post({
        url: 'limitBetting/deleteMany',
        data
    });
    if (response.data.code) {
        commit('DELETE_ITEMS', [])
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else vm.$toastr.e(`${response.data.message.descriptions}`)
};

const updateLimitSetting = async ({ commit }, { vm, data }) => {
    commit('LOADINGRESULT', true);
    let elementId = data._id;
    const response = await request.post({
        url: 'limitBetting/update/'+ elementId,
        data
    });
    if (response.data.code) {
        commit('UPDATE_CONFIG_USER_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const updateConfigUser = async ({ commit }, { vm, d }) => {
    let data = d
    
    const response = await request.post({
        url: 'configUser/update/' + d._id,
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`${response.data.message.descriptions}`)
        commit('UPDATE_POPUP', false)
    } else vm.$toastr.e(`${response.data.message.descriptions}`)
};

const createConfigUser = async ({ commit }, { vm, d }) => {
    let data = d
    const response = await request.post({
        url: 'configUser/create',
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`${response.data.message.descriptions}`)
        commit('UPDATE_POPUP', false)
    } else vm.$toastr.e(`${response.data.message.descriptions}`)
};

export default {
    fetchGetAllConfigUser,
    createConfigData,
    updateLimit,
    fetchPopUp,
    fetchCurrentConfigData,
    fetchListUsers,
    deleteData,
    fetchGetConfigUserByUserId,
    updateLimitSetting,
    updateConfigUser,
    createConfigUser
};