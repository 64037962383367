import winLosePP78Statement from '@/modules/winLoseMachineGameStatement/PP78Statement';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/winLosePP78Statement',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: winLosePP78Statement,
				name: 'winLosePP78Statement'
			}
		]
	}
];

export default routes;
