/* eslint-disable no-unused-vars */
import Request from '@/services/axios'

let request = new Request;

const fetchListRoles = async({ commit }, data) => {
    commit('UPDATE_LOADING', true)
    data.sortByFormat.length < 1 ? data.sortBy = '_id' : data.sortBy = data.sortByFormat[0]
    data.descendingFormat.length > 0 ? data.descending = data.descendingFormat[0] : data.descending = true
    const response = await request.post({
        url: 'roles/getAll',
        data
    });
    if (response.data.code) {
        commit('UPDATE_ROLES', response.data.data);
        commit('UPDATE_LOADING', false)
    }
};

const createRole = async({ commit }, { vm, data }) => {
    const response = await request.post({
        url: 'roles',
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`Successfully created role.`)
        commit('UPDATE_POPUP', false)
    } else vm.$toastr.e(`Fail created role.`)
};

const updateRole = async({ commit }, { vm, data }) => {
    const response = await request.put({
        url: 'roles/' + data._id,
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`Successfully updated role.`)
        commit('UPDATE_POPUP', false)
    } else vm.$toastr.e(`Fail updated role.`)
};

const deleteRole = async({ commit }, { vm, data }) => {
    const response = await request.post({
        url: 'roles/deleteMany',
        data
    });
    if (response.data.code) {
        commit('DELETE_ITEMS', [])
        vm.$toastr.s(`successfully deleted role.`)
    } else vm.$toastr.e(`'failed delete role.`)
};

const fetchPopUp = async({ commit }, payload) => {
    commit('UPDATE_POPUP', payload);
};

const fetchCurrentRole = async({ commit }, data) => {
    const response = await request.get({
        url: 'roles/' + data._id,
        data
    });
    if (response.data.code) {
        commit('UPDATE_CURRENT_ROLE', response.data.data.object);
        commit('UPDATE_SELECT_PERMISSION', response.data.data.object.menu)
    }
};

const fetchRolesWithoutBody = async({ commit }) => {
    const response = await request.post({
        url: 'roles/getAllRolesWithoutBodyField',
    });
    if (response.data.code) {
        commit('UPDATE_ROLES_WITHOUT_BODY', response.data.data);
    }
}

const fetchAllRolesDependOnUser = async({ commit }) => {
    const response = await request.get({
        url: 'roles/getAllRolesDependOnUser',
    });
    if (response.data.code) {
        commit('UPDATE_ROLES_DEPEND_ON_USER', response.data.data.object);
    }
}

export default {
    fetchListRoles,
    fetchPopUp,
    fetchCurrentRole,
    createRole,
    updateRole,
    deleteRole,
    fetchRolesWithoutBody,
    fetchAllRolesDependOnUser
};
