import statementCompanyKhM from '@/modules/machineGameReport/statementCompanyKh';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/statementCompanyKhM',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: statementCompanyKhM,
				name: 'statementCompanyKhM'
			}
		]
	}
];

export default routes;
