import statement from '@/modules/statement';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/statement',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: statement,
				name: 'statement'
			}
		]
	}
];

export default routes;
