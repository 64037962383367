<template>
    <div>
        <v-data-table style="font-family: 'khmer mef1'"
                      class="elevation-1"
                      item-key="_id"
                      :headers="headers"
                      :items="getModules && getModules.object ? getModules.object : []"
                      :footer-props="footerProps"
                      :server-items-length="getModules ? getModules.objectCount : null"
                      :page.sync="data.page"
                      :items-per-page.sync="data.rowsPerPage"
                      :sort-desc.sync="data.descendingFormat"
                      :sort-by.sync="data.sortByFormat"
                      @update:page="getData"
                      @update:items-per-page="getData"
                      @update:sort-desc="getData"
                      :value="getDeleteItems"
                      @input="itemDelete"
                      :loading="getLoading"
                      show-select
                      mobile-breakpoint="0">
            <template v-slot:[`item.is_enable`]="{ item }">
                <v-icon
                v-if="item.is_enable == true"
                small>mdi-checkbox-marked-circle-outline
                </v-icon>
                <v-icon
                v-else-if="item.is_enable === false"
                small>mdi-checkbox-blank-circle-outline
                </v-icon>
            </template>
            <template v-slot:top>
                <Header :darkmode="darkmode"
                        :params="data"
                        :dataDelete="getDeleteItems"
                        :user-permission="userAccessPermission" />
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <ActionComponents :darkmode="darkmode"
                                  :item="item"
                                  :dataDelete="getDeleteItems"
                                  :params="data"
                                  :user-permission="userAccessPermission" />
            </template>
        </v-data-table>
        <Dialog :darkmode="darkmode"
                :loading="loading"
                :appModule="getCurrentModule"
                :dialog="getPopUp"
                :optionModule="getParentModule"
                :is-validate.sync="valid"
                v-on:submit="handleSubmit"
                v-on:onCloseDialog="fetchPopUp(false)" />
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapMutations } from "vuex";

    export default {
        components: {
            ActionComponents: () => import("./_components/action"),
            Header: () => import("./_components/header.vue"),
            Dialog: () => import("./_components/dialog.vue"),
        },
        data: () => ({
            valid: true,
            footerProps: {
                'items-per-page-options': [10, 20, 50, 100, 200]
            },
            data: {
                descendingFormat: [true],
                sortByFormat: ['_id'],
                descending: true,
                sortBy: '_id',
                page: 1,
                rowsPerPage: 10,
                fields: ['name', 'desc','front_player_link','api_player_link','api_admin_link','background_icon'],
                search: ''
            },
            loading: false
        }),
        computed: {
            headers() {
                return [
                    { text: this.$t('header.name'), align: 'start', value: 'name' },
                    { text: this.$t('header.description'), value: 'desc', sortable: false },
                    { text: this.$t('header.front_player_link'), value: 'front_player_link', sortable: false },
                    { text: this.$t('header.api_player_link'), value: 'api_player_link', sortable: false },
                    { text: this.$t('header.api_admin_link'), value: 'api_admin_link', sortable: false },
                    { text: this.$t('header.background_icon'), value: 'background_icon', sortable: false },
                    { text: this.$t('header.app_id'), value: 'app_id', sortable: false },
                    { text: this.$t('header.is_enable'), value: 'is_enable', sortable: false },
                    { text: this.$t('header.actions'), value: 'actions', sortable: false },
                ];
            },
            ...mapGetters("$_modules", { darkmode: "getDarkMode" }),
            ...mapGetters("$_menu", ["getTreeMenu"]),
            ...mapGetters("$_appModule", ["getModules", "getPopUp", "getCurrentModule", "getDeleteItems", "getLoading", "getParentModule"]),
        },
        async created() {
            await this.fetchUserAccessPermission(this.$route.path)
            await this.getData()
        },
        methods: {
            async handleSubmit() {
                this.loading = true
                if (!this.getCurrentModule._id) {
                    await this.createModule({ vm: this, data: this.getCurrentModule })
                } else {
                    await this.updateModule({ vm: this, data: this.getCurrentModule })
                }
                await this.getData()
                this.loading = false
            },

            async getData() {
                this.checkUserPermission('VIEW') ? await this.fetchListModules(this.data) : [];
                this.fetchParentModule();
            },

            itemDelete(e) {
                const items = e.map(function (e) {
                    return { _id: e._id }
                })
                this.DELETE_ITEMS(items)
            },
            ...mapMutations("$_appModule", ["DELETE_ITEMS", "UPDATE_MODULES"]),
            ...mapActions("$_appModule", ["fetchListModules", 'fetchPopUp', "createModule", "updateModule", "fetchParentModule"]),
        },
        async beforeRouteLeave(to, from, next) {
            this.UPDATE_MODULES([])
            next()
        },
    };
</script>
