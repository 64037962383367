import syncModule from '@/modules/syncModule';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/sync-module',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: syncModule,
				name: 'syncModule'
			}
		]
	}
];

export default routes;
