export default {
    permission_list: 'Permission List',
    role_list: 'Role List',
    user_list: 'User List',
    menu_list: 'Menu List',
    create_permission: 'Create Permission',
    update_permission: 'Update Permission',
    create_role: 'Create Role',
    update_role: 'Update Role',
    create_user: 'Create User',
    update_user: 'Update User',
    create_menu: 'Create Menu',
    update_menu: 'Update Menu',
    change_password_user: 'Change Password User',
    welcome: 'Welcome:',
    account_balance: 'Account Balance',
    user_role: 'User Role',
    create_configUser : "Create Config User",
    update_configUser : "Update Config User",
    config_user :'Config User',
    limitBetting: "Limit Betting For Agent",
    payOut: "Pay Out",
    roleLevel: "Role Level",
    update_payOut: "Updating PayOut",
    update_roleLevel: "Updating Role Level",
    user_limit_betting: "Limit Betting",
    total:"Total",
    enablePlayers:"Players Controller",
    lottoSetting: "Lotto Setting",
    create_lottoSetting: "Create Lotto Setting",
    update_lottoSetting: "Update Lotto Setting",
    create_module: "Create Module",
    update_module: "Update Module",
    module_list: "Module List",
    transaction_player: "Transaction Player",
    sync_module: "List Sync Module",
    sync_user_detail: "Synce User Detail",
    widthdrawStatement:"Widthdraw Statement",
    depositStatement:"Deposit Statement",
    statementCompanyKh: "Company Statement",
    statementSeniorKh: "Senior Statement",
    statementMasterKh: "Master Statement",
    statementAgentKh: "Agent Statement",
    list_user_ip:"List Login ip",
    statementCompanyUSD: "Company Statement",
    statementSeniorUSD: "Senior Statement",
    statementMasterUSD: "Master Statement",
    statementAgentUSD: "Agent Statement",

    error_user_list: "Error User List",
    create_dealer: "Create Dealer",
    update_dealer: "Update Dealer",
    dealer_title: "Dealer List",
    user_config_betting: "កំណត់ការភ្នាល់: ",
    duplicate_transaction : "Duplicate Transaction List",
};
