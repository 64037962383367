import winLoseRatStatement from '@/modules/winLoseSNKStatement/ratStatement';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/winLoseRatStatement',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: winLoseRatStatement,
				name: 'winLoseRatStatement'
			}
		]
	}
];

export default routes;
