import statementAgentUSDSNK from '@/modules/snkReport/statementAgentUSD';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/statementAgentUSDSNK',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: statementAgentUSDSNK,
				name: 'statementAgentUSDSNK'
			}
		]
	}
];

export default routes;
