const UPDATE_USERS = (state, data) => {
    state.users = data;
};

const UPDATE_LOADING = (state, data) => {
    state.loading = data
}

// 
const UPDATE_LOADING_CONFIG = (state, data) => {
    state.loadingConfig = data
}

const UPDATE_LIMIT_DATA = (state, data) => {
    state.limitData = data
}
const LIST_MODULE = (state, data) => {
    let obj = {
        _id: null,
        name: "All"
    }
    data.unshift(obj);
    state.listModule = data
}

export default {
    UPDATE_LOADING,
    UPDATE_USERS,
    UPDATE_LIMIT_DATA,
    UPDATE_LOADING_CONFIG,
    LIST_MODULE
};
