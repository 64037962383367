<template>
    <div>
        <v-data-table style="font-family: 'khmer mef1';text-align: center;"
                      class="elevation-1"
                      item-key="_id"
                      :headers="headers"
                      :items="getSyncModule && getSyncModule.object ? getSyncModule.object : []"
                      :footer-props="footerProps"
                      :server-items-length="getSyncModule ? getSyncModule.objectCount : null"
                      :page.sync="data.page"
                      :items-per-page.sync="data.rowsPerPage"
                      :sort-desc.sync="data.descendingFormat"
                      :sort-by.sync="data.sortByFormat"
                      @update:page="getData"
                      @update:items-per-page="getData"
                      @update:sort-desc="getData"
                      :loading="getLoading"
                      mobile-breakpoint="0">
            <template v-slot:top>
                <Header :darkmode="darkmode"
                        :params="data"
                        :user-permission="userAccessPermission" />
            </template>

            <template v-slot:[`item`]="{ item }">
                <tr>
                    <td>{{ getSyncModule.object.indexOf(item) + 1 }}</td>
                    <td class="amount-bet-color to-detail" @click="_goToDetail(item._id)">{{ item.user_name }}</td>
                    <td>{{ item.balance }}</td>
                    <td>
                        <v-btn small class="ml-5" color="success" @click="_refreshSyncData(item._id)">
                            {{ $t('button.sync') }}
                        </v-btn>
                        <v-btn small class="ml-5" color="warning" @click="_goToDetail(item._id)">
                            {{ $t('button.detail_data') }}
                        </v-btn>
                        <!-- <v-icon small class="mr-2" @click="_refreshSyncData(item._id)">
                        mdi-refresh
                        </v-icon> -->
                    </td>
                </tr>
            </template>
        </v-data-table>
    <v-dialog
        :value="dialog"
        max-width="1200"
        persistent
        transition="dialog-bottom-transition"
    >
      <v-card >
        <v-toolbar>
          <v-btn icon  @click="dialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="pl-5">
            <v-col cols="6" sm="6" md="3" class="text-left mt-4">
              <v-toolbar-title>{{ $t('title.sync_user_detail') }}</v-toolbar-title>
            </v-col>
            <v-col cols="6" sm="6" md="3" class="text-left mt-4">
                
            </v-col>
              <v-col cols="8" sm="8" md="4" class="text-right">
                <v-text-field
                    append-icon="mdi-magnify"
                    label="Search ( អ្នកប្រើប្រាស់, Module )"
                    single-line
                    hide-details
                    clearable
                    class="mr-5"
                />
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="4" sm="4" md="2" class="text-right mb-5">
                <v-btn
                    class="white--text btn-custom mr-4 mt-3"
                    color="green darken-1"
                    depressed
                >
                  <!-- @click="fetchListStatement(params)" -->
                  SEARCH
                </v-btn>
              </v-col>
          </v-row>
          <v-data-table
              style="font-family: 'khmer mef1'"
              class="elevation-1"
              item-key="date"
              :headers="headersDetail"
              :footer-props="footerProps"
              :items="detailData ? detailData : []"
              :server-items-length="detailDataCount ? parseInt(detailDataCount.count): null"
              :sort-desc.sync="dataRequest.descendingFormat"
              :sort-by.sync="dataRequest.sortByFormat"
              :page.sync="dataRequest.page"
              :items-per-page.sync="dataRequest.rowsPerPage"
              :loading="getLoadingDetail"
              mobile-breakpoint="0"
          >
           <template v-slot:[`item`]="{ item }">
              <tr>
                <td>{{ detailData ? (detailData.indexOf(item) + ( (dataRequest.page -1 ) *  dataRequest.rowsPerPage)) + 1 :"loading ..." }}</td>
                <td>{{ item.user_name }}</td>
                <td>{{ item.module_name }}</td>
                <td>{{ item.status == 0? "Pendding" : "Done" }}</td>
                <td :style="item.is_active == 'false'
                    ? 'color:#BA2D4A'
                    : 'color:#39afd6'
                    ">
                    {{ item.is_active == "true" ? "សកម្ម" : "អសកម្ម"}}
                </td>
                <td>
                    <v-btn v-if="item.status ==0" small class="ml-5" color="warning"  @click="_refreshSyncData(item.user_id)">
                        {{ $t('button.sync') }}
                    </v-btn>
                    <!-- <v-icon v-if="item.status ==0" small class="mr-2" @click="_refreshSyncData(item.user_id)">
                        mdi-refresh
                    </v-icon> -->
                </td>
              </tr>
            </template>
            <!-- <template v-slot:[`item.is_active`]="{ item }">
                <span v-if="item.is_active == 'false'" style="color:#BA2D4A">{{item.is_active}}</span>
                <span v-else style="color:#39afd6">{{item.is_active}}</span>
            </template> -->
           
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapMutations } from "vuex";
    import headers from "./_api/columns";
    import headersDetail from "./_api/columns_detail";

    export default {
        components: {
            // ActionComponents: () => import("./_components/action"),
            Header: () => import("./_components/header.vue"),
            
        },
        data: () => ({
            valid: true,
            headers: headers,
            headersDetail: headersDetail,
            footerProps: {
                'items-per-page-options': [10, 20, 50, 100, 200]
            },
            dataRequest:{
                descendingFormat: [true],
                sortByFormat: ["_id"],
                descending: true,
                sortBy: null,
                page: 1,
                rowsPerPage: 10,
                user_id: "",
                fields: ["user_name","_id"],
                search: "",
                channelType:  0,
            },
            data: {
                descendingFormat: [true],
                sortByFormat: ['_id'],
                descending: true,
                sortBy: '_id',
                page: 1,
                rowsPerPage: 10,
                fields: ["user_name","_id"],
                search: ''
            },
            dialog: false,
            loading: false,
            user_iid: null,
            getLoadingDetail:false,
            detailData:[],
            detailDataCount:[],
        }),
        computed: {
            // headers() {
            //     return [
            //         { text: this.$t('header.user_name'), align: 'start', value: 'user_name' },
            //         { text: this.$t('header.status'), value: 'status', sortable: false },
            //         { text: this.$t('header.actions'), value: 'actions', sortable: false }
            //     ];
            // },
            ...mapGetters("$_modules", { darkmode: "getDarkMode" }),
            ...mapGetters("$_menu", ["getTreeMenu"]),
            ...mapGetters("$_syncModule", ["getSyncModule", "getLoading"]),
        },
        async created() {
            await this.fetchUserAccessPermission(this.$route.path)
            await this.getData()
        },
        methods: {

            async getData() {
                this.checkUserPermission('VIEW') ? await this.fetchListSyncModule(this.data) : [];
            },
            _goToDetail(user_id){
                this.user_iid = user_id;
                this.loadDetailData(user_id);
                this.dialog = true;
            },
            _refreshSyncData(user_id){
                
                this.user_iid = user_id;
                this.loadRefreshSyncData(user_id);
            },

            async loadRefreshSyncData(user_id){
                this.detailData = [];
                this.detailDataCount = [];
                this.getLoadingDetail = true;
                this.dataRequest.user_id = user_id;
                
                await this.$request
                    .post({
                    url: "sync-module/refreshSyncData",
                    data: this.dataRequest,
                    })
                    .then((res) => {
                    if (res.data.code) {
                        //console.log(res.data);
                         window.location.reload();
                    }else{
                        this.getLoadingDetail = false;
                    }
                    });
                },
            // async loadRefreshSyncData(user_id){
            //     // console.log(user_id);
            //     this.detailData = [];
            //     this.detailDataCount = [];
            //     this.getLoadingDetail = true;
            //     // this.dataRequest.user_id = user_id;
            //     // let data = {
            //     //     user_id : user_id
            //     // };
            //     let respon = await this.$request
            //         .get({
            //         url: "/api/module/sync_user/?user_id="+user_id,
            //         })
                    
            //         if(respon.status == 200){
            //             window.location.reload();
            //         }
            // },
            
            
            async loadDetailData(user_id){
                this.detailData = [];
                this.detailDataCount = [];
                this.getLoadingDetail = true;
                
                this.dataRequest.startDate = this.data.startDate;
                this.dataRequest.endDate = this.data.endDate;
                this.dataRequest.sortByFormat.length < 1
                    ? (this.dataRequest.sortBy = "_id")
                    : (this.dataRequest.sortBy = this.dataRequest.sortByFormat[0]);
                this.dataRequest.descendingFormat.length > 0
                    ? (this.dataRequest.descending = this.dataRequest.descendingFormat[0])
                    : (this.dataRequest.descending = true);

                this.dataRequest.user_id = user_id;
                
                await this.$request
                    .post({
                    url: "sync-module/getSyncModuleByUser",
                    data: this.dataRequest,
                    })
                    .then((res) => {
                    if (res.data.code) {
                        this.detailData = res.data.data.object;
                        this.detailDataCount = res.data.data.objectCount;
                        this.getLoadingDetail = false;
                    }else{
                        
                        this.getLoadingDetail = false;
                    }
                    });
                },
            ...mapMutations("$_syncModule", ["UPDATE_MODULES"]),
            ...mapActions("$_syncModule", ["fetchListSyncModule"]),
        },
        async beforeRouteLeave(to, from, next) {
            this.UPDATE_MODULES([])
            next()
        },
    };
</script>
<style lang="scss" scoped>

.to-detail{
  cursor: pointer;
  text-decoration: underline;
}
.amount-bet-color {
  color: rgb(28, 143, 236) !important;
}
</style>
