/* eslint-disable no-unused-vars */
import Request from '@/services/axios'

let request = new Request;

const fetchListStatement = async({ commit }) => {

    const response = await request.get({
        url: 'getViewOutStanding6number',
    });
    if (response.data.code) {
        commit('CHANNEL_1', response.data.channel);
        // commit('CHANNEL_2', response.data.channel2);
        // commit('CHANNEL_3', response.data.channel3);
        // commit('CHANNEL_4', response.data.channel4);
    }

};

export default {
    fetchListStatement,
    
};