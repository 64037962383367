/* eslint-disable no-unused-vars */
import Request from '@/services/axios'

let request = new Request;

const fetchListStatement = async({ commit }, preData) => {
    commit('UPDATE_LOADING', true)
    let data = preData.data
    data.sortByFormat.length < 1 ? data.sortBy = '_id._id' : data.sortBy = data.sortByFormat[0]
    data.descendingFormat.length > 0 ? data.descending = data.descendingFormat[0] : data.descending = true
    const response = await request.post({
        url: '/getViewWinLoseCock',
        data,
    });
    if (response.data.code) {
        
        commit('LIST_STATEMENT', response.data.data);
        commit('COUNT_STATEMENT', response.data.data.objectCount);
        commit('UPDATE_LOADING', false)
    }

};

export default {
    fetchListStatement
};