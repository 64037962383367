<template>
  <div>
    <!-- sort-by="user_name" -->
    <v-data-table
      :headers="headers"
      :items="getStatement ? getStatement.object : []"
      :server-items-length="getCountStatement? parseInt(getCountStatement.count) : null"
      item-key="_id._id"
      class="elevation-1"
      style="font-family: 'khmer mef1'"
      :footer-props="footerProps"
      :page.sync="data.page"
      :items-per-page.sync="data.rowsPerPage"
      :sort-desc.sync="data.descendingFormat"
      :sort-by.sync="data.sortByFormat"
      @update:page="getData"
      @update:items-per-page="getData"
      @update:sort-desc="getData"
      :loading="getLoading"
      mobile-breakpoint="0"
    >
      <template v-slot:top>
        <Header
          :darkmode="darkmode"
          :params="data"
          :listModule="getModule"
          :user="user"
          :time="time"
          @child-checkbox="checkboxValue"
          v-on:child-checkbox="updateParent"
        />
        <div class="between w-full mb-3 mt-0 pr-3 ">
          <v-btn
            v-for="(item, i) in defaultFilterDates"
            small
            color="success"
            class="mx-4 mb-4"
            :key="i"
            @click="selectDate(item)"
          >
            <span style="color: white">{{ item.name }}</span>
          </v-btn>
        </div>
      </template>

      <template v-slot:[`item`]="{ item }">
        <tr>
          <td>{{ (getStatement.object.indexOf(item)  + ( (data.page -1 ) *  data.rowsPerPage))+ 1 }}</td>
           <td >{{ user.role_name=="DEVELOPER" || user.role_name=="MANAGER" || user.role_name=="CONTROLLER" || user.role_name=="COMPANY" || user.role_name=="SPECIAL-MANAGER"? item._id.com :'---' }}</td>
          <td>{{  user.role_name=="DEVELOPER" || user.role_name=="MANAGER" || user.role_name=="CONTROLLER"|| user.role_name=="COMPANY" || user.role_name=="SPECIAL-MANAGER" || user.role_name=="SENIOR-B"|| user.role_name=="SENIOR-D"|| user.role_name=="SENIOR-R" ? item._id.admin :'---'  }}</td>
          <td>{{ item._id.master }}</td>
          <td>{{ currencyFormat(item._id.master_balance) }}</td>
          <td class="amount-bet-color to-detail" >{{ currencyFormat(item.amount) }}</td>
          <td :class="item.amount_win  < 0 ? 'lose-color' : 'win-color'">{{ currencyFormat(item.amount_win ) }}</td>
        </tr>
      </template>
      <template slot="body.append">
        <tr>
          <td :colspan="5" class="text-xs-center pl-4 py-2 ">
            {{ $t("title.total") }}
          </td>
          <td class="text-xs-center total-style pl-4 amount-bet-color py-2">
            {{
              getCountStatement.amount
                ? currencyFormat(getCountStatement.amount)
                : 0
            }}
          </td>
          <td
            class="text-xs-center pl-4 total-style font-weight-bold  py-2"
            :style="
              getCountStatement.amount_win  < 0
                ? 'color:#BA2D4A'
                : 'color:#39afd6'
            "
          >
            {{
              getCountStatement.amount_win 
                ? currencyFormat(
                    getCountStatement.amount_win 
                  )
                : 0
            }}
          </td>
        </tr>
      </template>
    </v-data-table>
    
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import headers from "./_api/columns";
import moment from "moment";

export default {
  components: {
    // ActionComponents: () => import("./_components/Action"),
    Header: () => import("./_components/HeaderTable.vue"),
  },

  data: () => ({
    valid: true,
    headers: headers,
    search: "",
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    data: {
      descendingFormat: [false],
      sortByFormat: ["amount_win"],
      descending: false,
      sortBy: "amount_win",
      page: 1,
      rowsPerPage: 10,
      fields: [
       "_id.com_id", "_id.com", "_id.con_balance", "_id.admin_id", "_id.admin", "_id.admin_balance", "_id.master_id", "_id.master", "_id.master_balance", "amount", "amount_win"
      ],
      search: "",
      startDate:  moment().format("YYYY-MM-DD"),
      endDate:  moment().format("YYYY-MM-DD"),
      module_id: null
    },
    dialog: false,
    loading: false,
    loader: false,
    user_id: null,
    check: {
      startDate: moment().format("YYYY-MM-DD"),
      endDate:  moment().format("YYYY-MM-DD"),
    },
    format: "YYYY-MM-DD",
    defaultFilterDates: [
      {
        name: "Today",
        active: false,
      },
      {
        name: "Yesterday",
        active: false,
      },
      {
        name: "This Week",
        active: false,
      },
      {
        name: "Last Week",
        active: false,
      },
      {
        name: "This Month",
        active: false,
      },
      {
        name: "Last Month",
        active: false,
      },
    ],
    totalItems: 0,
    time: null,
  }),
  watch: {
    user: function() {
      this.getData();
    },
    data: function() {
      this.getData();
    },
    getStatement: function() {},
  },
  computed: {
    reportUrl: function() {
      if (this.user) {
        if (this.user.role_name == "DEVELOPER" || this.user.role_name == "MANAGER") return "getViewMasterSummaryKhAll";
        else if (this.user.role_name == "COMPANY" || this.user.role_name == "SUB-COMPANY") return "getViewMasterSummaryKhAll";
        else if (this.user.role_name == "SENIOR-R" || this.user.role_name == "SUB-SENIOR-R") return "getViewMasterSummaryKhAll";
        else if (this.user.role_name == "MASTER-R" || this.user.role_name == "SUB-MASTER-R") return "getViewMasterSummaryKhAll";
        else if (this.user.role_name == "AGENT-R" || this.user.role_name == "SUB-AGENT-R") return "getViewMasterSummaryKhAll";
        else return null;
      } else return null;
    },
    ...mapGetters("$_modules", { darkmode: "getDarkMode", user: "getUser" }),
    ...mapGetters("$_MasterKhAll", ["getStatement", "getCountStatement","getLoading","getModule"]),
  },
  async created() {
    await this.getData();
    await this.fetchModule();
  },
  methods: {
    moment,
    selectDate({ name }) {
      const { format } = this;
      switch (name) {
        case "Today":
          this.startDate = this.moment().format(format);
          this.endDate = this.moment().format(format);
          break;
        case "Yesterday":
          this.startDate = this.moment()
            .subtract(1, "days")
            .format(format);
          this.endDate = this.moment().subtract(1, "days").format(format);
          break;
        case "This Week":
          this.startDate = this.moment()
            .startOf("isoweek")
            .format(format);
          this.endDate = this.moment()
            .endOf("isoWeek")
            .format(format);
          break;
        case "Last Week":
          this.startDate = this.moment()
            .subtract(1, "isoweek")
            .startOf("isoweek")
            .format(format);
          this.endDate = this.moment()
            .subtract(1, "isoweek")
            .endOf("isoweek")
            .format(format);
          break;
        case "This Month":
          this.startDate = this.moment().format("YYYY-MM-01");
          this.endDate = this.moment().format(format);
          break;
        case "Last Month":
          this.startDate = this.moment()
            .subtract(1, "months")
            .startOf("month")
            .format(format);
          this.endDate = this.moment()
            .subtract(1, "months")
            .endOf("month")
            .format(format);
          break;
        default:
          break;
      }
      const { startDate, endDate } = this;
      this.time = { startDate: startDate, endDate: endDate };
      this.data.startDate = startDate;
      this.data.endDate = endDate;
      this.getData();
      this.check = { startDate, endDate };
    },
    // Gets the checkbox information from the child component
    checkboxValue: function(params) {
      this.check = params;
      this.data.startDate = params.startDate;
      this.data.endDate = params.endDate;
    },
    currencyFormat(num) {
      num = parseFloat(num);
      // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return parseInt(num).toLocaleString('en')
    },
    updateParent(payload){
      this.data.startDate = payload.startDate;
      this.data.endDate = payload.endDate;
      this.data.module_id = payload.module_id;
    },
    async getData() {
      try {
        this.loader = true;
          let preData = {
            data: this.data,
            reportUrl: this.reportUrl,
          };
          // console.log(preData)
          await this.fetchListStatement(preData)
          this.loader = false;
      } catch (error) {
        // console.log("")
      }
    },
    ...mapMutations("$_MasterKhAll", ["LIST_STATEMENT", "COUNT-STATEMENT","LIST_MODULE"]),
    ...mapActions("$_MasterKhAll", ["fetchListStatement","fetchModule"]),
  },
};
</script>

<style lang="scss" scoped>
.users {
  width: 50%;
  float: left;

  h3 {
    font-size: 17px;
  }
}

table {
  font-family: "khmer mef1" !important;
}
.amount-bet-color {
  color: goldenrod !important;
}
.win-color {
  color: rgb(57, 175, 214);
  font-weight: bold;
}
.lose-color {
  color: #ba2d4a;
  font-weight: bold;
}
.tab-filter {
  display: flex;
  flex-direction: row;
}
.row {
  display: flex;
  flex-direction: row;
}
.between {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.w-full {
  width: 100%;
}

</style>
