/* eslint-disable no-unused-vars */
import Request from '@/services/axios'

let request = new Request;

const fetchListStatement = async({ commit }, preData) => {
    commit('UPDATE_LOADING', true)
    let data = preData.data
    let reportUrl = preData.reportUrl
    data.sortByFormat.length < 1 ? data.sortBy = '_id._id' : data.sortBy = data.sortByFormat[0]
    data.descendingFormat.length > 0 ? data.descending = data.descendingFormat[0] : data.descending = true
    const response = await request.post({
        url: 'adminReport/' + reportUrl+"/null",
        data,
    });
    if (response.data.code) {
        // console.log(response.data.data)
        commit('LIST_STATEMENT', response.data.data);
        commit('COUNT_STATEMENT', response.data.data.objectCount);
         commit('UPDATE_LOADING', false)
    }

};
const fetchModule = async({commit}) => {
    let module_id = process.env.VUE_APP_KD855;
    let data = {
        _id: module_id
    };
    const response = await request.post({
        url: 'app-modules/getModuleNameById',
        data
    });
    if (response.data.code) {
    
        commit('LIST_MODULE', response.data.data.object);
    }
}

export default {
    fetchListStatement,
    fetchModule
};