import Input from './input';
import BtnPrimary from './button/primary';
import ToolbarTable from './header/toolbarTable';
import Loading from './loading';

const Components = {
	Input,
	BtnPrimary,
	ToolbarTable,
	Loading
};

const ComponentsInit = {
	install(Vue) {
		Object.keys(Components).forEach((name) => {
			Vue.component(name, Components[name]);
		});
	}
};

export default ComponentsInit;
