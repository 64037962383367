<template>
    <div class="app">
        <router-view />
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import storeModules from "@/modules/_store";
    import storeAuth from "@/modules/auth/_store";
    import storeUser from "@/modules/user/_store";
    import storeMenu from "@/modules/menuManagement/_store";
    import storeRole from "@/modules/roles/_store";
    import storePermission from "@/modules/permission/_store";
    import storeEnableDisablePlayer from "@/modules/enableDisablePlayer/_store";
    import storeRoleLevel from "@/modules/roleLevel/_store";
    import storeConfigUser from "@/modules/configUser/_store";
    import storeAppModule from "@/modules/appModule/_store";
    import storeStatement from "@/modules/statement/_store";
    import storeSyncModule from "@/modules/syncModule/_store";
    import storeStatementKh from "@/modules/statementKh/_store";
    import storeStatementKhUser from "@/modules/statementKhUser/_store";
    import storeStatementUsdUser from "@/modules/statementUsdUser/_store";
    import storeTransactionPlayer from "@/modules/transactionPlayer/_store";
    import storeDuplicateTransaction from "@/modules/duplicateTransaction/_store";

    import storeDepositStatement from "@/modules/depositStatement/_store";
    import storeWidthdrawStatement from "@/modules/widthdrawStatement/_store";

    import storeErrorUserList from "@/modules/errorUserList/_store";

    // Report KH
    import storeStatementCompanyKh from "@/modules/statementCompanyKh/_store";
    import storeStatementSeniorKh from "@/modules/statementSeniorKh/_store";
    import storeStatementMasterKh from "@/modules/statementMasterKh/_store";
    import storeStatementAgentKh from "@/modules/statementAgentKh/_store";
    import storeStatementPlayerKh from "@/modules/statementPlayerKh/_store";

    // Report USD
    import storeStatementCompanyUSD from "@/modules/statementCompanyUSD/_store";
    import storeStatementSeniorUSD from "@/modules/statementSeniorUSD/_store";
    import storeStatementMasterUSD from "@/modules/statementMasterUSD/_store";
    import storeStatementAgentUSD from "@/modules/statementAgentUSD/_store";
    import storeStatementPlayerUSD from "@/modules/statementPlayerUSD/_store";

    //Machhine Game
    import storeMachineCompanyKh from "@/modules/machineGameReport/statementCompanyKh/_store";
    import storeMachineSeniorKh from "@/modules/machineGameReport/statementSeniorKh/_store";
    import storeMachinetMasterKh from "@/modules/machineGameReport/statementMasterKh/_store";
    import storeMachineAgentKh from "@/modules/machineGameReport/statementAgentKh/_store";
    import storeMachinePlayerKh from "@/modules/machineGameReport/statementPlayerKh/_store";

    import storeMachineCompanyUSD from "@/modules/machineGameReport/statementCompanyUSD/_store";
    import storeMachineSeniorUSD from "@/modules/machineGameReport/statementSeniorUSD/_store";
    import storeMachinetMasterUSD from "@/modules/machineGameReport/statementMasterUSD/_store";
    import storeMachineAgentUSD from "@/modules/machineGameReport/statementAgentUSD/_store";
    import storeMachinePlayerUSD from "@/modules/machineGameReport/statementPlayerUSD/_store";
    /// end Machhine Game

    //cockfight report
    import storeCockFightCompanyKh from "@/modules/cockFightReport/statementCompanyKh/_store";
    import storeCockFightSeniorKh from "@/modules/cockFightReport/statementSeniorKh/_store";
    import storeCockFighttMasterKh from "@/modules/cockFightReport/statementMasterKh/_store";
    import storeCockFightAgentKh from "@/modules/cockFightReport/statementAgentKh/_store";
    import storeCockFightPlayerKh from "@/modules/cockFightReport/statementPlayerKh/_store";

    import storeCockFightCompanyUSD from "@/modules/cockFightReport/statementCompanyUSD/_store";
    import storeCockFightSeniorUSD from "@/modules/cockFightReport/statementSeniorUSD/_store";
    import storeCockFighttMasterUSD from "@/modules/cockFightReport/statementMasterUSD/_store";
    import storeCockFightAgentUSD from "@/modules/cockFightReport/statementAgentUSD/_store";
    import storeCockFightPlayerUSD from "@/modules/cockFightReport/statementPlayerUSD/_store";
    /// end cockfight report

    //kd report
    import storeKDCompanyKh from "@/modules/kdReport/statementCompanyKh/_store";
    import storeKDSeniorKh from "@/modules/kdReport/statementSeniorKh/_store";
    import storeKDMasterKh from "@/modules/kdReport/statementMasterKh/_store";
    import storeKDAgentKh from "@/modules/kdReport/statementAgentKh/_store";
    import storeKDPlayerKh from "@/modules/kdReport/statementPlayerKh/_store";

    import storeKDCompanyUSD from "@/modules/kdReport/statementCompanyUSD/_store";
    import storeKDSeniorUSD from "@/modules/kdReport/statementSeniorUSD/_store";
    import storeKDMasterUSD from "@/modules/kdReport/statementMasterUSD/_store";
    import storeKDAgentUSD from "@/modules/kdReport/statementAgentUSD/_store";
    import storeKDPlayerUSD from "@/modules/kdReport/statementPlayerUSD/_store";
    /// end kd report

    //snk report
    import storeSNKCompanyKh from "@/modules/snkReport/statementCompanyKh/_store";
    import storeSNKSeniorKh from "@/modules/snkReport/statementSeniorKh/_store";
    import storeSNKMasterKh from "@/modules/snkReport/statementMasterKh/_store";
    import storeSNKAgentKh from "@/modules/snkReport/statementAgentKh/_store";
    import storeSNKPlayerKh from "@/modules/snkReport/statementPlayerKh/_store";

    import storeSNKCompanyUSD from "@/modules/snkReport/statementCompanyUSD/_store";
    import storeSNKSeniorUSD from "@/modules/snkReport/statementSeniorUSD/_store";
    import storeSNKMasterUSD from "@/modules/snkReport/statementMasterUSD/_store";
    import storeSNKAgentUSD from "@/modules/snkReport/statementAgentUSD/_store";
    import storeSNKPlayerUSD from "@/modules/snkReport/statementPlayerUSD/_store";
    /// end snk report

    //Statement All
    import storeCompanyKhAll from "@/modules/statementAll/statementCompanyKh/_store";
    import storeSeniorKhAll from "@/modules/statementAll/statementSeniorKh/_store";
    import storeMasterKhAll from "@/modules/statementAll/statementMasterKh/_store";
    import storeAgentKhAll from "@/modules/statementAll/statementAgentKh/_store";
    import storePlayerKhAll from "@/modules/statementAll/statementPlayerKh/_store";

    import storeCompanyUSDAll from "@/modules/statementAll/statementCompanyUSD/_store";
    import storeSeniorUSDAll from "@/modules/statementAll/statementSeniorUSD/_store";
    import storeMasterUSDAll from "@/modules/statementAll/statementMasterUSD/_store";
    import storeAgentUSDAll from "@/modules/statementAll/statementAgentUSD/_store";
    import storePlayerUSDAll from "@/modules/statementAll/statementPlayerUSD/_store";
    /// End Statement All


    
    /// report  Cock fight outstanding
    import storeReportOutStanding from "@/modules/reportOutStanding/_store";

    /// Live Betting KD855
    import storeLiveBettingCard from "@/modules/liveBettingCard/_store";
    import storeLiveBetting6Number from "@/modules/liveBetting6Number/_store";
    import storeLiveBettingLottoLive from "@/modules/liveBettingLottoLive/_store";
    import storeLiveBettingYukiLive from "@/modules/liveBettingYukiLive/_store";
    import storeLiveBettingDragonTiger from "@/modules/liveBettingDragonTiger/_store";

    /// Live Betting SNK Online
    import storeLiveBettingRat from "@/modules/liveBettingRat/_store";
    import storeLiveBettingKlaklouk from "@/modules/liveBettingKlaklouk/_store";

    /// Live Betting Machine Game
    import storeLiveBettingEE7777 from "@/modules/liveBettingEE7777/_store";
    import storeLiveBettingPP78 from "@/modules/liveBettingPP78/_store";
    import storeLiveBettingYuki from "@/modules/liveBettingYuki/_store";

    /// Win Lose statement
    import storeWinLoseCockStatement from "@/modules/winLoseCockStatement/cockStatement/_store";
    import storeWinLoseRatStatement from "@/modules/winLoseSNKStatement/ratStatement/_store";
    import storeWinLoseKlakloukStatement from "@/modules/winLoseSNKStatement/klakloukStatement/_store";
    import storeWinLoseEE7777Statement from "@/modules/winLoseMachineGameStatement/EE7777Statement/_store";
    import storeWinLosePP78Statement from "@/modules/winLoseMachineGameStatement/PP78Statement/_store";
    import storeWinLoseYukiStatement from "@/modules/winLoseMachineGameStatement/yukiStatement/_store";
    import storeWinLose6NumberStatement from "@/modules/winLoseKDStatement/6NumberStatement/_store";
    import storeWinLoseCardStatement from "@/modules/winLoseKDStatement/cardStatement/_store";
    import storeWinLoseDragonTigerStatement from "@/modules/winLoseKDStatement/dragonTigerStatement/_store";
    import storeWinLoseLottoLiveStatement from "@/modules/winLoseKDStatement/lottoLiveStatement/_store";
    import storeWinLoseYukiLiveStatement from "@/modules/winLoseKDStatement/yukiLiveStatement/_store";
    import storeDealer from "@/modules/dealer/_store";

    export default {
        name: "App",
        created() {
            this.registerStore();
            this.initData();
        },
        computed: {
            ...mapGetters("$_modules", {
                user: "getUserInfo"
            }),
        },
        methods: {
            initData() {
                const token = this.$cookies.get("token");
                if (!token) return;
                this.fetchUserInfo();
            },
            registerStore() {
                const MODULES = "$_modules";
                const AUTH = "$_auth";
                const USER = "$_user";
                const MENUS = "$_menu";
                const ROLE = "$_role";
                const PERMISSION = "$_permission";
                const EnableDisablePlayer = "$_enableDisablePlayer";
                const RoleLevel = "$_roleLevel";
                const ConfigUser = "$_configUser";
                const AppModule = "$_appModule";
                const Statement = "$_statement";
                const StatementKh = "$_statementKh";
                const StatementKhUser = "$_statementKhUser";
                const StatementUsdUser = "$_statementUsdUser";
                const SyncModule = "$_syncModule";
                const TransactionPlayer = "$_transactionPlayer";
                const DuplicateTransaction = "$_duplicateTransaction";

                const DepositStatement = "$_depositStatement";
                const WidthdrawStatement = "$_widthdrawStatement";

                const ErrorUserList = "$_errorUserList";

                // win lose statement
                const WinLoseCockStatement = "$_winLoseCockStatement";
                const WinLoseRatStatement = "$_winLoseRatStatement";
                const WinLoseKlakloukStatement = "$_winLoseKlakloukStatement";
                const WinLoseEE7777Statement = "$_winLoseEE7777Statement";
                const WinLosePP78Statement = "$_winLosePP78Statement";
                const WinLoseYukiStatement = "$_winLoseYukiStatement";
                const WinLose6NumberStatement = "$_winLose6NumberStatement";
                const WinLoseCardStatement = "$_winLoseCardStatement";
                const WinLoseDragonTigerStatement = "$_winLoseDragonTigerStatement";
                const WinLoseYukiLiveStatement = "$_winLoseYukiLiveStatement";
                const WinLoseLottoLiveStatement = "$_winLoseLottoLiveStatement";
                const DEALER = "$_dealer";

                if (!(WinLoseDragonTigerStatement in this.$store._modules.root._children)) {
                    this.$store.registerModule(WinLoseDragonTigerStatement, storeWinLoseDragonTigerStatement);
                }
                if (!(DEALER in this.$store._modules.root._children)) {
                    this.$store.registerModule(DEALER, storeDealer);
                }
                if (!(WinLoseYukiLiveStatement in this.$store._modules.root._children)) {
                    this.$store.registerModule(WinLoseYukiLiveStatement, storeWinLoseYukiLiveStatement);
                }
                if (!(WinLoseLottoLiveStatement in this.$store._modules.root._children)) {
                    this.$store.registerModule(WinLoseLottoLiveStatement, storeWinLoseLottoLiveStatement);
                }
                if (!(WinLoseCockStatement in this.$store._modules.root._children)) {
                    this.$store.registerModule(WinLoseCockStatement, storeWinLoseCockStatement);
                }
                if (!(WinLoseRatStatement in this.$store._modules.root._children)) {
                    this.$store.registerModule(WinLoseRatStatement, storeWinLoseRatStatement);
                }
                if (!(WinLoseKlakloukStatement in this.$store._modules.root._children)) {
                    this.$store.registerModule(WinLoseKlakloukStatement, storeWinLoseKlakloukStatement);
                }
                if (!(WinLoseEE7777Statement in this.$store._modules.root._children)) {
                    this.$store.registerModule(WinLoseEE7777Statement, storeWinLoseEE7777Statement);
                }
                if (!(WinLosePP78Statement in this.$store._modules.root._children)) {
                    this.$store.registerModule(WinLosePP78Statement, storeWinLosePP78Statement);
                }
                if (!(WinLoseYukiStatement in this.$store._modules.root._children)) {
                    this.$store.registerModule(WinLoseYukiStatement, storeWinLoseYukiStatement);
                }
                if (!(WinLose6NumberStatement in this.$store._modules.root._children)) {
                    this.$store.registerModule(WinLose6NumberStatement, storeWinLose6NumberStatement);
                }
                if (!(WinLoseCardStatement in this.$store._modules.root._children)) {
                    this.$store.registerModule(WinLoseCardStatement, storeWinLoseCardStatement);
                }

                //Report KH
                const StatementCompanyKh = "$_statementCompanyKh";
                const StatementSeniorKh = "$_statementSeniorKh";
                const StatementMasterKh = "$_statementMasterKh";
                const StatementAgentKh = "$_statementAgentKh";
                const StatementPlayerKh = "$_statementPlayerKh";

                // Report USD
                const StatementCompanyUSD = "$_statementCompanyUSD";
                const StatementSeniorUSD = "$_statementSeniorUSD";
                const StatementMasterUSD = "$_statementMasterUSD";
                const StatementAgentUSD = "$_statementAgentUSD";
                const StatementPlayerUSD = "$_statementPlayerUSD";

                //Statement All
                const CompanyKhAll = "$_CompanyKhAll";
                const SeniorKhAll = "$_SeniorKhAll";
                const MasterKhAll = "$_MasterKhAll";
                const MarchineAgentKhAll = "$_AgentKhAll";
                const PlayerKhAll = "$_PlayerKhAll";

                const CompanyUSDAll = "$_CompanyUSDAll";
                const SeniorUSDAll = "$_SeniorUSDAll";
                const MasterUSDAll = "$_MasterUSDAll";
                const AgentUSDAll = "$_AgentUSDAll";
                const PlayerUSDAll = "$_PlayerUSDAll";


                if (!(CompanyKhAll in this.$store._modules.root._children)) {
                    this.$store.registerModule(CompanyKhAll, storeCompanyKhAll);
                }
                if (!(SeniorKhAll in this.$store._modules.root._children)) {
                    this.$store.registerModule(SeniorKhAll, storeSeniorKhAll);
                }
                if (!(MasterKhAll in this.$store._modules.root._children)) {
                    this.$store.registerModule(MasterKhAll, storeMasterKhAll);
                }
                if (!(MarchineAgentKhAll in this.$store._modules.root._children)) {
                    this.$store.registerModule(MarchineAgentKhAll, storeAgentKhAll);
                }
                if (!(PlayerKhAll in this.$store._modules.root._children)) {
                    this.$store.registerModule(PlayerKhAll, storePlayerKhAll);
                }

                if (!(CompanyUSDAll in this.$store._modules.root._children)) {
                    this.$store.registerModule(CompanyUSDAll, storeCompanyUSDAll);
                }
                if (!(SeniorUSDAll in this.$store._modules.root._children)) {
                    this.$store.registerModule(SeniorUSDAll, storeSeniorUSDAll);
                }
                if (!(MasterUSDAll in this.$store._modules.root._children)) {
                    this.$store.registerModule(MasterUSDAll, storeMasterUSDAll);
                }
                if (!(AgentUSDAll in this.$store._modules.root._children)) {
                    this.$store.registerModule(AgentUSDAll, storeAgentUSDAll);
                }
                if (!(PlayerUSDAll in this.$store._modules.root._children)) {
                    this.$store.registerModule(PlayerUSDAll, storePlayerUSDAll);
                }
                ///end Statement All

                //marchine game
                const MarchineCompanyKh = "$_machineCompanyKh";
                const MarchineSeniorKh = "$_machineSeniorKh";
                const MarchineMasterKh = "$_machineMasterKh";
                const MarchineAgentKh = "$_machineAgentKh";
                const MarchinePlayerKh = "$_machinePlayerKh";

                const MarchineCompanyUSD = "$_machineCompanyUSD";
                const MarchineSeniorUSD = "$_machineSeniorUSD";
                const MarchineMasterUSD = "$_machineMasterUSD";
                const MarchineAgentUSD = "$_machineAgentUSD";
                const MarchinePlayerUSD = "$_machinePlayerUSD";


                if (!(MarchineCompanyKh in this.$store._modules.root._children)) {
                    this.$store.registerModule(MarchineCompanyKh, storeMachineCompanyKh);
                }
                if (!(MarchineSeniorKh in this.$store._modules.root._children)) {
                    this.$store.registerModule(MarchineSeniorKh, storeMachineSeniorKh);
                }
                if (!(MarchineMasterKh in this.$store._modules.root._children)) {
                    this.$store.registerModule(MarchineMasterKh, storeMachinetMasterKh);
                }
                if (!(MarchineAgentKh in this.$store._modules.root._children)) {
                    this.$store.registerModule(MarchineAgentKh, storeMachineAgentKh);
                }
                if (!(MarchinePlayerKh in this.$store._modules.root._children)) {
                    this.$store.registerModule(MarchinePlayerKh, storeMachinePlayerKh);
                }

                if (!(MarchineCompanyUSD in this.$store._modules.root._children)) {
                    this.$store.registerModule(MarchineCompanyUSD, storeMachineCompanyUSD);
                }
                if (!(MarchineSeniorUSD in this.$store._modules.root._children)) {
                    this.$store.registerModule(MarchineSeniorUSD, storeMachineSeniorUSD);
                }
                if (!(MarchineMasterUSD in this.$store._modules.root._children)) {
                    this.$store.registerModule(MarchineMasterUSD, storeMachinetMasterUSD);
                }
                if (!(MarchineAgentUSD in this.$store._modules.root._children)) {
                    this.$store.registerModule(MarchineAgentUSD, storeMachineAgentUSD);
                }
                if (!(MarchinePlayerUSD in this.$store._modules.root._children)) {
                    this.$store.registerModule(MarchinePlayerUSD, storeMachinePlayerUSD);
                }
                ///end marchine game


                //cockfight report
                const CockFightCompanyKh = "$_cockFightCompanyKh";
                const CockFightSeniorKh = "$_cockFightSeniorKh";
                const CockFightMasterKh = "$_cockFightMasterKh";
                const CockFightAgentKh = "$_cockFightAgentKh";
                const CockFightPlayerKh = "$_cockFightPlayerKh";

                const CockFightCompanyUSD = "$_cockFightCompanyUSD";
                const CockFightSeniorUSD = "$_cockFightSeniorUSD";
                const CockFightMasterUSD = "$_cockFightMasterUSD";
                const CockFightAgentUSD = "$_cockFightAgentUSD";
                const CockFightPlayerUSD = "$_cockFightPlayerUSD";

                if (!(CockFightCompanyKh in this.$store._modules.root._children)) {
                    this.$store.registerModule(CockFightCompanyKh, storeCockFightCompanyKh);
                }
                if (!(CockFightSeniorKh in this.$store._modules.root._children)) {
                    this.$store.registerModule(CockFightSeniorKh, storeCockFightSeniorKh);
                }
                if (!(CockFightMasterKh in this.$store._modules.root._children)) {
                    this.$store.registerModule(CockFightMasterKh, storeCockFighttMasterKh);
                }
                if (!(CockFightAgentKh in this.$store._modules.root._children)) {
                    this.$store.registerModule(CockFightAgentKh, storeCockFightAgentKh);
                }
                if (!(CockFightPlayerKh in this.$store._modules.root._children)) {
                    this.$store.registerModule(CockFightPlayerKh, storeCockFightPlayerKh);
                }

                if (!(CockFightCompanyUSD in this.$store._modules.root._children)) {
                    this.$store.registerModule(CockFightCompanyUSD, storeCockFightCompanyUSD);
                }
                if (!(CockFightSeniorUSD in this.$store._modules.root._children)) {
                    this.$store.registerModule(CockFightSeniorUSD, storeCockFightSeniorUSD);
                }
                if (!(CockFightMasterUSD in this.$store._modules.root._children)) {
                    this.$store.registerModule(CockFightMasterUSD, storeCockFighttMasterUSD);
                }
                if (!(CockFightAgentUSD in this.$store._modules.root._children)) {
                    this.$store.registerModule(CockFightAgentUSD, storeCockFightAgentUSD);
                }
                if (!(CockFightPlayerUSD in this.$store._modules.root._children)) {
                    this.$store.registerModule(CockFightPlayerUSD, storeCockFightPlayerUSD);
                }
                ///end cockfight report

                //kd report
                const KDCompanyKh = "$_kdCompanyKh";
                const KDSeniorKh = "$_kdSeniorKh";
                const KDMasterKh = "$_kdMasterKh";
                const KDAgentKh = "$_kdAgentKh";
                const KDPlayerKh = "$_kdPlayerKh";

                const KDCompanyUSD = "$_kdCompanyUSD";
                const KDSeniorUSD = "$_kdSeniorUSD";
                const KDMasterUSD = "$_kdMasterUSD";
                const KDAgentUSD = "$_kdAgentUSD";
                const KDPlayerUSD = "$_kdPlayerUSD";

                if (!(KDCompanyKh in this.$store._modules.root._children)) {
                    this.$store.registerModule(KDCompanyKh, storeKDCompanyKh);
                }
                if (!(KDSeniorKh in this.$store._modules.root._children)) {
                    this.$store.registerModule(KDSeniorKh, storeKDSeniorKh);
                }
                if (!(KDMasterKh in this.$store._modules.root._children)) {
                    this.$store.registerModule(KDMasterKh, storeKDMasterKh);
                }
                if (!(KDAgentKh in this.$store._modules.root._children)) {
                    this.$store.registerModule(KDAgentKh, storeKDAgentKh);
                }
                if (!(KDPlayerKh in this.$store._modules.root._children)) {
                    this.$store.registerModule(KDPlayerKh, storeKDPlayerKh);
                }

                if (!(KDCompanyUSD in this.$store._modules.root._children)) {
                    this.$store.registerModule(KDCompanyUSD, storeKDCompanyUSD);
                }
                if (!(KDSeniorUSD in this.$store._modules.root._children)) {
                    this.$store.registerModule(KDSeniorUSD, storeKDSeniorUSD);
                }
                if (!(KDMasterUSD in this.$store._modules.root._children)) {
                    this.$store.registerModule(KDMasterUSD, storeKDMasterUSD);
                }
                if (!(KDAgentUSD in this.$store._modules.root._children)) {
                    this.$store.registerModule(KDAgentUSD, storeKDAgentUSD);
                }
                if (!(KDPlayerUSD in this.$store._modules.root._children)) {
                    this.$store.registerModule(KDPlayerUSD, storeKDPlayerUSD);
                }
                /// end kd report

                //kd report
                const SNKCompanyKh = "$_snkCompanyKh";
                const SNKSeniorKh = "$_snkSeniorKh";
                const SNKMasterKh = "$_snkMasterKh";
                const SNKAgentKh = "$_snkAgentKh";
                const SNKPlayerKh = "$_snkPlayerKh";

                const SNKCompanyUSD = "$_snkCompanyUSD";
                const SNKSeniorUSD = "$_snkSeniorUSD";
                const SNKMasterUSD = "$_snkMasterUSD";
                const SNKAgentUSD = "$_snkAgentUSD";
                const SNKPlayerUSD = "$_snkPlayerUSD";

                if (!(SNKCompanyKh in this.$store._modules.root._children)) {
                    this.$store.registerModule(SNKCompanyKh, storeSNKCompanyKh);
                }
                if (!(SNKSeniorKh in this.$store._modules.root._children)) {
                    this.$store.registerModule(SNKSeniorKh, storeSNKSeniorKh);
                }
                if (!(SNKMasterKh in this.$store._modules.root._children)) {
                    this.$store.registerModule(SNKMasterKh, storeSNKMasterKh);
                }
                if (!(SNKAgentKh in this.$store._modules.root._children)) {
                    this.$store.registerModule(SNKAgentKh, storeSNKAgentKh);
                }
                if (!(SNKPlayerKh in this.$store._modules.root._children)) {
                    this.$store.registerModule(SNKPlayerKh, storeSNKPlayerKh);
                }

                if (!(SNKCompanyUSD in this.$store._modules.root._children)) {
                    this.$store.registerModule(SNKCompanyUSD, storeSNKCompanyUSD);
                }
                if (!(SNKSeniorUSD in this.$store._modules.root._children)) {
                    this.$store.registerModule(SNKSeniorUSD, storeSNKSeniorUSD);
                }
                if (!(SNKMasterUSD in this.$store._modules.root._children)) {
                    this.$store.registerModule(SNKMasterUSD, storeSNKMasterUSD);
                }
                if (!(SNKAgentUSD in this.$store._modules.root._children)) {
                    this.$store.registerModule(SNKAgentUSD, storeSNKAgentUSD);
                }
                if (!(SNKPlayerUSD in this.$store._modules.root._children)) {
                    this.$store.registerModule(SNKPlayerUSD, storeSNKPlayerUSD);
                }
                /// end kd report

                if (!(StatementCompanyKh in this.$store._modules.root._children)) {
                    this.$store.registerModule(StatementCompanyKh, storeStatementCompanyKh);
                }
                if (!(StatementSeniorKh in this.$store._modules.root._children)) {
                    this.$store.registerModule(StatementSeniorKh, storeStatementSeniorKh);
                }
                if (!(StatementMasterKh in this.$store._modules.root._children)) {
                    this.$store.registerModule(StatementMasterKh, storeStatementMasterKh);
                }
                if (!(StatementAgentKh in this.$store._modules.root._children)) {
                    this.$store.registerModule(StatementAgentKh, storeStatementAgentKh);
                }
                if (!(StatementPlayerKh in this.$store._modules.root._children)) {
                    this.$store.registerModule(StatementPlayerKh, storeStatementPlayerKh);
                }

                if (!(StatementCompanyUSD in this.$store._modules.root._children)) {
                    this.$store.registerModule(StatementCompanyUSD, storeStatementCompanyUSD);
                }
                if (!(StatementSeniorUSD in this.$store._modules.root._children)) {
                    this.$store.registerModule(StatementSeniorUSD, storeStatementSeniorUSD);
                }
                if (!(StatementMasterUSD in this.$store._modules.root._children)) {
                    this.$store.registerModule(StatementMasterUSD, storeStatementMasterUSD);
                }
                if (!(StatementAgentUSD in this.$store._modules.root._children)) {
                    this.$store.registerModule(StatementAgentUSD, storeStatementAgentUSD);
                }
                if (!(StatementPlayerUSD in this.$store._modules.root._children)) {
                    this.$store.registerModule(StatementPlayerUSD, storeStatementPlayerUSD);
                }


                if (!(AppModule in this.$store._modules.root._children)) {
                    this.$store.registerModule(AppModule, storeAppModule);
                }
                if (!(MODULES in this.$store._modules.root._children)) {
                    this.$store.registerModule(MODULES, storeModules);
                }
                if (!(AUTH in this.$store._modules.root._children)) {
                    this.$store.registerModule(AUTH, storeAuth);
                }
                if (!(USER in this.$store._modules.root._children)) {
                    this.$store.registerModule(USER, storeUser);
                }
                if (!(MENUS in this.$store._modules.root._children)) {
                    this.$store.registerModule(MENUS, storeMenu);
                }

                if (!(ROLE in this.$store._modules.root._children)) {
                    this.$store.registerModule(ROLE, storeRole);
                }
                if (!(PERMISSION in this.$store._modules.root._children)) {
                    this.$store.registerModule(PERMISSION, storePermission);
                }
                if (!(ConfigUser in this.$store._modules.root._children)) {
                    this.$store.registerModule(ConfigUser, storeConfigUser);
                }
                if (!(ConfigUser in this.$store._modules.root._children)) {
                    this.$store.registerModule(ConfigUser, storeConfigUser);
                }
                if (!(EnableDisablePlayer in this.$store._modules.root._children)) {
                    this.$store.registerModule(EnableDisablePlayer, storeEnableDisablePlayer);
                }
                if (!(RoleLevel in this.$store._modules.root._children)) {
                    this.$store.registerModule(RoleLevel, storeRoleLevel);
                }
                if (!(Statement in this.$store._modules.root._children)) {
                    this.$store.registerModule(Statement, storeStatement);
                }
                if (!(SyncModule in this.$store._modules.root._children)) {
                    this.$store.registerModule(SyncModule, storeSyncModule);
                }
                if (!(StatementKh in this.$store._modules.root._children)) {
                    this.$store.registerModule(StatementKh, storeStatementKh);
                }
                if (!(StatementKhUser in this.$store._modules.root._children)) {
                    this.$store.registerModule(StatementKhUser, storeStatementKhUser);
                }
                if (!(StatementUsdUser in this.$store._modules.root._children)) {
                    this.$store.registerModule(StatementUsdUser, storeStatementUsdUser);
                }
                if (!(TransactionPlayer in this.$store._modules.root._children)) {
                    this.$store.registerModule(TransactionPlayer, storeTransactionPlayer);
                }
                if (!(DepositStatement in this.$store._modules.root._children)) {
                    this.$store.registerModule(DepositStatement, storeDepositStatement);
                }
                if (!(WidthdrawStatement in this.$store._modules.root._children)) {
                    this.$store.registerModule(WidthdrawStatement, storeWidthdrawStatement);
                }

                if (!(ErrorUserList in this.$store._modules.root._children)) {
                    this.$store.registerModule(ErrorUserList, storeErrorUserList);
                }
                if (!(DuplicateTransaction in this.$store._modules.root._children)) {
                    this.$store.registerModule(DuplicateTransaction, storeDuplicateTransaction);
                }

                
                const LIVE_BETTING_CARD = "$_liveBettingCard";

                if (!(LIVE_BETTING_CARD in this.$store._modules.root._children)) {
                    this.$store.registerModule(LIVE_BETTING_CARD, storeLiveBettingCard);
                }
                const LIVE_BETTING_LOTTO_LIVE = "$_liveBettingLottoLive";

                if (!(LIVE_BETTING_LOTTO_LIVE in this.$store._modules.root._children)) {
                    this.$store.registerModule(LIVE_BETTING_LOTTO_LIVE, storeLiveBettingLottoLive);
                }
                const LIVE_BETTING_YUKI_LIVE = "$_liveBettingYukiLive";

                if (!(LIVE_BETTING_YUKI_LIVE in this.$store._modules.root._children)) {
                    this.$store.registerModule(LIVE_BETTING_YUKI_LIVE, storeLiveBettingYukiLive);
                }
                const LIVE_BETTING_DRAGON_TIGER = "$_liveBettingDragonTiger";

                if (!(LIVE_BETTING_DRAGON_TIGER in this.$store._modules.root._children)) {
                    this.$store.registerModule(LIVE_BETTING_DRAGON_TIGER, storeLiveBettingDragonTiger);
                }

                const LIVE_BETTING_RAT = "$_liveBettingRat";

                if (!(LIVE_BETTING_RAT in this.$store._modules.root._children)) {
                    this.$store.registerModule(LIVE_BETTING_RAT, storeLiveBettingRat);
                }

                const LIVE_BETTING_KLAKLOUK = "$_liveBettingKlaklouk";

                if (!(LIVE_BETTING_KLAKLOUK in this.$store._modules.root._children)) {
                    this.$store.registerModule(LIVE_BETTING_KLAKLOUK, storeLiveBettingKlaklouk);
                }
                const LIVE_BETTING_6Number = "$_liveBetting6Number";

                if (!(LIVE_BETTING_6Number in this.$store._modules.root._children)) {
                    this.$store.registerModule(LIVE_BETTING_6Number, storeLiveBetting6Number);
                }

                const LIVE_BETTING_EE7777 = "$_liveBettingEE7777";

                if (!(LIVE_BETTING_EE7777 in this.$store._modules.root._children)) {
                    this.$store.registerModule(LIVE_BETTING_EE7777, storeLiveBettingEE7777);
                }
                const LIVE_BETTING_PP78 = "$_liveBettingPP78";

                if (!(LIVE_BETTING_PP78 in this.$store._modules.root._children)) {
                    this.$store.registerModule(LIVE_BETTING_PP78, storeLiveBettingPP78);
                }
                const LIVE_BETTING_Yuki = "$_liveBettingYuki";

                if (!(LIVE_BETTING_Yuki in this.$store._modules.root._children)) {
                    this.$store.registerModule(LIVE_BETTING_Yuki, storeLiveBettingYuki);
                }
                
                const REPORT_OUT_STANDING = "$_reportOutStanding";

                if (!(REPORT_OUT_STANDING in this.$store._modules.root._children)) {
                    this.$store.registerModule(REPORT_OUT_STANDING, storeReportOutStanding);
                }  
            },
            ...mapActions("$_modules", ["fetchUserInfo"]),
        },
    };
</script>
