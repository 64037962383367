const getUsers = (state) => {
	return state.users;
};
const getLoading = (state) => state.loading
const getLoadingConfig = (state) => state.loadingConfig

const getLimitData = (state) => {
	return state.limitData;
};
const getModule = (state) => state.listModule;

export default {
	getLoading,
	getUsers,
	getLimitData,
	getLoadingConfig,
	getModule
};
