import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    modules: {
        object: [],
        objectCount: null
    },
    popUp: false,
    currentModule: {
        name: null,
        desc: null,
        is_enable: true,
        front_player_link: null,
        api_player_link: null,
        api_admin_link: null,
        background_icon: null,
        app_id: null,
        parent_id: null,
        small_icon: null,
        large_icon: null,
        order: null
    },
    deleteItems: [],
    moduleParent: [],
    loading: true
};


export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
