const headers = [
    // { text: 'សកម្មភាព', align: 'start', value: 'actions', sortable: false },
    {
        text: 'ល.រ',
        align: 'start',
        value: 'no',
    },
    { text: 'ឈ្មោះអ្នកប្រើប្រាស់', value: 'user_name' },
    { text: 'ឈ្មោះ Module', value: 'module_name' },
    { text: 'ស្ថានភាព', value: 'status' },
    { text: 'កំពុងប្រើប្រាស់', value: 'is_active' },
    { text: 'សកម្មភាព', value: 'actions' },
];

export default headers;