const UPDATE_MODULES = (state, data) => {
    state.modules = data;
};

const UPDATE_LOADING = (state, data) => {
    state.loading = data
}
const LIST_MODULE = (state, data) => {
    state.listModule = data
}

export default {
    UPDATE_LOADING,
    UPDATE_MODULES,
    LIST_MODULE
};
