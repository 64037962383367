const getStatement = (state) => {
    return state.statement;
};

const getCountStatement = (state) => {
    return state.countStatement;
};
const getModule = (state) => state.listModule;
const getLoading = (state) => state.loading;

export default {
    getStatement,
    getCountStatement,
    getLoading,
    getModule
};