import statementSeniorKh from '@/modules/statementSeniorKh';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/statementSeniorKh',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: statementSeniorKh,
				name: 'statementSeniorKh'
			}
		]
	}
];

export default routes;
