import statementPlayerKhSNK from '@/modules/snkReport/statementPlayerKh';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/statementPlayerKhSNK',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: statementPlayerKhSNK,
				name: 'statementPlayerKhSNK'
			}
		]
	}
];

export default routes;
