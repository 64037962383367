export default {
    search: 'ស្វែងរក',
    permission_name: 'ឈ្មោះ Permission',
    role_name: 'ឈ្មោះតួនាទី',
    menu_name: 'ឈ្មោះ Menu',
    description: 'បរិយាយ',
    order: 'លេខលំដាប់',
    route_name: 'ឈ្មោះ Route',
    url_name: 'ឈ្មោះ URL',
    icon: 'រូបតំណាង',
    parent: 'ទិន្នន័យមេ',
    permission: 'សិទ្ធអ្នកប្រើប្រាស់',
    visible: 'អាចមើលឃើញ',
    user_name: 'ឈ្មោះអ្នកប្រើប្រាស់',
    bank_account: 'ព័ត៌មានគណនីធនាគារ',
    user_role: 'ប្រភេទអ្នកប្រើប្រាស់',
    password: 'លេខសម្ងាត់',
    total_bet_a_fight: 'សរុបភ្នាល់ក្នុងមួយដៃ',
    total_bet_win_a_day:'សរុបភ្នាល់ក្នុងមួយថ្ងៃ',
    user:"ជ្រើសរើស អ្នកប្រើប្រាស់",
    default_bet_a_fight:"Default ក្នុង ១ដៃ",
    default_bet_win_a_day:"Default ក្នុង ១ថ្ងៃ",
    agent_bet_a_fight:" Agent អោយកូនភ្នាល់បានក្នុង ១ដៃ",
    agent_bet_win_a_day: "Agent អោយកូនភ្នាល់បានក្នុង ១ថ្ងៃ",
    bet_win_a_day: "ចាក់ឈ្នះក្នុងថ្ងៃ",
    bet_win_a_Fight :"ចាក់បានក្នុង ១ ដៃ",
    target :  "គោលដៅ",
    percentage_meron :  "ភាគរយ Meron",
    percentage_wala :  "ភាគរយ Wala",
    meron_name : "ឈ្មោះរបស់ Meron",
    wala_name : "ឈ្មោះរបស់ Wala",
    start_time :  "ម៉ោងចាប់ផ្ដើម",
    end_time :  "ម៉ោងបញ្ចប់",
    meron : "Meron",
    child_role_balance: "តុល្យភាពរបស់កូន",
    child_role_name: "ឈ្មោះរបស់កូន",
    chose_type: "ជ្រើសរើសប្រភេទលុយ",
    enable:"បើកដំណើរការ",
    listAllCompany:"ជ្រើសរើស Company",
    child_role_balance_d: "តុល្យភាពរបស់កូន USD",
    child_role_balance_b: "តុល្យភាពរបស់កូន Baht",
    child_role_balance_r: "តុល្យភាពរបស់កូន RIEL",
    channel_type:"ជ្រើសរើស បុស្តិ៌",
    name: "ឈ្មោះឡូតូ",
    is_enable: "បញ្ឈប់ដំណើរការ",
    is_start: "ចាប់ផ្ដើមកម្មវិធី",
    updatedAt: "បានធ្វើបច្ចុប្បន្នភាព",
    percentage: "ភាគរយ",
    win_lose_type: "ប្រភេទ Win Lose",
    one_day: "១ ថ្ងៃ",
    one_month: "១ ខែ",
    max_win: "Max Win",
    max_loose: "Max Loose",
    module_name: "ឈ្មោះ Module",
    sub_link: "Sub Link",
    app_id: "App Id",
    listModule: "តារាង Module",
    small_icon: "រូបតំណាងតូច",
    large_icon: "រូបតំណាងធំ",
    front_player_link: "តំណភ្ជាប់សម្រាប់ Front Player",
    api_player_link: "តំណភ្ជាប់សម្រាប់ API Player",
    api_admin_link: "តំណភ្ជាប់សម្រាប់ API Admin",
    background_icon: "រូបតំណាងផ្ទៃខាងក្រោយ",
    reverse_result: "ត្រលប់លទ្ធផល",
    select_module: "ជ្រើសរើស Module",
    start_date: "ថ្ងៃចាប់ផ្តើម",
    end_date: "ថ្ងៃបញ្ចប់",
    channel1:"បុស្តទី 1",
    channel2:"បុស្តទី 2",
    channel3:"បុស្តទី 3",
    channel4:"បុស្តទី 4",
    select_currency:"ជ្រើសរើសរូបិយប័ណ្ណ",
    barcode: "Barcode",
    dealer_name: "Username",
    dealer_dob: "Date of birth",
    profile_image: "Profile",
    is_pr: "Is PR",
    is_pitbox: "IS PITBOX",
};
