import statementAgentUSDM from '@/modules/machineGameReport/statementAgentUSD';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/statementAgentUSDM',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: statementAgentUSDM,
				name: 'statementAgentUSDM'
			}
		]
	}
];

export default routes;
