import liveBettingLottoLive from '@/modules/liveBettingLottoLive';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/liveBettingLottoLive',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: liveBettingLottoLive,
				name: 'liveBettingLottoLive'
			}
		]
	}
];

export default routes;
