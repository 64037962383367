import statementCompanyUSDAll from '@/modules/statementAll/statementCompanyUSD';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/statementCompanyUSDAll',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: statementCompanyUSDAll,
				name: 'statementCompanyUSDAll'
			}
		]
	}
];

export default routes;
