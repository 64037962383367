import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
    drawer: false,
    menus: [],
    userInfo: null,
    user: null,
    darkMode: true,
    userAccessPermission: []
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
