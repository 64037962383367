import statementAgentKhKD from '@/modules/kdReport/statementAgentKh';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/statementAgentKhKD',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: statementAgentKhKD,
				name: 'statementAgentKhKD'
			}
		]
	}
];

export default routes;
