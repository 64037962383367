/* eslint-disable no-unused-vars */
import Request from '@/services/axios'

let request = new Request;

const fetchListModules = async({ commit }, data) => {
    commit('UPDATE_LOADING', true)
    data.sortByFormat.length < 1 ? data.sortBy = '_id' : data.sortBy = data.sortByFormat[0]
    data.descendingFormat.length > 0 ? data.descending = data.descendingFormat[0] : data.descending = true
    const response = await request.post({
        url: 'app-modules/getAll',
        data
    });
    console.log("response", response);
    if (response.data.code) {
        commit('UPDATE_MODULES', response.data.data);
        commit('UPDATE_LOADING', false)
    }
};

const createModule = async({ commit }, { vm, data }) => {
    const response = await request.post({
        url: 'app-modules',
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`Successfully created module.`)
        commit('UPDATE_POPUP', false)
    } else vm.$toastr.e(`Fail created module.`)
};

const updateModule = async({ commit }, { vm, data }) => {
    const response = await request.put({
        url: 'app-modules/' + data._id,
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`Successfully updated module.`)
        commit('UPDATE_POPUP', false)
    } else vm.$toastr.e(`Fail updated module.`)
};

const deleteModule = async({ commit }, { vm, data }) => {
    const response = await request.post({
        url: 'app-modules/deleteMany',
        data
    });
    if (response.data.code) {
        commit('DELETE_ITEMS', [])
        vm.$toastr.s(`successfully deleted role.`)
    } else vm.$toastr.e(`'failed delete role.`)
};

const fetchPopUp = async({ commit }, payload) => {
    commit('UPDATE_POPUP', payload);
};

const fetchCurrentModule = async({ commit }, data) => {
    const response = await request.get({
        url: 'app-modules/' + data._id,
        data
    });
    if (response.data.code) {
        commit('UPDATE_CURRENT_MODULE', response.data.data.object);
    }
};
const fetchParentModule = async({ commit }) => {
    const response = await request.get({
        url: 'app-modules/getParentModule'
    });
    console.log("response.data.data",response.data.data);
    if (response.data.code) {
        commit('UPDATE_PARENT_MODULE', response.data.data.object);
    }
};


export default {
    fetchListModules,
    fetchPopUp,
    fetchCurrentModule,
    createModule,
    updateModule,
    deleteModule,
    fetchParentModule
};
