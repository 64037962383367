export default {
    permission_list: 'តារាងសិទ្ធអ្នកប្រើប្រាស់',
    role_list: 'តារាងតួនាទីអ្នកប្រើប្រាស់',
    user_list: 'តារាងអ្នកប្រើប្រាស់',
    menu_list: 'តារាងម៉ឺនុយ',
    create_permission: 'បង្កើតសិទ្ធអ្នកប្រើប្រាស់',
    update_permission: 'កែប្រែសិទ្ធអ្នកប្រើប្រាស់',
    create_role: 'បង្កើតតួនាទីអ្នកប្រើប្រាស់',
    update_role: 'កែប្រែតួនាទីអ្នកប្រើប្រាស់',
    create_user: 'បង្កើតអ្នកប្រើប្រាស់',
    update_user: 'កែប្រែអ្នកប្រើប្រាស់',
    create_menu: 'បង្កើតម៉ឺនុយ',
    update_menu: 'កែប្រែម៉ឺនុយ',
    change_password_user: 'កែប្រែលេខសម្ងាត់អ្នកប្រើប្រាស់',
    welcome: 'សូមស្វាគមន៍:',
    account_balance: 'សមតុល្យគណនេយ្យ',
    user_role: 'តួនាទីអ្នកប្រើប្រាស់',
    create_configUser : "បង្កើតព័ត៌បន្ថែមសម្រាប់ អ្នកប្រើប្រាស់",
    update_configUser : "កែប្រែព័ត៌បន្ថែមសម្រាប់ អ្នកប្រើប្រាស់",
    config_user :'ព័ត៌បន្ថែមសម្រាប់ អ្នកប្រើប្រាស់',
    limitBetting: "កំណត់ការភ្នាល់សម្រាប់ Agent",
    payOut: "ការទូរទាត់",
    roleLevel: "កំណត់ទឹកប្រាក់តាមតួនាទី",
    update_payOut: "កំពុងធ្វើការបច្នុប្បន្នភាព PayOut",
    update_roleLevel: "កំពុងធ្វើការបច្នុប្បន្នភាព Role Level",
    user_limit_betting: "កំណត់ការភ្នាល់ :",
    total:"សរុប",
    enablePlayers:"គ្រប់គ្រងដំណើរការ Players",
    lottoSetting: "កំណត់ការភ្នាល់",
    create_lottoSetting: "បង្កើតការកំណត់ឡូតូ",
    update_lottoSetting: "កែប្រែការកំណត់ឡូតូ",
    create_module: "បង្កើត Module",
    update_module: "កែប្រែ Module",
    module_list: "តារាង Module",
    transaction_player: "ប្រភេទប្រតិបត្តិការ",
    sync_module: "តារាង Sync Module",
    sync_user_detail: "ព័ត៌មានលម្អិតការធ្វើសមកាលកម្មរបស់អ្នកប្រើប្រាស់",
    widthdrawStatement:"របាយការណ៌ ដកប្រាក់",
    depositStatement:"របាយការណ៌ ដាក់ប្រាក់",
    statementCompanyKh: "Company Statement",
    statementSeniorKh: "Senior Statement",
    statementMasterKh: "Master Statement",
    statementAgentKh: "Agent Statement",
    list_user_ip:"បង្ហាញអាស័យដ្ឋានអ្នកប្រើប្រាស់បានចូលប្រើប្រាស់ប្រព័ន្ធ",
    statementCompanyUSD: "Company Statement",
    statementSeniorUSD: "Senior Statement",
    statementMasterUSD: "Master Statement",
    statementAgentUSD: "Agent Statement",
    error_user_list: "Error User List",
    create_dealer: "បង្កើត អ្នកចែកបៀរ",
    update_dealer: "កែប្រែ អ្នកចែកបៀរ",
    dealer_title: "តារាង អ្នកចែកបៀរ",
    user_config_betting: "កំណត់ការភ្នាល់: ",
    duplicate_transaction : "Duplicate Transaction List",
};
