import liveBetting6Number from '@/modules/liveBetting6Number';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/liveBetting6Number',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: liveBetting6Number,
				name: 'liveBetting6Number'
			}
		]
	}
];

export default routes;
