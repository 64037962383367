import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  channel: {},
  // channel_2: {},
  // channel_3: {},
  // channel_4: {},
  loading: false,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
