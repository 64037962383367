// update data over statement

const CHANNEL_1 = (state, data) => {
    state.channel_1 = data;
};

const CHANNEL_2 = (state, data) => {
    state.channel_2 = data;
};

const CHANNEL_3 = (state, data) => {
    state.channel_3 = data;
};

const CHANNEL_4 = (state, data) => {
    state.channel_4 = data;
};
const CHANNEL_5 = (state, data) => {
    state.channel_5 = data;
};
const CHANNEL_6 = (state, data) => {
    state.channel_6 = data;
};
const UPDATE_LOADING = (state, data) => {
    state.loading = data
}


export default {
    CHANNEL_1,
    CHANNEL_2,
    CHANNEL_3,
    CHANNEL_4,
    CHANNEL_5,
    CHANNEL_6,
    UPDATE_LOADING
};