import winLose6NumberStatement from '@/modules/winLoseKDStatement/6NumberStatement';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/winLose6NumberStatement',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: winLose6NumberStatement,
				name: 'winLose6NumberStatement'
			}
		]
	}
];

export default routes;
