const UPDATE_MODULES = (state, data) => {
    state.modules = data;
};

const UPDATE_LOADING = (state, data) => {
    state.loading = data
}

export default {
    UPDATE_LOADING,
    UPDATE_MODULES,
};
