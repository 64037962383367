<template>
    <div>
        <v-data-table style="font-family: 'khmer mef1'"
                      class="elevation-1"
                      item-key="_id"
                      :headers="headers"
                      :items="getRoles && getRoles.object ? getRoles.object : []"
                      :footer-props="footerProps"
                      :server-items-length="getRoles ? getRoles.objectCount : null"
                      :page.sync="data.page"
                      :items-per-page.sync="data.rowsPerPage"
                      :sort-desc.sync="data.descendingFormat"
                      :sort-by.sync="data.sortByFormat"
                      @update:page="getData"
                      @update:items-per-page="getData"
                      @update:sort-desc="getData"
                      :value="getDeleteItems"
                      @input="itemDelete"
                      :loading="getLoading"
                      show-select
                      mobile-breakpoint="0">
            <template v-slot:top>
                <Header :darkmode="darkmode"
                        :params="data"
                        :dataDelete="getDeleteItems"
                        :user-permission="userAccessPermission" />
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <ActionComponents :darkmode="darkmode"
                                  :item="item"
                                  :dataDelete="getDeleteItems"
                                  :params="data"
                                  :user-permission="userAccessPermission" />
            </template>
        </v-data-table>
        <Dialog :darkmode="darkmode"
                :loading="loading"
                :role="getCurrentRole"
                :dialog="getPopUp"
                :selection="getPermissionSelection"
                :tree-menu="getTreeMenu"
                :is-validate.sync="valid"
                v-on:submit="handleSubmit"
                v-on:onCloseDialog="fetchPopUp(false)" />
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapMutations } from "vuex";

    export default {
        components: {
            ActionComponents: () => import("./_components/action"),
            Header: () => import("./_components/header.vue"),
            Dialog: () => import("./_components/dialog.vue"),
        },
        data: () => ({
            valid: true,
            footerProps: {
                'items-per-page-options': [10, 20, 50, 100, 200]
            },
            data: {
                descendingFormat: [true],
                sortByFormat: ['_id'],
                descending: true,
                sortBy: '_id',
                page: 1,
                rowsPerPage: 10,
                fields: ['role_name', 'descr', 'status', 'updatedAt'],
                search: ''
            },
            loading: false
        }),
        computed: {
            headers() {
                return [
                    { text: this.$t('header.role'), align: 'start', value: 'role_name' },
                    { text: this.$t('header.description'), value: 'descr', sortable: false },
                    { text: this.$t('header.created_at'), value: 'createdAt', sortable: false },
                    { text: this.$t('header.actions'), value: 'actions', sortable: false },
                ];
            },
            ...mapGetters("$_modules", { darkmode: "getDarkMode" }),
            ...mapGetters("$_menu", ["getTreeMenu"]),
            ...mapGetters("$_role", ["getRoles", "getPopUp", "getCurrentRole", "getDeleteItems", "getPermissionSelection", "getLoading"]),
        },
        async created() {
            await this.fetchUserAccessPermission(this.$route.path)
            await this.getData()
        },
        methods: {
            async handleSubmit() {
                this.loading = true
                if (!this.getCurrentRole._id) {
                    await this.createRole({ vm: this, data: this.getCurrentRole })
                } else {
                    await this.updateRole({ vm: this, data: this.getCurrentRole })
                }
                await this.getData()
                this.loading = false
            },

            async getData() {
                this.checkUserPermission('VIEW') ? await this.fetchListRoles(this.data) : []
            },

            itemDelete(e) {
                const items = e.map(function (e) {
                    return { _id: e._id }
                })
                this.DELETE_ITEMS(items)
            },
            ...mapMutations("$_role", ["DELETE_ITEMS", "UPDATE_ROLES"]),
            ...mapActions("$_role", ["fetchListRoles", 'fetchPopUp', "createRole", "updateRole"]),
        },
        async beforeRouteLeave(to, from, next) {
            this.UPDATE_ROLES([])
            this.UPDATE_USER_ACCESS_PERMISSION([])
            next()
        },
    };
</script>
