<template>
  <div>
    <!-- sort-by="user_name" -->
    <v-data-table
      :headers="headers"
      :items="getStatement ? getStatement.object : []"
      
      :server-items-length="getCountStatement.count"
      item-key="_id._id"
      class="elevation-1"
      style="font-family: 'khmer mef1'"
      :footer-props="footerProps"
      :page.sync="data.page"
      :items-per-page.sync="data.rowsPerPage"
      :sort-desc.sync="data.descendingFormat"
      :sort-by.sync="data.sortByFormat"
      @update:page="getData"
      @update:items-per-page="getData"
      @update:sort-desc="getData"
       :loading="getLoading"
      mobile-breakpoint="0"
    >
      <template v-slot:top>
        <Header
          :darkmode="darkmode"
          :params="data"
          :user="user"
          :time="time"
          @child-checkbox="checkboxValue"
          v-on:child-checkbox="updateParent"
        />
        <div class="between w-full mb-3 mt-0 pr-3 ">
          <v-btn
            v-for="(item, i) in defaultFilterDates"
            small
            color="success"
            class="mx-4 mb-4"
            :key="i"
            @click="selectDate(item)"
          >
            <span style="color: white">{{ item.name }}</span>
          </v-btn>
        </div>
      </template>

      <template v-slot:[`item`]="{ item }">
        <tr>
          <td>{{ getStatement.object.indexOf(item) + 1 }}</td>
          <td>{{ item._id.group_no }}</td>
          <td>{{ item._id.fight_no }}</td>
          <td class="amount-bet-color to-detail"  @click="_goToDetailByUser(item._id.group_no,item._id.fight_no)">{{ currencyFormat(item.amount) }}</td>
          <td :class="item.amount_win  < 0 ? 'lose-color' : 'win-color'">{{ currencyFormat(item.amount_win) }}</td>
        </tr>
      </template>
      <template slot="body.append">
        <tr>
          <td :colspan="3" class="text-xs-center pl-4 py-2 ">
            {{ $t("title.total") }}
          </td>
          <td class="text-xs-center total-style pl-4 amount-bet-color py-2">
            {{
              getCountStatement.amount
                ? currencyFormat(getCountStatement.amount)
                : 0
            }}
          </td>
          <td
            class="text-xs-center pl-4 total-style font-weight-bold  py-2"
            :style="
              getCountStatement.amount_win < 0
                ? 'color:#BA2D4A'
                : 'color:#39afd6'
            "
          >
            {{
              currencyFormat(getCountStatement.amount_win)
            }}
          </td>
        </tr>
      </template>
    </v-data-table>
<!-- Detail Win Lose -->
    <v-dialog
    :value="dialogWinLoseDetail"
    max-width="1200"
    persistent
    transition="dialog-bottom-transition"
          >
            <v-card >
              <v-toolbar>
                <v-btn icon  @click="dialogWinLoseDetail=false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-row class="pl-5">
                  <v-col cols="6" sm="6" md="3" class="text-left mt-4">
                    <v-toolbar-title>Win Lose Cock Detail</v-toolbar-title>
                  </v-col>
                  <v-col cols="6" sm="6" md="6" style="padding: 10px">
                    <!-- <v-select
                    v-model="module_id"
                    :items="getModule"
                    :label="$t('input.select_module')"
                    item-text="name"
                    item-value="_id"
                    @input="selectModule(module_id)"
                  ></v-select> -->
                  </v-col>
                    
                </v-row>
                <v-data-table
                    style="font-family: 'khmer mef1'"
                    class="elevation-1"
                    item-key="date"
                    :headers="headersWinLoseDetail"
                    :footer-props="footerProps"
                    :items="detailWinLose ? detailWinLose : []"
                    :server-items-length="detailWinLoseCount ? parseInt(detailWinLoseCount.count): null"
                    :sort-desc.sync="dataRequest.descendingFormat"
                    :sort-by.sync="dataRequest.sortByFormat"
                    :page.sync="dataRequest.page"
                    :items-per-page.sync="dataRequest.rowsPerPage"
                    @update:page="updatePage"
                    @update:items-per-page="updatePage"
                    @update:sort-desc="updatePage"
                    :loading="getLoadingDetailWinLose"
                    mobile-breakpoint="0"
                >
                  <template v-slot:[`item`]="{ item }">
                    <tr>
                      <td>{{ detailWinLose.indexOf(item) + 1 }}</td>
                      <td>{{ item._id.player }}</td>
                      <td>{{ item._id.group_no }}</td>
                      <td>{{ item._id.fight_no }}</td>
                      <td class="to-detail" style="color: goldenrod;" @click="_goToMoreDetailByUser(item._id.group_no,item._id.fight_no,item._id.player_id)">{{ currencyFormat(item.amount) }} $ </td>
                      <td :style="item.amount_win < 0 ? 'color:#BA2D4A': 'color:#39afd6'">{{ currencyFormat(item.amount_win)}} $</td>
                      
                    </tr>
                  </template>
                  <template slot="body.append">
                    <tr>
                      <td :colspan="4" class="text-xs-center pl-4 py-2 ">
                        {{ $t("title.total") }}
                      </td>
                      <td class="text-xs-center total-style pl-4 amount-bet-color py-2">
                        {{
                          detailWinLoseCount.amount
                            ? currencyFormat(detailWinLoseCount.amount)
                            : 0
                        }} $
                      </td>
                      <td
                        class="text-xs-center pl-4 total-style font-weight-bold  py-2"
                        :style="
                          detailWinLoseCount.amount_win < 0
                            ? 'color:#BA2D4A'
                            : 'color:#39afd6'
                        "
                      >
                        {{
                          detailWinLoseCount.amount_win
                            ? currencyFormat(
                              detailWinLoseCount.amount_win
                              )
                            : 0
                        }}
                        $
                      </td>
                      <td class="text-xs-center total-style pl-4  py-2">
                        
                      </td>
                    </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- More Detail Win Lose -->
    <v-dialog
    :value="dialogMoreWinLoseDetail"
    max-width="1200"
    persistent
    transition="dialog-bottom-transition"
          >
            <v-card >
              <v-toolbar>
                <v-btn icon  @click="dialogMoreWinLoseDetail=false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-row class="pl-5">
                  <v-col cols="6" sm="6" md="3" class="text-left mt-4">
                    <v-toolbar-title>Win Lose Cock More Detail</v-toolbar-title>
                  </v-col>
                  <v-col cols="6" sm="6" md="6" style="padding: 10px">
                    <!-- <v-select
                    v-model="module_id"
                    :items="getModule"
                    :label="$t('input.select_module')"
                    item-text="name"
                    item-value="_id"
                    @input="selectModule(module_id)"
                  ></v-select> -->
                  </v-col>
                    
                </v-row>
                <v-data-table
                    style="font-family: 'khmer mef1'"
                    class="elevation-1"
                    item-key="date"
                    :headers="headersMoreWinLoseDetail"
                    :footer-props="footerProps"
                    :items="moreDetailWinLose ? moreDetailWinLose : []"
                    :server-items-length="moreDetailWinLoseCount ? parseInt(moreDetailWinLoseCount.count): null"
                    :sort-desc.sync="dataMoreDetail.descendingFormat"
                    :sort-by.sync="dataMoreDetail.sortByFormat"
                    :page.sync="dataMoreDetail.page"
                    :items-per-page.sync="dataMoreDetail.rowsPerPage"
                    @update:page="updatePageMoreDetail"
                    @update:items-per-page="updatePageMoreDetail"
                    @update:sort-desc="updatePageMoreDetail"
                    :loading="getLoadingMoreDetailWinLose"
                    mobile-breakpoint="0"
                >
                  <template v-slot:[`item`]="{ item }">
                    <tr>
                      <td>{{ moreDetailWinLose.indexOf(item) + 1 }}</td>
                      <td>{{ item.user_name }}</td>
                      <td>{{ item.public_ip }}</td>
                      <td>{{ item.fight_no }}</td>
                      <td>{{ item.group_no }}</td>
                      <td>{{ item.bet_type }}</td>
                      <td>{{ item.payout }}</td>
                      <td style="color: goldenrod;" >{{ currencyFormat(item.amount) }} $ </td>
                      <td :style="item.amount_win < 0 ? 'color:#BA2D4A': 'color:#39afd6'">{{ currencyFormat(item.amount_win)}} $</td>
                      
                    </tr>
                  </template>
                  <template slot="body.append">
                    <tr>
                      <td :colspan="7" class="text-xs-center pl-4 py-2 ">
                        {{ $t("title.total") }}
                      </td>
                      <td class="text-xs-center total-style pl-4 amount-bet-color py-2">
                        {{
                          detailWinLoseCount.amount
                            ? currencyFormat(detailWinLoseCount.amount)
                            : 0
                        }} $
                      </td>
                      <td
                        class="text-xs-center pl-4 total-style font-weight-bold  py-2"
                        :style="
                          detailWinLoseCount.amount_win < 0
                            ? 'color:#BA2D4A'
                            : 'color:#39afd6'
                        "
                      >
                        {{
                          detailWinLoseCount.amount_win
                            ? currencyFormat(
                              detailWinLoseCount.amount_win
                              )
                            : 0
                        }}
                        $
                      </td>
                      <td class="text-xs-center total-style pl-4  py-2">
                        
                      </td>
                    </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import headers from "./_api/columns";
import headersWinLoseDetail from "./_api/columns_win_lose_detail";
import headersMoreWinLoseDetail from "./_api/columns_more_win_lose_detail";
import moment from "moment";

export default {
  components: {
    // ActionComponents: () => import("./_components/Action"),
    Header: () => import("./_components/HeaderTable.vue"),
  },

  data: () => ({
    valid: true,
    headers: headers,
    headersWinLoseDetail: headersWinLoseDetail,
    headersMoreWinLoseDetail: headersMoreWinLoseDetail,
    search: null,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    data: {
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: ["_id.group_no","_id.fight_no"],
      search: "",
      startDate:  moment().format("YYYY-MM-DD"),
      endDate:  moment().format("YYYY-MM-DD"),
      module_id: null,
      currency_type: 1
    },
    dataRequest: {
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: ["_id.group_no","_id.fight_no"],
      search: "",
      startDate:  moment().format("YYYY-MM-DD"),
      endDate:  moment().format("YYYY-MM-DD"),
      module_id: null,
      currency_type: 1,
      group_no:null,
      fight_no:null,
    },
    dataMoreDetail: {
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: ["user_name"],
      search: "",
      group_no:null,
      fight_no:null,
      user_id:null,
    },
    dialogWinLoseDetail:false,
    detailWinLose:[],
    detailWinLoseCount:[],
    getLoadingDetailWinLose:false,
    dialogMoreWinLoseDetail:false,
    moreDetailWinLose:[],
    moreDetailWinLoseCount:[],
    getLoadingMoreDetailWinLose:false,
    dialog: false,
    loading: false,
    loader: false,
    user_id: null,
    group_no:null,
    fight_no:null,
    module_id: null,
    currency_type: 1,
    check: {
      startDate: moment().format("YYYY-MM-DD"),
      endDate:  moment().format("YYYY-MM-DD"),
    },
    
    format: "YYYY-MM-DD",
    defaultFilterDates: [
      {
        name: "Today",
        active: false,
      },
      {
        name: "Yesterday",
        active: false,
      },
      {
        name: "This Week",
        active: false,
      },
      {
        name: "Last Week",
        active: false,
      },
      {
        name: "This Month",
        active: false,
      },
      {
        name: "Last Month",
        active: false,
      },
    ],
    totalItems: 0,
    time: null,
  }),
  watch: {
    user: function() {
      this.getData();
    },
    data: function() {
      this.getData();
    },
    getStatement: function() {},
  },
  computed: {
    ...mapGetters("$_modules", { darkmode: "getDarkMode", user: "getUser" }),
    ...mapGetters("$_winLoseRatStatement", ["getStatement", "getCountStatement","getLoading"]),
  },
  async created() {
    await this.getData();
  },
  methods: {
    moment,
    selectDate({ name }) {
      const { format } = this;
      switch (name) {
        case "Today":
          this.startDate = this.moment().format(format);
          this.endDate = this.moment().format(format);
          break;
        case "Yesterday":
          this.startDate = this.moment()
            .subtract(1, "days")
            .format(format);
          this.endDate = this.moment().subtract(1, "days").format(format);
          break;
        case "This Week":
          this.startDate = this.moment()
            .startOf("isoweek")
            .format(format);
          this.endDate = this.moment()
            .endOf("isoWeek")
            .format(format);
          break;
        case "Last Week":
          this.startDate = this.moment()
            .subtract(1, "isoweek")
            .startOf("isoweek")
            .format(format);
          this.endDate = this.moment()
            .subtract(1, "isoweek")
            .endOf("isoweek")
            .format(format);
          break;
        case "This Month":
          this.startDate = this.moment().format("YYYY-MM-01");
          this.endDate = this.moment().format(format);
          break;
        case "Last Month":
          this.startDate = this.moment()
            .subtract(1, "months")
            .startOf("month")
            .format(format);
          this.endDate = this.moment()
            .subtract(1, "months")
            .endOf("month")
            .format(format);
          break;
        default:
          break;
      }
      const { startDate, endDate } = this;
      this.time = { startDate: startDate, endDate: endDate };
      this.data.startDate = startDate;
      this.data.endDate = endDate;
      this.getData();
      this.check = { startDate, endDate };
    },
    // Gets the checkbox information from the child component
    checkboxValue: function(params) {
      this.check = params;
      this.data.startDate = params.startDate;
      this.data.endDate = params.endDate;
    },
    currencyFormat(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      // return parseInt(num).toLocaleString('en')
    },
    _goToDetailByUser(fight_no,group_no){
      this.fight_no = fight_no;
      this.group_no = group_no;
      this.loadDetailWinLose(fight_no,group_no);
      this.dialogWinLoseDetail = true;   
    },
    async loadDetailWinLose(fight_no,group_no){
     this.detailWinLose = [];
     this.detailWinLoseCount = [];
     this.getLoadingDetailWinLose = true;
     
     this.dataRequest.startDate = this.data.startDate;
     this.dataRequest.endDate = this.data.endDate;
      this.dataRequest.sortByFormat.length < 1
       ? (this.dataRequest.sortBy = "_id")
       : (this.dataRequest.sortBy = this.dataRequest.sortByFormat[0]);
     this.dataRequest.descendingFormat.length > 0
       ? (this.dataRequest.descending = this.dataRequest.descendingFormat[0])
       : (this.dataRequest.descending = true);

    //  this.dataRequest.module_id = this.module_id;
     this.dataRequest.currency_type = this.currency_type;
     this.dataRequest.group_no = fight_no;
     this.dataRequest.fight_no = group_no;
    
    await this.$request
       .post({
         url: "getViewWinLoseRatByUser",
         data: this.dataRequest,
       })
       .then((res) => {
         if (res.data.code) {
           this.detailWinLose = res.data.data.object;
           this.detailWinLoseCount = res.data.data.objectCount;
           this.getLoadingDetailWinLose = false;
         }else{
           
           this.getLoadingDetailWinLose = false;
         }
       });
       
   },
   _goToMoreDetailByUser(fight_no,group_no,user_id){
      this.fight_no = fight_no;
      this.group_no = group_no;
      this.user_id = user_id;
      this.loadMoreDetailWinLose(fight_no,group_no,user_id);
      this.dialogMoreWinLoseDetail = true;   
    },
    async loadMoreDetailWinLose(fight_no,group_no,user_id){
     this.moreDetailWinLose = [];
     this.moreDetailWinLoseCount = [];
     this.getLoadingMoreDetailWinLose = true;
     
     this.dataMoreDetail.startDate = this.data.startDate;
     this.dataMoreDetail.endDate = this.data.endDate;
      this.dataMoreDetail.sortByFormat.length < 1
       ? (this.dataMoreDetail.sortBy = "_id")
       : (this.dataMoreDetail.sortBy = this.dataMoreDetail.sortByFormat[0]);
     this.dataMoreDetail.descendingFormat.length > 0
       ? (this.dataMoreDetail.descending = this.dataMoreDetail.descendingFormat[0])
       : (this.dataMoreDetail.descending = true);

    //  this.dataMoreDetail.module_id = this.module_id;
     this.dataMoreDetail.currency_type = this.currency_type;
     this.dataMoreDetail.group_no = fight_no;
     this.dataMoreDetail.fight_no = group_no;
     this.dataMoreDetail.user_id = user_id;
    
    await this.$request
       .post({
         url: "getViewMoreWinLoseRatByUser",
         data: this.dataMoreDetail,
       })
       .then((res) => {
         if (res.data.code) {
           this.moreDetailWinLose = res.data.data.object;
           this.moreDetailWinLoseCount = res.data.data.objectCount;
           this.getLoadingMoreDetailWinLose = false;
         }else{
           
           this.getLoadingMoreDetailWinLose = false;
         }
       });
       
   },

   updatePage() {
      this.getLoadingDetailWinLose = true;
      this.loadDetailWinLose(this.fight_no,this.group_no);
      this.getLoadingDetailWinLose = false;
    },
    updatePageMoreDetail(){
      this.getLoadingMoreDetailWinLose = true;
      this.loadMoreDetailWinLose(this.fight_no,this.group_no,this.user_id);
      this.getLoadingMoreDetailWinLose = false;
    },
    updateParent(payload){
      this.data.startDate = payload.startDate;
      this.data.endDate = payload.endDate;
      this.data.module_id = payload.module_id;
      this.data.currency_type = payload.currency_type;
    },
    async getData() {
      try {
        this.loader = true;
          let preData = {
            data: this.data,
          };
          await this.fetchListStatement(preData)
          this.loader = false;
      } catch (error) {
        // console.log("")
      }
    },
    ...mapMutations("$_winLoseRatStatement", ["LIST_STATEMENT", "COUNT-STATEMENT"]),
    ...mapActions("$_winLoseRatStatement", ["fetchListStatement"]),
  },
};
</script>

<style lang="scss" scoped>
.users {
  width: 50%;
  float: left;

  h3 {
    font-size: 17px;
  }
}

table {
  font-family: "khmer mef1" !important;
}
.amount-bet-color {
  color: goldenrod !important;
}
.win-color {
  color: rgb(57, 175, 214);
  font-weight: bold;
}
.lose-color {
  color: #ba2d4a;
  font-weight: bold;
}
.tab-filter {
  display: flex;
  flex-direction: row;
}
.row {
  display: flex;
  flex-direction: row;
}
.between {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.w-full {
  width: 100%;
}
.to-detail{
  cursor: pointer;
  text-decoration: underline;
}
</style>
