<template>
  <div>
    <v-row class="justify-center py-5 cm-title">
      Live Betting Yuki Live
    </v-row>
    <div class="w-100 text-right">
      <v-btn class="white--text mr-4 mt-3" color="green darken-1" depressed @click="searchData">
        <!-- @click="fetchListStatement(params)" -->
        Refresh
      </v-btn>
    </div>
    <!--    Display List Front Page-->
    <template>
      <v-row>
          <v-card
            class="mx-auto"
            width="800"
            outlined
            style="margin-top:120px"
          >
          <v-row>
            <v-col cols="4" sm="4" md="4" class="text-left mt-5">
              <v-card-title style="margin-top: -13px;"> <strong>Group No:  </strong></v-card-title>
            </v-col>
            <v-col cols="2" sm="2" md="2" class="text-left mt-5">
              <span class="fino">{{ channel.group_no?channel.group_no:0}}</span>
            </v-col>
            <v-col cols="4" sm="4" md="4" class="text-left mt-5">
              <v-card-title style="margin-top: -13px;"><strong>Fight No:  </strong></v-card-title>
            </v-col>
            <v-col cols="2" sm="2" md="2" class="text-left mt-5">
              <span class="fino">{{ channel.fight_no?channel.fight_no:0}}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="4" md="4" class="text-left mt-5">
              <v-card-title style="margin-top: -10px;color: goldenrod;" @click="_goToDetail(channel,1)" class="row-pointer"> <strong>Balance USD:  </strong></v-card-title>
            </v-col>
            <v-col cols="2" sm="2" md="2" class="text-left mt-5">
              <span class="fino1" @click="_goToDetail(channel,1)">{{channel.balance_usd?channel.balance_usd:0}}</span>
              <!-- <v-avatar
                color="orange"
                size="36"
                class="row-pointer"
                @click="_goToDetail(channel,1)"
              >
                <span class="white--text text-h5 ">{{channel.balance_usd?channel.balance_usd:0}}</span>
              </v-avatar> -->
            </v-col>
            <v-col cols="4" sm="4" md="4" class="text-left mt-5">
              <v-card-title style="margin-top: -10px;color: goldenrod;" @click="_goToDetail(channel,1)" class="row-pointer"><strong>Balance KH:  </strong></v-card-title>
            </v-col>
            <v-col cols="2" sm="2" md="2" class="text-left mt-5">
              <span class="fino1" @click="_goToDetail(channel,2)">{{channel.balance_kh?channel.balance_kh:0}}</span>
              <!-- <v-avatar
                color="orange"
                size="36"
                class="row-pointer"
                @click="_goToDetail(channel,2)"
              >
                <span class="white--text text-h5 ">{{channel.balance_kh?channel.balance_kh:0}}</span>
              </v-avatar> -->
            </v-col>
          </v-row>
          </v-card>
      </v-row>
    </template>

    <!--    Detail Each Match -->
    <v-dialog :value="dialog" max-width="1200" persistent transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="pl-5">
            <v-col cols="12" sm="12" md="12" class="text-left mt-5">
              <v-row>
              <v-toolbar-title>Detail Player Statement
               
                <!-- <span style="margin-left:20px;"></span> ( Channel Type:
                <span class="detail_ch_number">{{
                    dataRequest.channel_type == 1 ? "1" : dataRequest.channel_type == 2 ? "2" : dataRequest.channel_type
                }}
                </span>) -->
   
       
         
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn class="white--text btn-custom " color="primary darken-1" style="margin-right:28px" depressed @click="searchq"
                :disabled="this.loadingGG">
                <!-- @click="fetchListStatement(params)" -->
                Refresh
              </v-btn>
            </v-row>
            </v-col>

            <v-col cols="8" sm="8" md="8" class="text-right mb-4">
              <v-text-field v-model="v_search" append-icon="mdi-magnify" label="Search" single-line hide-details
                clearable class="mr-5" />
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="2" sm="2" md="2" class="text-right mb-4">
              <v-btn class="white--text btn-custom mr-4 mt-3" color="green darken-1" depressed @click="searchq"
                :disabled="loadingGG">
                <!-- @click="fetchListStatement(params)" -->
                SEARCH
              </v-btn>
            </v-col>

          </v-row>
          <v-data-table style="font-family: 'khmer mef1'" class="elevation-1" item-key="date" :headers="headers"
            :footer-props="footerProps" :items="detailData ? detailData : []" :server-items-length="
              detailDataCount ? parseInt(detailDataCount.count) : null
            " :sort-desc.sync="dataRequest.descendingFormat" :sort-by.sync="dataRequest.sortByFormat"
            :page.sync="dataRequest.page" :items-per-page.sync="dataRequest.rowsPerPage" @update:page="updatePage"
            @update:items-per-page="updatePage" @update:sort-desc="updatePage" :loading="loadingGG"
            mobile-breakpoint="0">
            <template v-slot:[`item`]="{ item }">
              <tr>
                <td>
                  {{
                      detailData
                        ? detailData.indexOf(item) +
                        (dataRequest.page - 1) * dataRequest.rowsPerPage +
                        1
                        : "loading ..."
                  }}
                </td>
                <td>{{ item.date }}</td>
                <td>{{ item.user_name }}</td>
                <td>{{ item.public_ip }}</td>
                <td>{{ item.fight_no }}</td>
                <td :class="item.bet_type">
                  {{ item.bet_type }}
                </td>
                <td class="amount-bet-color">
                  {{
                      dataRequest && dataRequest.currency_type == 2
                        ? currencyFormatKm(item.amount)
                        : dataRequest.currency_type &&
                          dataRequest.currency_type != 2
                          ? currencyFormatEn(item.amount)
                          : "Loading ..."
                  }}
                </td>
                <td class="font-weight-bold">
                  {{ currencyFormatEn(item.payout) }}
                </td>
              </tr>
            </template>
            <template slot="body.append">
              <td :colspan="6" class="text-xs-center pl-4 py-2 ">
                {{ $t("title.total") }}
              </td>
              <td class="text-xs-center total-style pl-4  py-2">
                {{
    dataRequest &&
      dataRequest.currency_type == 2 &&
      detailDataCount
      ? currencyFormatKm(detailDataCount.amount)
      : dataRequest.currency_type &&
        dataRequest.currency_type != 2 &&
        detailDataCount
        ? currencyFormatEn(detailDataCount.amount)
        : "Loading ..."
                }}
              </td>
            </template>
            <template slot="body.append">
            <tr>

              <td style="text-align:center " colspan="9" v-if="loadingGG">
                <div class="lds-roller my-3">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </td>
            </tr>
          </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";

export default {
  components: {},

  data: () => ({
    v_search: null,
    getLoading: false,
    headers: [
      {
        text: "ល.រ",
        align: "start",
        value: "no",
      },
      { text: "កាលបរិច្ឆេទ", value: "date" },
      { text: "ឈ្មោះ", value: "user_name" },
      { text: "Public Ip", value: "public_ip" },
      { text: "ប្រកួតលេខ", value: "fight_no" },
      { text: "ប្រភេទចាក់", value: "co_type_of_betting.name" },
      { text: "ប្រាក់ចាក់", value: "amount" },
      { text: "Payout", value: "payout" },
    ],
    headers1: [
      {
        text: "Meron",
        value: "Meron_Ch1",
        format: (v) => v && Number(v).toFixed(2),
        align: "left",
      },
      {
        text: "Wala",
        value: "Wala_Ch1",
        format: (v) => v && Number(v).toFixed(2),
        align: "left",
      },
    ],
    headers2: [
      {
        text: "Meron",
        value: "Meron_Ch2",
        format: (v) => v && Number(v).toFixed(2),
        align: "left",
      },
      {
        text: "Wala",
        value: "Wala_Ch2",
        format: (v) => v && Number(v).toFixed(2),
        align: "left",
      },
    ],
    headers3: [
      {
        text: "Meron",
        value: "Meron_Ch3",
        format: (v) => v && Number(v).toFixed(2),
        align: "left",
      },
      {
        text: "Wala",
        value: "Wala_Ch3",
        format: (v) => v && Number(v).toFixed(2),
        align: "left",
      },
    ],
    headers4: [
      {
        text: "Meron",
        value: "Meron_Ch4",
      },
      {
        text: "Wala",
        value: "Wala_Ch4",
      },
    ],
    StatementObj: [
      {
        currency: "$",
        group_no: 0, 
        fight_no: 0, 
      },
      {
        currency: "៛",
        group_no: 0, 
        fight_no: 0, 
      },
    ],
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    dataRequest: {
      descendingFormat: [true],
      sortByFormat: ["_id._id"],
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: [
        "user_name",
        "public_ip"
      ],
      search: "",
      fight_no: null,
    },
    dialog: false,
    loading: false,
    loader: false,
    detailData: [],
    detailDataCount: [],
    ch: [],
    channel_type: null,
    loadingGG: false,
    reloadingDetail :false,
  }),
  watch: {
    // channel_1: function (newV) {
    //   if (newV) this.getFirstData();
    // },
    StatementObj: function () { },
    ch: function () { },
    userp: function () { }
  },
  computed: {
    ...mapGetters("$_modules", { user: "getUser" }),
    ...mapGetters("$_liveBettingYukiLive", {
      channel: "getChannel",
    }),
  },
  async created() {
    await this.getData();
  },
  methods: {
    processTableHeaders(headers) {
      const nested = !!headers.some((h) => h.children);

      if (nested) {
        let children = [];
        const h = headers.map((h) => {
          if (h.children && h.children.length > 0) {
            children.push(...h.children);

            return {
              rowspan: 1,
              colspan: h.children.length,
              text: h.text,
              align: h.align,
            };
          }
          return {
            rowspan: 2,
            colspan: 1,
            text: h.text,
            align: h.align,
          };
        });

        return {
          children: children,
          parents: h,
        };
      }
      return {
        parents: headers,
      };
    },
    moment,
    currencyFormatEn(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    currencyFormatKm(num) {
      num = parseFloat(num);
      return num.toLocaleString("en");
    },
    _goToDetail(channel,currency_type ) {
      this.dataRequest.search = "";
      this.v_search = "";
      this.detailData = [];
      this.detailDataCount = [];
      // this.dataRequest.bet_type = selectedData.bet_type;
      this.dataRequest.currency_type = currency_type;
      // this.dataRequest.channel_type = channel.channel_type;
      this.dataRequest.fight_no = channel.fight_no;
      this.dataRequest.group_no = channel.group_no;
      this.dialog = true;
      this.loadDetailData();
    },
    async loadDetailData() {
      this.loadingGG = true;
      this.dataRequest.sortByFormat.length < 1
        ? (this.dataRequest.sortBy = "_id")
        : (this.dataRequest.sortBy = this.dataRequest.sortByFormat[0]);
      this.dataRequest.descendingFormat.length > 0
        ? (this.dataRequest.descending = this.dataRequest.descendingFormat[0])
        : (this.dataRequest.descending = true);
      await this.$request
        .post({
          url: "getViewOutStandingYukiLiveDetail",
          data: this.dataRequest,
        })
        .then((res) => {
          if (res.data.code) {
            this.detailData = res.data.data.object;
            this.detailDataCount = res.data.data.objectCount;
          } 
        });
        this.loadingGG = false;
    },
    async getData() {
      try {
        this.loader = true;
        await this.fetchListStatement();
        this.loader = false;
      } catch (error) {
        // console.log(error)
      }


    },
    searchq() {
      this.dataRequest.search = this.v_search;
      this.updatePage();
    },
    updatePage() {
      this.loadingGG = true;
      this.loadDetailData();
      this.loadingGG = false;
    },
    searchData() {
      this.getData()
    },

    ...mapMutations("$_liveBettingYukiLive", ["CHANNEL"]),
    ...mapActions("$_liveBettingYukiLive", ["fetchListStatement"]),
  },
};
</script>

<style lang="scss" scoped>
.fino {
  color: #6699cc;
  font-size: 24px;
  font-weight: bold;
  margin-left: 10px;
}
.fino1 {
  color: #6699cc;
  font-size: 24px;
  font-weight: bold;
  margin-left: 10px;
  text-decoration: underline;
  cursor: pointer;
}

.cm-title {
  color: black;
  font-weight: 900;
  font-size: 32px;
  position: absolute;
  top: 105px;
  left: 40%;
  margin-left: 0;
}

.row-pointer:hover {
  cursor: pointer;
  background-color: rgb(231, 224, 117);
  text-decoration: underline;
}

.users {
  width: 50%;
  float: left;

  h3 {
    font-size: 17px;
  }
}

table {
  font-family: "khmer mef1" !important;
}

.amount-bet-color {
  color: goldenrod !important;
}

.wala {
  color: rgb(57, 175, 214);
  font-weight: bold;
  text-transform:uppercase
}
.win-color {
  color: rgb(57, 175, 214);
  font-weight: bold;
  text-transform:uppercase
}

.meron {
  color: #ba2d4a;
  font-weight: bold;
  text-transform:uppercase
}
.lose-color {
  color: #ba2d4a;
  font-weight: bold;
  text-transform:uppercase
}

.tie {
  color: green;
  font-weight: bold;
  text-transform:uppercase
}

.tab-filter {
  display: flex;
  flex-direction: row;
}

.row {
  display: flex;
  flex-direction: row;
}

.between {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.w-full {
  width: 100%;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #cef;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.tie-color {
  color: green;
}

.channel_number_style {
  font-size: 1.8rem;
  color: white;
  font-weight: bold;
  padding: 5px 14px;
  margin-left: 5px;
  background-color: #6699cc;
  border-radius: 100px;
}

.table-style {
  font-family: 'khmer mef1';
  border: 1px solid black;
}

.detail_ch_number {
  margin-left: 10px;
  color: #6699cc;
  font-size: 24px;
  font-weight: bold
}
.num-1{
  color: #8aa000;
  font-weight: bold;
  text-transform:uppercase
}
.num-2{
  color: #ff5f30;
  font-weight: bold;
  text-transform:uppercase
}
.num-3{
  color: #fe9808;
  font-weight: bold;
  text-transform:uppercase
}
.num-4{
  color: #715b06;
  font-weight: bold;
  text-transform:uppercase
}
.num-5{
  color: #548c2b;
  font-weight: bold;
  text-transform:uppercase
}
.num-6{
  color: #66ab44;
  font-weight: bold;
  text-transform:uppercase
}
.num-7{
  color: #FF00FF;
  font-weight: bold;
  text-transform:uppercase
}
.num-8{
  color: #fbb700;
  font-weight: bold;
  text-transform:uppercase
}
.num-9{
  color: #0000FF;
  font-weight: bold;
  text-transform:uppercase
}
.cancel{
  color: #CBCEC9;
  font-weight: bold;
  text-transform:uppercase
}
</style>
