<template>
    <div>
        <v-data-table style="font-family: 'khmer mef1'"
                      class="elevation-1"
                      item-key="_id"
                      :headers="headers"
                      :items="getTransactionPlayer && getTransactionPlayer.object ? getTransactionPlayer.object : []"
                      :footer-props="footerProps"
                      :server-items-length="getTransactionPlayer.objectCount ? getTransactionPlayer.objectCount.count : null"
                      :page.sync="data.page"
                      :items-per-page.sync="data.rowsPerPage"
                      :sort-desc.sync="data.descendingFormat"
                      :sort-by.sync="data.sortByFormat"
                      @update:page="getData"
                      @update:items-per-page="getData"
                      @update:sort-desc="getData"
                      :loading="getLoading"
                      show-select
                      mobile-breakpoint="0">
        <template v-slot:top>
            <Header :darkmode="darkmode"
                    :params="data"
                    :user-permission="userAccessPermission" 
                    @child-checkbox="checkboxValue"
                    v-on:child-checkbox="updateParent"
                    />
        </template>
        <template v-slot:[`item.tranc_type`]="{ item }">
            <span v-if="item.tranc_type == 1">New</span>
            <span v-else>Modify</span>
        </template>
        <template v-slot:[`item.is_withdraw`]="{ item }">
            <span v-if="item.is_withdraw == 'true'" style="color:#33C4FF">True</span>
            <span v-else style="color:#FF5733">False</span>
        </template>
        <template v-slot:[`item.amount`]="{ item }">
            <span v-if="item.amount < '0'" style="color:#BA2D4A">{{item.amount}}</span>
            <span v-else style="color:#39afd6">{{item.amount}}</span>
        </template>
        <template slot="body.append">
        <tr>
          <td :colspan="7" class="text-xs-center pl-4 py-2 ">
            {{ $t("title.total") }}
          </td>
          <td class="text-xs-center total-style pl-4  py-2">
            {{
             getTransactionPlayer.objectCount
                ?  getTransactionPlayer.objectCount.balance
                : 0
            }} 
          </td>
          
        </tr>
      </template>
        </v-data-table>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapMutations } from "vuex";
    import moment from "moment";
    export default {
        components: {
            Header: () => import("./_components/header.vue"),
        },
        data: () => ({
            valid: true,
            footerProps: {
                'items-per-page-options': [10, 20, 50, 100, 200]
            },
            data: {
                descendingFormat: [true],
                sortByFormat: ['_id'],
                descending: true,
                sortBy: '_id',
                page: 1,
                rowsPerPage: 10,
                fields: ['module_name', 'user_name','module_bet_id'],
                search: '',
                startDate:  moment().format("YYYY-MM-DD"),
                endDate:  moment().format("YYYY-MM-DD"),
            },
            loading: false
        }),
        computed: {
            headers() {
                return [
                    { text: this.$t('header.userName'), align: 'start', value: 'user_name' },
                    { text: this.$t('header.moduleName'), value: 'module_name', sortable: false },
                    { text: this.$t('header.tranc_type'), value: 'tranc_type', sortable: false },
                    { text: this.$t('header.amount'), value: 'amount', sortable: false },
                    { text: this.$t('header.old_balance'), value: 'old_balance', sortable: false },
                    { text: this.$t('header.new_balance'), value: 'new_balance', sortable: false },
                    { text: "គណនីនៅសល់", value: 'balance', sortable: false },
                    { text: this.$t('header.module_bet_id'), value: 'module_bet_id', sortable: false },
                    { text: this.$t('header.channel_type'), value: 'channel_type', sortable: false },
                    { text: this.$t('header.is_withdraw'), value: 'is_withdraw', sortable: false },
                    { text: this.$t('header.created_at'), value: 'date', sortable: false },
                ];
            },
            ...mapGetters("$_modules", { darkmode: "getDarkMode" }),
            ...mapGetters("$_menu", ["getTreeMenu"]),
            ...mapGetters("$_transactionPlayer", ["getTransactionPlayer","getLoading"]),
        },
        async created() {
            await this.fetchUserAccessPermission(this.$route.path)
            await this.getData()
        },
        methods: {
            async getData() {
                this.checkUserPermission('VIEW') ? await this.fetchListTransactionPlayer(this.data) : [];
            },
            updateParent(payload){
                this.data.startDate = payload.startDate;
                this.data.endDate = payload.endDate;
                this.data.search = payload.search;
            },
            checkboxValue: function(params) {
                this.data.startDate = params.startDate;
                this.data.endDate = params.endDate;
            },
            ...mapMutations("$_transactionPlayer", ["DELETE_ITEMS", "UPDATE_MODULES"]),
            ...mapActions("$_transactionPlayer", ["fetchListTransactionPlayer"]),
        },
        async beforeRouteLeave(to, from, next) {
            this.UPDATE_MODULES([])
            next()
        },
    };
</script>
<style lang="scss" scoped>
.win-color {
  color: rgb(57, 175, 214);
  font-weight: bold;
}
.lose-color {
  color: #ba2d4a;
  font-weight: bold;
}
</style>
