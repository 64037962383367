import liveBettingPP78 from '@/modules/liveBettingPP78';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/liveBettingPP78',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: liveBettingPP78,
				name: 'liveBettingPP78'
			}
		]
	}
];

export default routes;
