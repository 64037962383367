export default {
    permission: 'សិទ្ធអ្នកប្រើប្រាស់',
    role: 'ប្រភេទអ្នកប្រើប្រាស់',
    description: 'បរិយាយ',
    menu_name: 'ឈ្មោះ Menu',
    icon: 'រូបតំណាង',
    order: 'លេខលំដាប់',
    active: 'សកម្មភាព(ការចាក់)',
    actions: 'សកម្មភាព',
    balance: 'គណនី',
    created_at: 'កាលបរិច្ឆេទ',
    bank_account: 'ព័ត៌មានគណនីធនាគារ',
    user_name: 'ឈ្មោះអ្នកប្រើប្រាស់',
    deposit_withdraw: 'ដក/ដាក់ប្រាក់',
    address :"អាស័យដ្ឋាន IP",
    no :"ល.រ",
    suspend:"បិទដំណើរការ​(ការចូលប្រព័ន្ធ)",
    public_ip:"អាស័យដ្ឋាន",
    name: "ឈ្មោះ",
    sub_link: "Sub Link",
    app_id: "App Id",
    is_enable: "Is Enable",
    sync_date: "Sync Date",
    riel: "រៀល",
    dollar: "ដុល្លា",
    baht: "បាត",
    front_player_link: "តំណភ្ជាប់សម្រាប់ Front Player",
    api_player_link: "តំណភ្ជាប់សម្រាប់ API Player",
    api_admin_link: "តំណភ្ជាប់សម្រាប់ API Admin",
    status: "ស្ថានភាព",
    background_icon: "រូបតំណាងផ្ទៃខាងក្រោយ",
    userName: "ឈ្មោះអ្នកប្រើប្រាស់",
    moduleName: "ឈ្មោះ Module",
    tranc_type: "ប្រភេទប្រតិបត្តិការ",
    old_balance: "គណនីចាស់",
    new_balance: "គណនីថ្មី",
    module_bet_id: "Module Bet ID",
    channel_type: "ប្រភេទប៉ុស្តិ៍",
    parentName: "អ្នកគ្រប់គ្រង",
    withdrawAmount :"ចំនួនប្រាក់បានដក",
    depositAmount :"ចំនួនប្រាក់បានដាក់",
    oldBalance :"ប្រាក់ដើម",
    newBalance :"ប្រាក់ថ្មី",
    date:"កាលបរិច្ឆេទ",
    module_name: "កម្មវិធី",
    company: "Company",
    senior: "Senior",
    master: "Master",
    agent: "Agent",
    amount: "សរុបប្រាក់ភ្នាល់",
    amount_win: "សរុបឈ្នះ/ចាញ់",
    updateConfig : "កំណត់ការភ្នាល់សម្រាប់មាន់ជល់",
    reportDW : "ប្រតិបត្តិការណ៍លុយ",
    reportBetting : "របាយការណ៍ភ្នាល់",
    is_withdraw : "Is Withdraw",
  
};
