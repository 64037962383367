<template>
  <div>
    <v-data-table
        style="font-family: 'khmer mef1'"
        class="elevation-1"
        item-key="_id"
        :headers="headers"
        :items="getMenus && getMenus.object ? getMenus.object : []"
        :footer-props="footerProps"
        
        :server-items-length="getMenus ? getMenus.objectCount : null"
        :page.sync="data.page"
        :items-per-page.sync="data.rowsPerPage"
        :sort-desc.sync="data.descendingFormat"
        :sort-by.sync="data.sortByFormat"
        @update:page="getData"
        @update:items-per-page="getData"
        @update:sort-desc="getData"
        :loading="getLoading"
        :value="getDeleteItems"
        @input="itemDelete"
        show-select
        mobile-breakpoint="0"
    >
      <template v-slot:top>
        <Header :darkmode="darkmode"
                :params="data"
                :data-delete="getDeleteItems"
                :user-permission="userAccessPermission"/>
      </template>

      <template v-slot:[`item.is_visible`]="{ item }">
        <v-chip label text-color="white" :color="item.is_visible ? 'green' : 'red'">
          {{ item.is_visible ? "Active" : "Inactive" }}
        </v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <ActionComponents :darkmode="darkmode"
                          :item="item"
                          :dataDelete="getDeleteItems"
                          :params="data"
                          :user-permission="userAccessPermission"
        />
      </template>
    </v-data-table>
    <Dialog :darkmode="darkmode"
            :dialog="getPopUp"
            :menu="getCurrentMenu"
            :option-permissions="getPermission.object"
            :option-menus="getParentMenu.object"
            :is-validate.sync="valid"
            :loading="loading"
            v-on:onCloseDialog="fetchPopUp(false)"
            v-on:submit="handleSubmit"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  components: {
    ActionComponents: () => import("./_components/action"),
    Header: () => import("./_components/tableHeader.vue"),
    Dialog: () => import("./_components/dialog.vue"),
  },
  data: () => ({
    valid: true,
    loading:false,
    footerProps: {
      'items-per-page-options': [10, 20, 50, 100, 200]
    },
    data: {
      descendingFormat: [true],
      sortByFormat: ['_id'],
      descending: true,
      sortBy: '_id',
      page: 1,
      rowsPerPage: 10,
      fields: ["name","descr", "order", "icon", "route", "url"],
      search: ''
    }
  }),
  computed: {
    headers() {
      return [
        { text: this.$t('header.menu_name'), align: 'start', value: 'name' },
        { text: this.$t('header.icon'), value: 'icon', sortable: false },
        { text: this.$t('header.order'), value: 'order' },
        { text: this.$t('header.created_at'), value: 'createdAt', sortable: false },
        { text: this.$t('header.active'), value: 'is_visible', sortable: false },
        { text: this.$t('header.actions'), value: 'actions', sortable: false },
      ]
    },
    ...mapGetters("$_modules", {
      darkmode: "getDarkMode",
    }),
    ...mapGetters("$_permission", ["getPermission"]),
    ...mapGetters("$_menu", ["getMenus", "getPopUp", "getCurrentMenu", "getDeleteItems", "getParentMenu", "getLoading"]),
  },
  async created() {
    await this.fetchUserAccessPermission(this.$route.path)
    await this.getData()
  },
  methods: {
    async handleSubmit() {
      this.loading = true
      if (!this.getCurrentMenu._id) {
        await this.createMenu({vm: this, data: this.getCurrentMenu})
      } else {
        await this.updateMenu({vm: this, data: this.getCurrentMenu})
      }
      await this.getData()
      this.loading = false
    },

    async getData() {
      this.checkUserPermission('VIEW') ? await this.fetchListMenus(this.data) : []
    },

    itemDelete(e) {
      const items = e.map(function (e) {
        return {_id: e._id}
      })
      this.DELETE_ITEMS(items)
    },

    ...mapMutations("$_menu", ["DELETE_ITEMS", "UPDATE_MENUS"]),
    ...mapActions("$_menu", ["fetchListMenus", "fetchPopUp", "createMenu", "updateMenu", "fetchCurrentMenu", "fetchParentMenu"]),
    ...mapActions("$_permission", ["fetchListPermission"]),
  },
  async beforeRouteLeave(to, from, next) {
    this.UPDATE_MENUS([])
    this.UPDATE_USER_ACCESS_PERMISSION([])
    next()
  },
};
</script>

<style lang="scss" scoped>
</style>
