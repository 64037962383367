import liveBettingYukiLive from '@/modules/liveBettingYukiLive';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/liveBettingYukiLive',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: liveBettingYukiLive,
				name: 'liveBettingYukiLive'
			}
		]
	}
];

export default routes;
