const headers = [
    // { text: 'សកម្មភាព', align: 'start', value: 'actions', sortable: false },
    {
        text: 'ល.រ',
        align: 'center',
        value: 'no',
    },
    { text: 'Date',align:'center', value: 'date' },
    { text: 'Group No',align:'center', value: 'group_no' },
    { text: 'Fight No',align:'center', value: 'fight_no' },
    { text: 'User ID',align:'center', value: 'user_id' },
    { text: 'User Name',align:'center', value: 'user_name' },
    { text: 'គណនី',align:'center', value: 'balance' },
    { text: 'សកម្មភាព',align:'center', value: 'actions' },
];

export default headers;