import appModule from '@/modules/appModule';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/app-module',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: appModule,
				name: 'appModule'
			}
		]
	}
];

export default routes;
