import statementMasterKh from '@/modules/statementMasterKh';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/statementMasterKh',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: statementMasterKh,
				name: 'statementMasterKh'
			}
		]
	}
];

export default routes;
