<template>
    <div>
        <v-data-table style="font-family: 'khmer mef1'"
                      class="elevation-1"
                      item-key="_id"
                      :headers="headers"
                      :items="getDuplicateTransaction && getDuplicateTransaction.object ? getDuplicateTransaction.object : []"
                      :footer-props="footerProps"
                      :server-items-length="getDuplicateTransaction.objectCount ? getDuplicateTransaction.objectCount : null"
                      :page.sync="data.page"
                      :items-per-page.sync="data.rowsPerPage"
                      :sort-desc.sync="data.descendingFormat"
                      :sort-by.sync="data.sortByFormat"
                      @update:page="getData"
                      @update:items-per-page="getData"
                      @update:sort-desc="getData"
                      :loading="getLoading"
                      show-select
                      mobile-breakpoint="0">
        <template v-slot:top>
            <Header :darkmode="darkmode"
                    :params="data"
                    :user-permission="userAccessPermission" 
                    @child-checkbox="checkboxValue"
                    v-on:child-checkbox="updateParent"
                    />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn color="primary" @click="_openDialogWithdrow(item)">
            Withdraw
          </v-btn>
        </template>
        <template v-slot:[`item.tranc_type`]="{ item }">
            <span v-if="item.tranc_type == 1">New</span>
            <span v-else>Modify</span>
        </template>
        <template v-slot:[`item.amount`]="{ item }">
            <span v-if="item.amount < '0'" style="color:#BA2D4A">{{item.amount}}</span>
            <span v-else style="color:#39afd6">{{item.amount}}</span>
        </template>
        <!-- <template slot="body.append">
        <tr>
          <td :colspan="7" class="text-xs-center pl-4 py-2 ">
            {{ $t("title.total") }}
          </td>
          <td class="text-xs-center total-style pl-4  py-2">
            {{
             getDuplicateTransaction.objectCount
                ?  getDuplicateTransaction.objectCount.balance
                : 0
            }} 
          </td>
          
        </tr>
      </template> -->
    </v-data-table>
    <v-dialog v-model="withdrawDialog" persistent max-width="500px">
      <v-card >
        <v-card-title class="text-h5 py-5" style="margin-left: 95px;">
         do you want to Withdraw ?
        </v-card-title>
        <v-card-actions>
          <v-btn color="blue-grey"    class="ma-2 white--text"  @click="_closeDialogDuplicate">Cancel</v-btn>
          <v-spacer></v-spacer>
          <!-- <v-btn     class="ma-2 white--text" :color="targetChannel && targetChannel.enable ?  'error':'success'" @click="_takeAction">{{targetChannel && targetChannel.enable ? "Inactive Channel" :"Active Channel"}}</v-btn> -->
          <v-btn  class="ma-2 white--text" color="success"
          @click="_updateWithdrowTransaction()"> Yes!</v-btn>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapMutations } from "vuex";
    import moment from "moment";
    export default {
        components: {
            Header: () => import("./_components/header.vue"),
        },
        data: () => ({
            valid: true,
            footerProps: {
                'items-per-page-options': [10, 20, 50, 100, 200]
            },
            data: {
                descendingFormat: [true],
                sortByFormat: ['_id'],
                descending: true,
                sortBy: '_id',
                page: 1,
                rowsPerPage: 10,
                fields: ['module_name', 'user_name','module_bet_id'],
                search: '',
                startDate:  moment().format("YYYY-MM-DD"),
                endDate:  moment().format("YYYY-MM-DD"),
            },
            loading: false,
            withdrawDialog: false,
            selectedDuplicate: [],
            waiting:false,
        }),
        computed: {
            headers() {
                return [
                    { text: this.$t('header.userName'), align: 'start', value: 'user_name' },
                    { text: this.$t('header.old_balance'), value: 'user_balance', sortable: false },
                    { text: this.$t('header.tranc_type'), value: 'tranc_type', sortable: false },
                    { text: this.$t('header.amount'), value: 'amount', sortable: false },
                    { text: this.$t('header.module_bet_id'), value: 'module_bet_id', sortable: false },
                    { text: this.$t("header.actions"), value: "actions", sortable: false },
                ];
            },
            ...mapGetters("$_modules", { darkmode: "getDarkMode" }),
            ...mapGetters("$_menu", ["getTreeMenu"]),
            ...mapGetters("$_duplicateTransaction", ["getDuplicateTransaction","getLoading"]),
        },
        async created() {
            await this.fetchUserAccessPermission(this.$route.path)
            await this.getData()
        },
        methods: {
            async getData() {
                this.checkUserPermission('VIEW') ? await this.fetchListDuplicateTransaction(this.data) : [];
            },
            updateParent(payload){
                this.data.startDate = payload.startDate;
                this.data.endDate = payload.endDate;
            },
            checkboxValue: function(params) {
                this.data.startDate = params.startDate;
                this.data.endDate = params.endDate;
            },
            _openDialogWithdrow(item){
            this.selectedDuplicate = item
            this.withdrawDialog = true
            },
            _closeDialogDuplicate(){
            this.selectedDuplicate = []
            this.withdrawDialog = false
            },
            async _updateWithdrowTransaction(){
                this.waiting = true;
                let data = {
                    user_id : this.selectedDuplicate.user_id,
                    module_bet_id : this.selectedDuplicate.module_bet_id,
                    amount : this.selectedDuplicate.amount,
                }
                await this.$request
                    .post({
                    url: "/tranc-player/withdrawDuplicate",
                    data: data,
                    })
                    .then((res) => {
                    if (res.data.code) {
                        this.getData()
                        this._closeDialogDuplicate()
                        this.waiting = false;
                    }else{
                        this.getData()
                        this._closeDialogDuplicate()
                        this.waiting = false;
                        
                    }
                });
            },
            ...mapMutations("$_duplicateTransaction", ["DELETE_ITEMS", "UPDATE_MODULES"]),
            ...mapActions("$_duplicateTransaction", ["fetchListDuplicateTransaction"]),
        },
        async beforeRouteLeave(to, from, next) {
            this.UPDATE_MODULES([])
            next()
        },
    };
</script>
<style lang="scss" scoped>
.win-color {
  color: rgb(57, 175, 214);
  font-weight: bold;
}
.lose-color {
  color: #ba2d4a;
  font-weight: bold;
}
</style>
