<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="getRoleLevelData ? getRoleLevelData.object : []"
        :footer-props="footerProps"
        
        item-key="_id"
        class="elevation-1"
        :server-items-length="getRoleLevelData ? getRoleLevelData.objectCount : null"
        style="font-family: 'khmer mef1'"
        :page.sync="data.page"
        :items-per-page.sync="data.rowsPerPage"
        :sort-desc.sync="data.descendingFormat"
        :sort-by.sync="data.sortByFormat"
        @update:page="getData"
        @update:items-per-page="getData"
        @update:sort-desc="getData"
        show-select
        :loading="getLoading"
        :value="getDeleteItems"
        @input="itemDelete"
        mobile-breakpoint="0"
         :hide-default-footer="true"
    >
      <template v-slot:top>
        <Header :darkmode="darkmode"
                :params="data"
                :dataDelete="getDeleteItems"
                :user-permission="userAccessPermission"
        />
      </template>
      <template v-slot:[`item.child_role_balance`]="{ item }">
        <span  v-if="item.role_id ==='611532b19c6e77ad0fd50c1c'">
        {{ currencyFormatUsd(item.company_balance_d) +'$, ' + currencyFormatKh(item.company_balance_r) +'៛'}}
        </span>
        <span  v-if="item.child_role_name.substr(-1) ==='$' && item.role_id !=='611532b19c6e77ad0fd50c1c'">
          {{currencyFormatUsd(item.child_role_balance) + "$"}}
        </span>
        <span  v-if="item.child_role_name.substr(-4) ==='Riel' && item.role_id !=='611532b19c6e77ad0fd50c1c'">
          {{currencyFormatKh(item.child_role_balance) + "៛"}}
        </span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <ActionComponents
            :darkmode="darkmode"
            :item="item"
            :dataDelete="getDeleteItems"
            :params="data"
            :user-permission="userAccessPermission"
        />
      </template>
    </v-data-table>
      <Dialog
        :darkmode="darkmode"
        :loading="loading"
        :users="getUsers"
        :roleLevelData="getCurrentRoleLevelData"
        :dialog="getPopUp"
        :is-validate="valid"
        :user="userp"
        v-on:submit="handleSubmit"
        v-on:onCloseDialog="closeDialogCreate"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  components: {
    ActionComponents: () => import("./_components/Action"),
    Header: () => import("./_components/HeaderTable.vue"),
    Dialog: () => import("./_components/Dialog.vue"),
  },
  data: () => ({
    valid: true,
    search: null,
    footerProps: {
      'items-per-page-options': [10, 20, 50, 100, 200]
    },
    data: {
      type:1,
      descendingFormat: [true],
      sortByFormat: ['_id'],
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: ["role_name", "child_role_name", "child_role_balance"],
      search: ''
    },
    dataUser: {
        descending: true,
        sortBy: "_id",
        page: 1,
        rowsPerPage: 5000,
        fields: ['user_name', 'balance', 'account_info', 'enable', 'updatedAt'],
        search: ''
    },
    dialog: false,
    loading: false,
  }),
  computed: {
    headers() {
      return [
        // {text: this.$t('header.user_name'), align: 'start', sortable: false, value: 'user_id'},
        { text: this.$t('input.child_role_balance'), value: 'child_role_balance' },
        { text: this.$t('input.child_role_name'), value: 'child_role_name' },
        { text: this.$t('input.role_name'), value: 'role_name' },
        { text: this.$t('header.actions'), value: 'actions', sortable: false }
      ];
    },
    ...mapGetters("$_modules", {darkmode: "getDarkMode",userp: "getUser" }),
    ...mapGetters("$_roleLevel", ["getRoleLevelData", "getLoading" , "getDeleteItems","getPopUp", "getUsers", "getCurrentRoleLevelData" ]),
    ...mapGetters("$_role", ["getAllRolesDependOnUser"])
  },
  async created() {
    await this.fetchUserAccessPermission(this.$route.path)
    await this.getData()
    await this.getDataUser()
  },
  methods: {
    async handleSubmit() {
      this.loading = true;
      this.getCurrentRoleLevelData.type = 1
      if (!this.getCurrentRoleLevelData._id) {  
        await this.createRoleLevelData({vm: this, data: this.getCurrentRoleLevelData})
        this.getCurrentRoleLevelData = []
        this.fetchPopUp(false)
      } else {
        let uploadData = {
          type : this.getCurrentRoleLevelData.type,
          role_name :  this.getCurrentRoleLevelData.role_name,
          child_role_name :  this.getCurrentRoleLevelData.child_role_name,
          child_role_balance :  this.getCurrentRoleLevelData.child_role_balance,
          company_balance_b :  this.getCurrentRoleLevelData.company_balance_b,
          company_balance_r :  this.getCurrentRoleLevelData.company_balance_r,
          company_balance_d :  this.getCurrentRoleLevelData.company_balance_d,
        }
        await this.updateRoleLevel({ vm: this, data: uploadData, _id : this.getCurrentRoleLevelData._id })
        this.fetchPopUp(false)
      }
      await this.getData()
      this.loading = false
    },

    currencyFormatKh(num) {
      num = parseFloat(num);
      // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return parseInt(num).toLocaleString("en");
    },
    currencyFormatUsd(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      // return parseInt(num).toLocaleString("en");
   },

    async getData() {
      this.checkUserPermission('VIEW') ? await this.fetchGetAllRoleLevel(this.data) : []
    },
    async getDataUser() {
      this.checkUserPermission('VIEW') ? await this.fetchListUsers(this.dataUser) : []
    },

    itemDelete(e) {
      const items = e.map(function (e) {
        return {_id: e._id}
      })
      this.DELETE_ITEMS(items)
    },

    closeDialogCreate() {
      this.getCurrentRoleLevelData={}
      this.fetchPopUp(false)
    },

    ...mapMutations("$_roleLevel", ["UPDATE_ROLE_LEVEL_DATA", "DELETE_ITEMS", "UPDATE_USERS"]),
    ...mapActions("$_roleLevel", [
      "fetchPopUp",
      "createRoleLevelData",
      "fetchGetAllRoleLevel",
      "fetchListUsers",
      "updateRoleLevel"
    ]),
  },
  async beforeRouteLeave(to, from, next) {
    this.UPDATE_USERS([])
    this.UPDATE_USER_ACCESS_PERMISSION([])
    next()
  },
};
</script>

<style lang="scss" scoped>
.users {
  width: 50%;
  float: left;

  h3 {
    font-size: 17px;
  }
}

table {
  font-family: "khmer mef1" !important;
}
</style>
