import statementPlayerKhAll from '@/modules/statementAll/statementPlayerKh';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/statementPlayerKhAll',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: statementPlayerKhAll,
				name: 'statementPlayerKhAll'
			}
		]
	}
];

export default routes;
