export default {
	update: 'កែប្រែ',
	delete: 'លុប',
	delete_all: 'លុបទាំងអស់',
	view: 'មើល',
	cancel: 'បោះបង់',
	yes: 'យល់ព្រម',
	save: 'រក្សារទុក',
	detail: 'ពត៌មានលំអិត',
	create: 'បង្កើត',
	upload_file: 'ផ្ទុកឯកសារឡើង',
	clear: 'Clear',
	filter: 'ត្រង',
	local_leng: 'English',
	create_invoice: 'បង្កើតវិក្កយបត្រ',
	print: 'បោះពុម្ព',
	create_patient: 'បង្កើតអ្នកជម្ងឺ',
	create_doctor: 'បង្កើតវេជ្ជបណ្ឌិត',
	create_service: 'បង្កើតសេវាកម្ម',
	change_password: 'ផ្លាស់ប្តូរពាក្យសម្ងាត់',
	logout: 'ចាកចេញ',
	refresh: 'Refresh',
	sync: 'Sync',
	sync_all: 'Sync All',
	detail_data: 'លម្អិត'
};
