import statementAgentKh from '@/modules/statementAgentKh';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/statementAgentKh',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: statementAgentKh,
				name: 'statementAgentKh'
			}
		]
	}
];

export default routes;
