const getChannel_1 = (state) => state.channel_1
const getChannel_2 = (state) => state.channel_2
const getChannel_3 = (state) => state.channel_3
const getChannel_4 = (state) => state.channel_4
const getChannel_5 = (state) => state.channel_5
const getChannel_6 = (state) => state.channel_6


const getLoading = (state) => state.loading

export default {
    getChannel_1,
    getChannel_2,
    getChannel_3,
    getChannel_4,
    getChannel_5,
    getChannel_6,
    getLoading
};