const headers = [
    // { text: 'សកម្មភាព', align: 'start', value: 'actions', sortable: false },
    {
        text: 'ល.រ',
        align: 'start',
        value: 'no',
    },
    { text: 'អ្នកប្រើប្រាស់', value: 'user_name' },
    { text: 'Public IP', value: 'public_ip' },
    { text: 'ក្រុមទិ', value: 'group_no' },
    { text: 'គូរប្រកួត', value: 'fight_no' },
    { text: 'Shoe No', value: 'shoe_no' },
    { text: 'ប្រភេទចាក់', value: 'bet_type' },
    { text: 'Payout', value: 'payout' },
    { text: 'សរុបប្រាក់ភ្នាល់', value: 'amount' },
    { text: 'សរុបឈ្នះ/ចាញ់', value: 'amount_win' },
];

export default headers;