<template>
  <div>
      <v-data-table :headers="headers" :items="getUsers ? getUsers.users : []" :footer-props="footerProps" item-key="_id"
                    class="elevation-1" :server-items-length="getUsers ? getUsers.totalRow : null" style="font-family: 'khmer mef1'"
                    :page.sync="data.page" :items-per-page.sync="data.rowsPerPage" :sort-desc.sync="data.descendingFormat"
                    :sort-by.sync="data.sortByFormat" @update:page="getData" @update:items-per-page="getData"
                    @update:sort-desc="getData" :loading="getLoading" mobile-breakpoint="0">
          <template v-slot:top>
              <Header :darkmode="darkmode" :params="data" :user-permission="userAccessPermission" />
          </template>

          <template v-slot:[`item`]="{ item }">
              <tr>
                  <td>{{ (getUsers.users.indexOf(item) + ((data.page - 1) * data.rowsPerPage)) + 1 }}</td>
                  <td>{{ item.user_name }}</td>
                  <td v-if="item.role_id !== '612a3fcc1a1fcead8871e822' && (item.currency_type === 1 || item.currency_type === 3)">
                      {{ currencyFormatUsd(item.balance) }}
                  </td>

                  <td v-if="item.role_id !== '612a3fcc1a1fcead8871e822' && item.currency_type === 2">
                      {{ currencyFormatKh(item.balance) }}
                  </td>
                  <td>
                      <a @click="_openDialogListIpUser(item)">
                          {{ item.public_ip }}
                      </a>
                  </td>
                  <td class="text-center">
                      <v-btn :color="item.enable ? 'success' : 'error'" @click="_openDialog(item)">
                          {{
item.enable ?
              "Enabled" : "Not Enable"
                          }}
                      </v-btn>
                  </td>
                  <td class="text-center">
                      <v-btn :color="!item.is_suspend ? 'success' : 'error'" @click="_openDialogs(item)">
                          {{
!item.is_suspend ?
              "Active" : "suspend"
                          }}
                      </v-btn>
                  </td>
                  <!-- <td class="text-center">
                      <v-btn color="primary" @click="_openConfigUser(item)">
                          <v-icon style="color:white; font-size:30px">
                              mdi-account-cog-outline
                          </v-icon>
                      </v-btn>

                  </td> -->
                  <td class="text-center">
                      <v-btn color="green" @click="_openTransaction(item)">
                          <v-icon style="color:white; font-size:30px">
                              mdi-account-cash-outline
                          </v-icon>
                      </v-btn>
                  </td>
                  <td class="text-center">
                      <v-btn color="warning" @click="_goToModule(item._id,item.currency_type)">
                          <v-icon style="color:white; font-size:30px">
                              mdi-book-account
                          </v-icon>
                      </v-btn>
                  </td>
                  <!-- <td class="text-center">
                      <v-btn color="warning" @click="_openDetailBetting(item)">
                          <v-icon style="color:white; font-size:30px">
                              mdi-book-account
                          </v-icon>
                      </v-btn>
                  </td> -->
              </tr>
          </template>
      </v-data-table>

      <!-- Detail Module -->
    <v-dialog
        :value="dialogModule"
        max-width="1200"
        persistent
        transition="dialog-bottom-transition"
    >
      <v-card >
        <v-toolbar>
          <v-btn icon  @click="dialogModule=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <template>
            <v-row> 
              <v-col cols="12" sm="12" md="4" class="text-right pl-5">
                <v-select
                  v-model="module_id"
                  :items="getModule"
                  :label="$t('input.select_module')"
                  item-text="name"
                  item-value="_id"
                  @input="selectModule(module_id)"
                ></v-select>
              </v-col>  
              <v-spacer></v-spacer>
              <v-col cols="6" sm="6" md="2" class="text-right pl-5">
                <v-menu
                  v-model="v_startDate"
                  :close-on-content-click="false"
                  :nudge-right="100"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  class="pl-5"
                  >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :label="$t('input.start_date')"
                      v-model="startDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on">
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    @input="v_startDate = false"
                  >
                </v-date-picker>
                </v-menu>
              </v-col>
              <v-spacer></v-spacer>
              <v-col
                cols="6"
                sm="6"
                md="2"
                class="text-right pl-5 "
              >
                <v-menu
                  v-model="v_endDate"
                  :close-on-content-click="false"
                  :nudge-right="100"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :label="$t('input.end_date')"
                  v-model="endDate"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on">
                </v-text-field>
              </template>
                <v-date-picker
                  v-model="endDate"
                  @input="v_endDate = false"
                ></v-date-picker>
                </v-menu>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="4" sm="4" md="2" class="text-right pr-5 mb-5">
                <v-btn 
                  class="white--text btn-custom mr-5"
                  color="green darken-1"
                  depressed
                  @click="_searchModule()"
                >
                  SEARCH
                </v-btn>
              </v-col>
            </v-row>
            
          </template>
        
          <v-data-table
              style="font-family: 'khmer mef1'"
              class="elevation-1"
              item-key="date"
              :headers="headersDetailModule"
              :footer-props="footerProps"
              :items="detailModule ? detailModule : []"
              :server-items-length="detailModuleCount ? parseInt(detailModuleCount.count): null"
              :sort-desc.sync="dataModule.descendingFormat"
              :sort-by.sync="dataModule.sortByFormat"
              :page.sync="dataModule.page"
              :items-per-page.sync="dataModule.rowsPerPage"
              @update:page="updatePageModule"
              @update:items-per-page="updatePageModule"
              @update:sort-desc="updatePageModule"
              :loading="getLoadingDetailModule"
              mobile-breakpoint="0"
          >
            <template v-slot:[`item`]="{ item }">
              <tr>
                <td>{{ detailModule.indexOf(item) + 1 }}</td>
                <td>{{ item.module_name }}</td>
                <!-- <td>{{ item.com }}</td>
                <td>{{ item.admin }}</td>
                <td>{{ item.master }}</td> -->
                
                <!-- <td>{{ item.agent }}</td> -->
                <td>{{ item.player }}</td>
                <!-- <td>{{ currencyFormat(item.player_balance) }} ៛ </td> -->
                <td class="to-detail" style="color: goldenrod;" @click="_goToDetail(item.player_id,item.module_id,item.player)">{{ currencyFormat(item.amount) }}  </td>
                <!-- <td :class="item.amount_win < 0 ? 'lose-color' : 'win-color'">{{ currencyFormat(item.amount_win)}} ៛ </td> -->
                <td :style="item.amount_win < 0 ? 'color:#BA2D4A': 'color:#39afd6'">{{ currencyFormat(item.amount_win)}} </td>
                
              </tr>
            </template>
            <template slot="body.append">
              <tr>
                <td :colspan="3" class="text-xs-center pl-4 py-2 ">
                  {{ $t("title.total") }}
                </td>
                <td class="text-xs-center total-style pl-4  py-2">
                  {{
                    detailModuleCount.amount
                      ? currencyFormat(detailModuleCount.amount)
                      : 0
                  }} 
                </td>
                <td
                  class="text-xs-center pl-4 total-style font-weight-bold  py-2"
                  :style="
                    detailModuleCount.amount_win < 0
                      ? 'color:#BA2D4A'
                      : 'color:#39afd6'
                  "
                >
                  {{
                    detailModuleCount.amount_win
                      ? currencyFormat(
                          detailModuleCount.amount_win
                        )
                      : 0
                  }}
                
                </td>
                <td class="text-xs-center total-style pl-4  py-2">
                  
                </td>
              </tr>
      </template>
          </v-data-table>

          
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Detail data for Lotto80, Apong and Cock fight #2steps -->

    <v-dialog
        :value="dialogN"
        max-width="1200"
        persistent
        transition="dialog-bottom-transition"
    >
      <v-card >
        <v-toolbar>
          <v-btn icon  @click="dialogN=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="pl-5">
            <v-col cols="6" sm="6" md="3" class="text-left mt-4">
              <v-toolbar-title>{{title}}</v-toolbar-title>
            </v-col>
            <v-col cols="6" sm="6" md="3" class="text-left mt-4">
              <v-select v-if="module_id == '62dfdf855ef1fb28515a1a71'"
                  v-model="channelType"
                  :items="items"
                  item-value="id" item-text="name"
                  label="Channel #  "
                  class="channel-type   "
                  style="min-width:100%"
                  @input="selectChannelType(channelType)"
                  solo
              ><template v-slot:prepend>
                <v-icon color="green darken-2">mdi-television</v-icon>
              </template>
              </v-select>
            </v-col>
              <v-col cols="8" sm="8" md="4" class="text-right">
                <v-text-field
                    v-model="v_search"
                    append-icon="mdi-magnify"
                    label="Search ( Fight no, គូ ប្រកួត )"
                    single-line
                    hide-details
                    clearable
                    class="mr-5"
                />
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="4" sm="4" md="2" class="text-right mb-5">
                <v-btn
                    class="white--text btn-custom mr-4 mt-3"
                    color="green darken-1"
                    depressed
                    @click="searchq"
                >
                  <!-- @click="fetchListStatement(params)" -->
                  SEARCH
                </v-btn>
              </v-col>
          </v-row>
          <v-data-table
              style="font-family: 'khmer mef1'"
              class="elevation-1"
              item-key="date"
              :headers="headersDetailN"
              :footer-props="footerProps"
              :items="detailData ? detailData : []"
              :server-items-length="detailDataCount ? parseInt(detailDataCount.count): null"
              :sort-desc.sync="dataRequest.descendingFormat"
              :sort-by.sync="dataRequest.sortByFormat"
              :page.sync="dataRequest.page"
              :items-per-page.sync="dataRequest.rowsPerPage"
              @update:page="updatePageCock"
              @update:items-per-page="updatePageCock"
              @update:sort-desc="updatePageCock"
              :loading="getLoadingDetailCockFight"
              mobile-breakpoint="0"
          >
            <template v-slot:[`item`]="{ item }">
              <tr>
                <td>{{ detailData ? (detailData.indexOf(item) + ( (dataRequest.page -1 ) *  dataRequest.rowsPerPage)) + 1 :"loading ..." }}</td>
                <td>{{ item.public_ip }}</td>
                <td>{{ item.date }}</td>
                <td>{{ item.channel_type }}</td>
                <td v-if="module_id == '62e48485abc48561de3a470f'">{{ item.no }}</td>
                <td v-else>{{ item.fight_no }}</td>
                <td v-if="module_id == '62dfdf855ef1fb28515a1a71'">{{ item.co_type_of_betting.name }}</td>
                <td v-if="module_id == '62f92808016e8046cccee5bc'">{{ item.sn_number.name }}</td>
                <td v-if="module_id == '62e48485abc48561de3a470f'">{{ item.type_of_betting.name }}</td>
                <td v-if="module_id == '63a0995c9cbbad6ab61dfc78'" :class="item.co_type_of_betting.color">{{ switchResult(item.co_type_of_betting.name) }}</td>
                <td v-if="module_id == '63675312283c9121b8a30a92'" :class="item.co_type_of_betting.color">{{ item.co_type_of_betting.name}}</td>
                <td v-if="module_id == '6371f53073798150886bc23b'" :class="item.co_type_of_betting.color">{{ item.co_type_of_betting.name}}</td>
                <td>
                  <span v-if="module_id == '62dfdf855ef1fb28515a1a71'"> {{item.co_result.name}}</span>
                  <span v-if="module_id == '62f92808016e8046cccee5bc'"> {{item.sn_result}}</span>
                  <span v-if="module_id == '62e48485abc48561de3a470f'"> {{item.result.name}}/{{item.result_range.name}}</span>
                  <span v-if="module_id == '63a0995c9cbbad6ab61dfc78'" :class="item.co_result.color">
                    {{switchResult(item.co_result.name)}}
                    <span v-if="item.pair_banker" :class="item.pair_banker.color"> / {{switchResult(item.pair_banker.name)}}</span>
                    <span v-if="item.pair_player" :class="item.pair_player.color"> / {{switchResult(item.pair_player.name)}} </span>
                  </span>
                  <span v-if="module_id == '63675312283c9121b8a30a92'" :style="'color:'+item.co_result.color"> {{item.co_result.name}}</span>
                  <span v-if="module_id == '6371f53073798150886bc23b'"> {{item.co_result.name}}</span>
                </td>
                <td style="color: goldenrod;">{{item.amount? currencyFormat(item.amount) :0 }}  </td>
                <td>{{item.payout? item.payout :0 }} </td>
                <td>{{item.is_bonus == true ? 'number:' + item.payout_number + '/payout: ' + item.payout_bonus :0 }} </td>
                <td :style="item.amount_win < 0 ? 'color:#BA2D4A': 'color:#39afd6'">{{item.amount_win? currencyFormat(item.amount_win) :0}}  </td>
                 <td>{{ (item.cast || item.cast == 0) ? currencyFormat(item.cast) : currencyFormat(item.amount) }} </td>
              </tr>
            </template>
            <template slot="body.append">
              <tr>
                <td :colspan="7" class="text-xs-center pl-4 py-2 ">
                  {{ $t("title.total") }}
                </td>
                <td :colspan="3" class="text-xs-center total-style pl-4  py-2">
                  {{
                    detailDataCount.amount
                      ? currencyFormat(detailDataCount.amount)
                      : 0
                  }} 
                </td>
                <td
                  class="text-xs-center pl-4 total-style font-weight-bold  py-2"
                  :style="
                    detailDataCount.amount_win < 0
                      ? 'color:#BA2D4A'
                      : 'color:#39afd6'
                  "
                  v-if="module_id == '62dfdf855ef1fb28515a1a71'"
                >
                  {{
                    detailDataCount.amount_win
                      ? currencyFormat(
                        detailDataCount.amount_win
                        )
                      : 0
                  }}
                  
                </td>
                <td
                  class="text-xs-center pl-4 total-style font-weight-bold  py-2"
                  :style="
                    detailDataCount.amount_win + detailDataCount.amount_lose < 0
                      ? 'color:#BA2D4A'
                      : 'color:#39afd6'
                  "
                  v-if="module_id == '62f92808016e8046cccee5bc'"
                >
                  {{
                    detailDataCount.amount_win
                      ? currencyFormat(
                        detailDataCount.amount_win + detailDataCount.amount_lose
                        )
                      : 0
                  }}
                  
                </td>
                <td
                  class="text-xs-center pl-4 total-style font-weight-bold  py-2"
                  :style="
                    detailDataCount.amount_win + detailDataCount.amount_lose < 0
                      ? 'color:#BA2D4A'
                      : 'color:#39afd6'
                  "
                  v-if="module_id == '62e48485abc48561de3a470f'"
                >
                  {{
                    detailDataCount.amount_win
                      ? currencyFormat(
                        detailDataCount.amount_win + detailDataCount.amount_lose
                        )
                      : 0
                  }}
                  
                </td>
                <td
                  class="text-xs-center pl-4 total-style font-weight-bold  py-2"
                  :style="
                    detailDataCount.amount_win + detailDataCount.amount_lose < 0
                      ? 'color:#BA2D4A'
                      : 'color:#39afd6'
                  "
                  v-if="module_id == '63675312283c9121b8a30a92'"
                >
                {{
                    detailDataCount.amount_win
                      ? currencyFormat(
                        detailDataCount.amount_win
                        )
                      : 0
                  }}
                  
                </td>
                <td
                  class="text-xs-center pl-4 total-style font-weight-bold  py-2"
                  :style="
                    detailDataCount.amount_win + detailDataCount.amount_lose < 0
                      ? 'color:#BA2D4A'
                      : 'color:#39afd6'
                  "
                  v-if="module_id == '63a0995c9cbbad6ab61dfc78'"
                >
                {{
                    detailDataCount.amount_win
                      ? currencyFormat(
                        detailDataCount.amount_win
                        )
                      : 0
                  }}
                  
                </td>
                <td
                  class="text-xs-center pl-4 total-style font-weight-bold  py-2"
                  :style="
                    detailDataCount.amount_win + detailDataCount.amount_lose < 0
                      ? 'color:#BA2D4A'
                      : 'color:#39afd6'
                  "
                  v-if="module_id == '6371f53073798150886bc23b'"
                >
                {{
                    detailDataCount.amount_win
                      ? currencyFormat(
                        detailDataCount.amount_win
                        )
                      : 0
                  }}
                  
                </td>
                <td class="text-xs-center total-style pl-4  py-2">
                  
                </td>
              </tr>
      </template>
          </v-data-table>

          
        </v-card-text>
      </v-card>
    </v-dialog>

<!-- Detail data for Lotto plus and Yuki #3steps -->
    <v-dialog
        :value="dialogY"
        max-width="1200"
        persistent
        transition="dialog-bottom-transition"
    >
      <v-card >
        <v-toolbar>
          <v-btn icon  @click="dialogY=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="pl-5">
            <v-col cols="6" sm="6" md="3" class="text-left mt-4">
              <v-toolbar-title>{{title}}</v-toolbar-title>
            </v-col>
            <v-col cols="6" sm="6" md="3" class="text-left mt-4">
                
            </v-col>
              <v-col cols="8" sm="8" md="4" class="text-right">
                <v-text-field
                    v-model="v_search"
                    append-icon="mdi-magnify"
                    label="Search ( Fight no, គូ ប្រកួត )"
                    single-line
                    hide-details
                    clearable
                    class="mr-5"
                />
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="4" sm="4" md="2" class="text-right mb-5">
                <v-btn
                    class="white--text btn-custom mr-4 mt-3"
                    color="green darken-1"
                    depressed
                    @click="searchq"
                >
                  <!-- @click="fetchListStatement(params)" -->
                  SEARCH
                </v-btn>
              </v-col>
          </v-row>
          <v-data-table
              style="font-family: 'khmer mef1'"
              class="elevation-1"
              item-key="date"
              :headers="headersDetailY"
              :footer-props="footerProps"
              :items="detailData ? detailData : []"
              :server-items-length="detailDataCount ? parseInt(detailDataCount.count): null"
              :sort-desc.sync="dataRequest.descendingFormat"
              :sort-by.sync="dataRequest.sortByFormat"
              :page.sync="dataRequest.page"
              :items-per-page.sync="dataRequest.rowsPerPage"
              @update:page="updatePage"
              @update:items-per-page="updatePage"
              @update:sort-desc="updatePage"
              :loading="getLoadingDetailY"
              mobile-breakpoint="0"
          >
            <template v-slot:[`item`]="{ item }">
              <tr>
                <td>{{ detailData ? (detailData.indexOf(item) + ( (dataRequest.page -1 ) *  dataRequest.rowsPerPage)) + 1 :"loading ..." }}</td>
                <td>{{ item.time }}</td>
                <td>{{ item.invoice_id }}</td>
                <td>{{ item.channel_type }}</td>
                <td>{{ item.fight_no }}</td>
                <td class="to-detail" style="color: goldenrod;" @click="_goToInvoiceDetail(item.user_name,item.app_id,item.invoice_id,item.module_id)">{{ currencyFormat(item.amount) }}  </td>
                <td :style="item.amount_win < 0 ? 'color:#BA2D4A': 'color:#39afd6'">{{ currencyFormat(item.amount_win)}}  </td>
                <!-- <td>{{ currencyFormat(item.amount_win)}} ៛ </td> -->
                 <td>{{ currencyFormat(item.cast)}} </td>
              </tr>
            </template>
            
          </v-data-table>

          
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- invoiceDialog -->
    <v-dialog
        :value="invoiceDialog"
        max-width="1200"
        persistent
        transition="dialog-bottom-transition"
    >
      <v-card >
        <v-toolbar>
          <v-btn icon  @click="invoiceDialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="pl-5">
            <v-col cols="6" sm="6" md="3" class="text-left mt-4">
              <v-toolbar-title>Invoice Detail</v-toolbar-title>
            </v-col>
            <v-col cols="6" sm="6" md="3" class="text-left mt-4">
                
            </v-col>
          </v-row>
          <v-data-table
              style="font-family: 'khmer mef1'"
              class="elevation-1"
              item-key="date"
              :headers="invoiceDetail"
              :footer-props="footerProps"
              :items="invoiceDetailData ? invoiceDetailData : []"
              :server-items-length="invoiceDetailData ? parseInt(invoiceDetailData.length): null"
              :sort-desc.sync="dataRequest.descendingFormat"
              :sort-by.sync="dataRequest.sortByFormat"
              :page.sync="dataRequest.page"
              :items-per-page.sync="dataRequest.rowsPerPage"
              :loading="getLoadingInvoiceDetail"
              mobile-breakpoint="0"
          >
            <template v-slot:[`item`]="{ item }">
              <tr>
                <td>{{ invoiceDetailData ? (invoiceDetailData.indexOf(item) + ( (dataRequest.page -1 ) *  dataRequest.rowsPerPage)) + 1 :"loading ..." }}</td>
                <td>{{ item.date }}</td>
                <td>{{ item.invoice_no }}</td>
                <td>{{ item.no }}</td>
                <td>
                  <v-chip label :color=item.type_of_betting.color style="padding:15px;color:#ffffff; font-size:13px;height:22px">
                      <span class="white--text">{{item.type_of_betting.name}} : {{item.type_of_betting.number_label}}</span>
                  </v-chip>
                </td>
                <td>
                  <v-btn
                    class="mx-1"
                    fab
                    dark
                    x-small
                    :color="item.result_a.color"
                  >
                    {{item.result_a.number_label}}
                  </v-btn>
                  <v-btn
                    class="mx-1"
                    fab
                    dark
                    x-small
                    :color="item.result_b.color"
                  >
                    {{item.result_b.number_label}}
                  </v-btn>
                  <v-btn
                    class="mx-1"
                    fab
                    dark
                    x-small
                    :color="item.result_c.color"
                  >
                    {{item.result_c.number_label}}
                  </v-btn>
                   <v-btn
                    class="mx-1"
                    fab
                    dark
                    x-small
                    :color="item.result_d.color"
                  >
                    {{item.result_d.number_label}}
                  </v-btn>
                   <v-btn
                    class="mx-1"
                    fab
                    dark
                    x-small
                    :color="item.result_e.color"
                  >
                    {{item.result_e.number_label}}
                  </v-btn>
                </td>
                <td style="color: goldenrod;">
                   {{currencyFormat(item.amount)}} 
                </td>
                <td  >{{ item.payout }}</td>
                <td :class="item.amount_win < 0 ? 'lose-color font-weight-bold' :'win-color font-weight-bold'">{{item.amount_win ? currencyFormat(item.amount_win) : 0 }} </td>
                <td>{{item.cast ?  currencyFormat(item.cast) : 0}} </td>
              </tr>
            </template>
            
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- invoiceDialogYuki -->
    <v-dialog
        :value="invoiceDialogYuki"
        max-width="1200"
        persistent
        transition="dialog-bottom-transition"
    >
      <v-card >
        <v-toolbar>
          <v-btn icon  @click="invoiceDialogYuki=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="pl-5">
            <v-col cols="6" sm="6" md="3" class="text-left mt-4">
              <v-toolbar-title>Detail Player Statement</v-toolbar-title>
            </v-col>
            <v-col cols="6" sm="6" md="3" class="text-left mt-4">
                
            </v-col>
            
              <v-spacer></v-spacer>
             
          </v-row>
          <v-data-table
            style="font-family: 'khmer mef1'"
            class="elevation-1"
            item-key="date"
            :headers="headersInvoiceDetail"
            :footer-props="footerProps"
            :items="invoiceDetailData ? invoiceDetailData : []"
            :server-items-length="invoiceDetailData ? parseInt(invoiceDetailData.count): null"
            :sort-desc.sync="dataRequest.descendingFormat"
            :sort-by.sync="dataRequest.sortByFormat"
            :page.sync="dataRequest.page"
            :items-per-page.sync="dataRequest.rowsPerPage"
            @update:page="updatePage"
            @update:items-per-page="updatePage"
            @update:sort-desc="updatePage"
            :loading="getLoadingInvoiceDetail"
            mobile-breakpoint="0"
          >
            <template v-slot:[`item`]="{ item }">
              <tr>
                <td>{{ invoiceDetailData ? (invoiceDetailData.indexOf(item) + ( (dataRequest.page -1 ) *  dataRequest.rowsPerPage)) + 1 :"loading ..." }}</td>
                <td>{{ item.date }}</td>
                <td>{{ item.no }}</td>
                <td :class="item.type_of_betting.name">
                  {{ item.type_of_betting.name }}
                </td>
                <td :class="item.result.name">
                  {{ item.result.name }}
                </td>
                <td style="color: goldenrod;">
                   
                   {{currencyFormat(item.amount)}} 
                </td>
                <td  >{{ item.payout }}</td>
                <td :class="item.amount_win < 0 ? 'lose-color font-weight-bold' :'win-color font-weight-bold'">{{item.amount_win ? currencyFormatEn(item.amount_win) : 0 }} </td>
                <td>{{item.cast ?  currencyFormat(item.cast) : 0}}</td>
              </tr>
            </template>
            <!-- <template slot="body.append">
              <td :colspan="5" class="text-xs-center pl-4 py-2 ">
                {{ $t("title.total") }}
              </td>
              <td :class=" invoiceDetailDataCount.amount < 0 ? 'lose-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold' :'win-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold'">
               
                {{ invoiceDetailDataCount ? (invoiceDetailDataCount.amount) : "loading ..."}}  
              </td>
              <td></td>
              <td :class=" invoiceDetailDataCount.amount_win + invoiceDetailDataCount.amount_lose< 0 ? 'lose-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold' :'win-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold'">
                
                {{ invoiceDetailDataCount ? currencyFormatEn(invoiceDetailDataCount.amount_win + invoiceDetailDataCount.amount_lose) : "loading ..."}}  
              </td>
              
            </template> -->
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>


      <!-- Confirm Dialog -->
      <v-dialog v-model="dialog" persistent max-width="500px">
          <v-card>
              <v-card-title class="text-h5 py-5">
                  {{ player.enable ? 'បិទដំណើរការ Player : ' + player.user_name : 'បើកដំណើរការ Player : ' + player.user_name }}
              </v-card-title>
              <v-card-actions>
                  <v-btn color="blue-grey" class="ma-2 white--text" @click="_closeDialog">Cancel</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="ma-2 white--text" :color="player.enable ? 'error' : 'success'" @click="_takeAction">
                      {{ this.player.enable ? "Inactive this Player" : "Active this Player" }}
                  </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
      <v-dialog v-model="dialogs" persistent max-width="500px">
          <v-card>
              <v-card-title class="text-h5 py-5">
                  {{ player.suspend ? 'បិទដំណើរការ Player : ' + player.user_name : 'បើកដំណើរការ Player : ' + player.user_name }}
              </v-card-title>
              <v-card-actions>

                  <v-btn color="blue-grey" class="ma-2 white--text" @click="_closeDialogs">Cancel 1</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="ma-2 white--text" :color="player.is_suspend ? 'error' : 'success'" @click="_takeActions">
                      {{ this.player.is_suspend ? "Suspend this Player" : "Active this Player" }}
                  </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
      <DialogListIpUserComponents :dialog="ipDialog" :listData="listIpUserData" @onCloseDialog="ipDialog = !ipDialog" />
      <DialogConfigUser :darkmode="darkmode" :dialogConfig="dialogConfig" :dataConfig="configUserData" :loading="loading"
                        :passLimitConfig="getLimitData" v-on:submit="onChangeConfigUser" :passPlayer="selectedPlayer"
                        v-on:onCloseDialog="closeConfigUserDialog" />
      <dialog-view-transaction v-if="dialogViewTransaction"
                               :dialog-withdraw="dialogViewTransaction"
                               :darkmode="darkmode"
                               :dialog="dialogViewTransaction"
                               :selectedUser="selectedUser"
                               v-on:onCloseDialog="_closeDialogViewTransaction" />
      <v-dialog :value="dialogDetailBetting"
                max-width="1500"
                persistent
                transition="dialog-bottom-transition">
          <v-card>
              <v-toolbar>
                  <v-btn icon @click="dialogDetailBetting=false">
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
              </v-toolbar>
              <v-card-text>
                  <v-row class="pl-5">
                      <v-col cols="6" sm="6" md="12" class="text-left mt-4">
                          <v-toolbar-title>Detail Player Statement</v-toolbar-title>
                      </v-col>
                      <v-col cols="6" sm="6" md="1" class="text-left mt-4">
                        
                      </v-col>
                      <!-- <v-col cols="12" sm="12" md="1" class="text-left mt-4">
                          <v-row>
                              <v-checkbox v-model="statusTime" value="true" style="margin-top:10px;" class="ml-5"></v-checkbox>
                              <span class="mt-4 mr-2">Time</span>
                          </v-row>
                      </v-col> -->
                      <v-col cols="6" sm="6" md="2" :class="statusTime?'text-right custom-menu' : 'text-right'">
                          <v-menu v-model="v_startDate" :close-on-content-click="false" :nudge-right="100" transition="scale-transition"
                                  offset-y min-width="auto" class="pl-5">
                              <template v-slot:activator="{ on, attrs }">
                                  <v-text-field v-model="startDate" label="ចាប់ផ្តើម" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                                v-on="on">
                                  </v-text-field>
                              </template>
                              <v-date-picker v-model="startDate" @input="v_startDate = false">
                              </v-date-picker>
                          </v-menu>
                      </v-col>
                      <v-col v-if="statusTime" cols="4" sm="4" md="1" class="text-right pl-0 ml-0 mb-5 pt-5">
                          <input v-model="v_startTime" type="time" class="custom-input-time" />
                      </v-col>
                      <v-col cols="6" sm="6" md="2" :class="statusTime?'text-right custom-menu' : 'text-right'">
                          <v-menu v-model="v_endDate" :close-on-content-click="false" :nudge-right="100" transition="scale-transition"
                                  offset-y min-width="auto">
                              <template v-slot:activator="{ on, attrs }">
                                  <v-text-field v-model="endDate" label="បញ្ចប់" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on">
                                  </v-text-field>
                              </template>
                              <v-date-picker v-model="endDate" @input="v_endDate = false"></v-date-picker>
                          </v-menu>
                      </v-col>
                      <v-col v-if="statusTime" cols="4" sm="4" md="1" class="text-right pl-0 ml-0 mb-5 pt-5">
                          <input v-model="v_endTime" type="time" class="custom-input-time" />
                      </v-col>
                      <v-col cols="6" sm="6" md="2" class="text-left mt-4">
                          <v-select v-model="defaultSelected"
                                    :items="selectChannel"
                                    item-text="name"
                                    item-value="id"
                                    style="margin-top:-10px"
                                      single-line></v-select>
                      </v-col>
                      <v-col cols="8" sm="8" md="2" class="text-right">
                          <v-text-field v-model="v_search"
                                        append-icon="mdi-magnify"
                                        label="Search ( Fight no, គូ ប្រកួត )"
                                        single-line
                                        hide-details
                                        clearable
                                        class="mr-5" />
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="4" sm="4" md="1" class="text-right mr-2 mb-5">
                          <v-btn class="white--text btn-custom mt-3"
                                 color="green darken-1"
                                 depressed
                                 @click="searchq">
                              <!-- @click="fetchListStatement(params)" -->
                              SEARCH
                          </v-btn>
                      </v-col>
                  </v-row>
                  <v-data-table style="font-family: 'khmer mef1'"
                                class="elevation-1"
                                item-key="date"
                                :headers="headersDetail"
                                :footer-props="footerProps"
                                :items="detailData ? detailData : []"
                                :server-items-length="detailDataCount ? parseInt(detailDataCount.count): null"
                                :sort-desc.sync="dataRequest.descendingFormat"
                                :sort-by.sync="dataRequest.sortByFormat"
                                :page.sync="dataRequest.page"
                                :items-per-page.sync="dataRequest.rowsPerPage"
                                @update:page="updatePage"
                                @update:items-per-page="updatePage"
                                @update:sort-desc="updatePage"
                                :loading="getLoadingDetail"
                                mobile-breakpoint="0">
                      <template v-slot:[`item`]="{ item }">
                          <tr>
                              <td>{{ detailData ? (detailData.indexOf(item) + ( (dataRequest.page -1 ) *  dataRequest.rowsPerPage)) + 1 :"loading ..." }}</td>
                              <td>{{ item.app_id }}</td>
                              <td>{{ item.public_ip }}</td>
                              <td>{{ item.date }}</td>
                              <td>{{ item.fight_no }}</td>
                              <td>{{ item.channel_type }}</td>
                              <td :class="item.co_type_of_betting.desc">
                                  {{ item.co_type_of_betting.desc }}
                              </td>
                              <td :class="item.co_result.desc">
                                  {{ item.co_result.desc }}
                              </td>
                              <td class="amount-bet-color">
                                  <!-- {{ dataRequest && dataRequest.currencyType == 2 ? currencyFormatKm(item.amount) : dataRequest.currencyType && dataRequest.currencyType != 2 ?  currencyFormatEn(item.amount) :"Loading ..." }} -->
                                  {{currencyFormatEn(item.amount)}}
                              </td>
                              <td>{{ currencyFormatEn(item.payout) }}</td>
                              <td :class="item.amount_win < 0 ? 'lose-color font-weight-bold' :'win-color font-weight-bold'">{{ item.amount_win != null ? currencyFormatEn(item.amount_win) : 0  }}</td>
                              <td>{{ item.cast != null ? currencyFormatEn(item.cast) : 0  }}</td>
                          </tr>
                      </template>
                      <template slot="body.append">
                          <td :colspan="8" class="text-xs-center pl-4 py-2 ">
                              {{ $t("title.total") }}
                          </td>
                          <td :class=" detailDataCount.amount < 0 ? 'lose-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold' :'win-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold'">

                              {{ detailDataCount ? currencyFormatEn(detailDataCount.amount) : "loading ..."}}
                          </td>
                          <td></td>
                          <td :class=" detailDataCount.amount_win  < 0 ? 'lose-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold' :'win-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold'">
                              <!-- 123 -->
                              <!-- {{ dataRequest && dataRequest.currencyType == 2 && detailDataCount ? currencyFormatKm(detailDataCount.amount) : dataRequest.currencyType && dataRequest.currencyType != 2 && detailDataCount ?  currencyFormatEn(detailDataCount.amount) :"Loading ..." }} -->
                              {{ detailDataCount ? currencyFormatEn(detailDataCount.amount_win) : "loading ..."}}
                          </td>

                      </template>
                  </v-data-table>
              </v-card-text>
          </v-card>
      </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
  import headersDetail from "./_api/columns_detail";
  import headersDetailModule from "./_api/columns_detail_module";
  import headersDetailN from "./_api/columns_detail_n";
  import headersDetailY from "./_api/columns_detail_y";
  import headersInvoiceDetail from "./_api/invoice_yuki_detail";
  import invoiceDetail from "./_api/invoice_detail";
  import moment from "moment";
export default {

components: {
  Header: () => import("./_components/headerTable.vue"),
  DialogListIpUserComponents: () => import("./_components/DialogListIpUser"),
  DialogConfigUser: () => import("./_components/ConfigUserData"),
  DialogViewTransaction : () => import("./_components/DialogViewTransaction"),
},
data: () => ({
  valid: true,
  search: null,
  footerProps: {
    "items-per-page-options": [10, 20, 50, 100, 200],
  },
  configUserData: {},
  data: {
    page: 1,
    rowsPerPage: 10,
    search: "",
    descendingFormat: [true],
    sortByFormat: ["_id"],
    descending: true,
    sortBy: null,
    startDate:  moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    module_id: null,
  },
  dataModule: {
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: ["_id.module_id","_id.module_name","_id.player"],
      search: "",
      startDate:  moment().format("YYYY-MM-DD"),
      endDate:  moment().format("YYYY-MM-DD"),
      module_id: null,
      user_id:null,
      channelType:  1,
    },
    items: [
        {name:"ប៉ុស្តិ៍ 1", id:1},
        {name:"ប៉ុស្តិ៍ 2", id:2},
        {name:"ប៉ុស្តិ៍ 3", id:3},
        {name:"ប៉ុស្តិ៍ 4", id:4}
      ],
      channelType:  1,
  dialogModule: false,
  detailModule:[],
  detailModuleCount:[],
  title:'',
  dialog: false,
  dialogs: false,
  dialogN: false,
  dialogY:false,
  invoiceDialog: false,
  invoiceDialogYuki: false,
  dialogConfig: false,
  player: [],
  ipDialog: false,
  listIpUserData: [],
  onCloseDialog: false,
  passLimitConfig: {},
  loading: false,
  selectedPlayer: {},
  selectedUser:{},
    dialogViewTransaction: false,
    dialogDetailBetting: false,
    detailData: [],
    detailDataCount: [],
    invoiceDetailData: [],
    headersDetail: headersDetail,
    headersDetailModule:headersDetailModule,
    headersDetailN:headersDetailN,
    headersDetailY:headersDetailY,
    invoiceDetail: invoiceDetail,
    headersInvoiceDetail:headersInvoiceDetail,
    dataRequest: {
        descendingFormat: [true],
        sortByFormat: ["amount_win"],
        descending: true,
        sortBy: null,
        page: 1,
        rowsPerPage: 10,
        fields: ["co_type_of_betting.name", "fight_no"],
        search: "",
        channelType: 1,
        module_id: null,
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
    },
    dataInvoiceRequest:{
      userName: "",
      appId: "",
      invoice_id: "",
      module_id:null
    },
    getLoadingDetail: false,
    getLoadingDetailModule:false,
    getLoadingDetailY:false,
    getLoadingDetailCockFight:false,
    getLoadingInvoiceDetail:false,
    user_iid: null,
    channel_type: null,
    currency_type: null,
    v_search: "",
    module_id: null,
    defaultSelected: {
        id: 0,
        name: "បុស្តិ៍ទាំងអស់",
    },
    selectChannel: [
        {
            id: 0,
            name: "បុស្តិ៍ទាំងអស់",
        },
        {
            id: 1,
            name: "បុស្តិ៍ទី ១",
        },
        {
            id: 2,
            name: "បុស្តិ៍ទី ២",
        },
        {
            id: 3,
            name: "បុស្តិ៍ទី ៣",
        },
        {
            id: 4,
            name: "បុស្តិ៍ទី 4",
        },
    ],

    v_startTime: "00:00",
    v_endTime: "23:59",
    statusTime: false,
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    v_startDate: null,
    v_endDate: null,
}),
computed: {
  headers() {
    return [
      {
        text: this.$t("header.no"),
        align: "center",
        sortable: false,
      },
      {
        text: this.$t("header.user_name"),
        align: "center",
        sortable: false,
        value: "user_name",
      },
      { text: this.$t("header.balance"), value: "balance" },
      { text: this.$t("header.public_ip"), value: "public_ip" },
      { text: this.$t("header.active"), value: "enable" },
      { text: this.$t("header.suspend"), value: "suspend" },
      // { text: this.$t("header.configUser"), value: "",   align: "center", },
      { text: this.$t("header.reportDW"), value: "",   align: "center", },
      { text: this.$t("header.reportBetting"), value: "",  align: "center", },
    ];
  },
  ...mapGetters("$_modules", { darkmode: "getDarkMode", userp: "getUser" }),
  ...mapGetters("$_enableDisablePlayer", [
    "getUsers", "getLoading", "getLimitData","getModule"
  ]),
},
async created() {
  await this.getData();
  await this.fetchModule();
},
watch: {
  getUsers: function () { },
  configUserData: function () { },
},
methods: {
  _openDialogListIpUser(item) {
    this.getListIpUserData(item._id);
    this.ipDialog = true;
  },
  async getListIpUserData(_id) {
    let data = {
      user_id: _id
    }
    await this.$request
      .post({
        url: "/users/getLoginIpByUser",
        data: data,
      })
      .then((res) => {
        if (res.data.code) {
          this.listIpUserData = res.data.data
          // this.waiting = false;
        }
      });
  },
  currencyFormatKh(num) {
    num = parseFloat(num);
    // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    return parseInt(num).toLocaleString("en");
  },
  currencyFormatUsd(num) {
    num = parseFloat(num);
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    // return parseInt(num).toLocaleString("en");
    },
    currencyFormatEn(num) {
        num = parseFloat(num);
        return num.toLocaleString("en");
    },
  async getData() {
    await this.fetchListUsers(this.data)
  },
  // async getData() {
  //     this.dataModule.startDate = this.startDate;
  //     this.dataModule.endDate = this.endDate;
  //     this.dataModule.search = this.v_search;
  //     this.dataModule.module_id = this.module_id;
  //     await this.fetchListUsers(this.dataModule)
  //   },
  _closeDialog() {
    this.player = [];
    this.dialog = false;
  },
  _closeDialogs() {
    this.player = [];
    this.dialogs = false;
  },
  _openDialog(player) {
    this.player = player;
    this.dialog = true;
  },

  _openDialogs(player) {
    this.player = player;
    this.dialogs = true;
  },
  async _takeAction() {
    if (this.player) {
      let data = {
        player_id: this.player._id,
        enable: !this.player.enable
      }
      await this.updateUser({ vm: this, data: data })
      this.dialog = false;
      this.getData()
    }

  },
  async _takeActions() {
    if (this.player) {
      let data = {
        player_id: this.player._id,
        is_suspend: !this.player.is_suspend
      }
      // console.log("okay")
      await this.updateSuspendUser({ vm: this, data: data })
      this.dialogs = false;
      this.getData()
    }

  },
  //
  async _openConfigUser(player) {
    await this.upConfigUser(player)
    this.selectedPlayer = player;
    this.dialogConfig = true;
  },
  async onChangeConfigUser() {
    this.loading = true;
    this.configUserData.total_bet_a_fight = parseInt(
      this.configUserData.total_bet_a_fight
    );

    this.configUserData.total_bet_win_a_day = parseInt(
      this.configUserData.total_bet_win_a_day
    );
    this.configUserData.amount_min = parseInt(
      this.configUserData.amount_min
    );

    this.configUserData.amount_max = parseInt(
      this.configUserData.amount_max
    );
    this.configUserData.parent_id = this.selectedPlayer.parent_id;

    if (this.configUserData._id) {
      await this.updateConfigUser({ vm: this, d: this.configUserData });
      this.loading = false;
      this.dialogConfig = false;
    }
  },
  async upConfigUser(player) {
    let data = { currency_type: player.currency_type, _id: player._id };
    this.$request
      .post({
        url: "configUser/getConfigData",
        data: data,
      })
      .then((res) => {
        this.configUserData = res.data.data.object;
      });
    await this.getLimitDataFromApi(player);
  },
  async getLimitDataFromApi(player) {
    let submitLimitData = {
      currency_type: player.currency_type,
      _id: player.parent_id,
    };
    await this.requestLimitData(submitLimitData);
  },
  switchResult(name) {
      let data;
      switch (name) {
        case 'BANKER':
          data = "B";
          return data;
        case 'PLAYER':
          data = "P";
          return data;
        case 'TIE':
          data = "T";
          return data;
        case 'PAIR BANKER':
          data = "PB";
          return data;
        case 'PAIR PLAYER':
          data = "PP";
          return data;
        case 'CANCEL':
          data = "C";
          return data;
      }
    },
  changeTittle(module_id){
      if(module_id == "62dfe1d65ef1fb28515a1a75"){
        this.title = "Lotto Plus Invoice";
      }else if(module_id == "62f92808016e8046cccee5bc"){
        this.title = "Apong Invoice";
      }else if(module_id == "62dfdfb85ef1fb28515a1a72") {
        this.title = "Yuki Invoice";
      }else if(module_id == "62dfdf855ef1fb28515a1a71") {
        this.title = "Cock Fight Invoice";
      }else if(module_id == "63a0995c9cbbad6ab61dfc78"){
        this.title = "Card Invoice";
      }else if(module_id == "63675312283c9121b8a30a92"){
        this.title = "Rat Invoice";
      }else if(module_id == "6371f53073798150886bc23b"){
        this.title = "Klaklouk Invoice";
      }else{
        this.title = "Lotto80 Invoice";
      }
    },
  closeConfigUserDialog() {
    this.dialogConfig = false;
  },
  _openTransaction(selectedUser){
    this.selectedUser = selectedUser;
    this.dialogViewTransaction = true;
  },
  _closeDialogViewTransaction(){
    this.selectedUser = {};
    this.dialogViewTransaction = false;
    },
    _openDetailBetting(user) {
        this.user_iid = user._id;
        this.loadDetailData(this.user_iid);
        this.dialogDetailBetting = true;
    },
    selectModule(module_id){
      this.module_id = module_id;
      this.dataModule.module_id = module_id;
      let currency_type = this.currency_type;
      this.dataModule.startDate = this.startDate;
     this.dataModule.endDate = this.endDate;
      this.loadDetailModule(this.user_iid,currency_type);
    },
    _searchModule(){
      let user_id = this.user_iid;
      let currency_type = this.currency_type;
       this.loadDetailModule(user_id,currency_type);
        this.dialogModule = true;      
    },

    _goToModule(user_id,currency_type){
       this.user_iid = user_id;
       this.currency_type = currency_type;
       this.loadDetailModule(user_id,currency_type);
        this.dialogModule = true;      
    },
    async loadDetailModule(user_id,currency_type){
     this.detailModule = [];
     this.detailModuleCount = [];
     this.getLoadingDetailModule = true;
     
     this.dataModule.startDate = this.startDate;
     this.dataModule.endDate = this.endDate;
      this.dataModule.sortByFormat.length < 1
       ? (this.dataModule.sortBy = "_id")
       : (this.dataModule.sortBy = this.dataModule.sortByFormat[0]);
     this.dataModule.descendingFormat.length > 0
       ? (this.dataModule.descending = this.dataModule.descendingFormat[0])
       : (this.dataModule.descending = true);


     this.dataModule.user_id = user_id;
     this.dataModule.module_id = this.module_id;
    //  let headers = {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   };
    //   headers['currency-type'] = currency_type;
    this.$cookies.set("currency-type", parseInt(currency_type));
    
    await this.$request
       .post({
         url: "statement-by-user-module",
         data: this.dataModule,
       })
       .then((res) => {
         if (res.data.code) {
           this.detailModule = res.data.data.object;
           this.detailModuleCount = res.data.data.objectCount;
           this.getLoadingDetailModule = false;
         }else{
           
           this.getLoadingDetailModule = false;
         }
       });
       
   },

    _goToDetail(user_id, module_id, userName){
       this.user_iid = user_id;
       this.module_id = module_id;
       this.user_name = userName;
       if(module_id == "62dfe1d65ef1fb28515a1a75" || module_id == "62dfdfb85ef1fb28515a1a72"){
        this.loadDetailDataY(user_id, module_id);
        this.dialogY = true;
       }else{
        this.loadDetailDataCockFight(user_id, module_id,userName);
        this.dialogN = true;
       }
       this.changeTittle(module_id);
    },

    async loadDetailDataY(user_id, module_id){
     
     this.detailData = [];
     this.detailDataCount = [];
     this.getLoadingDetail = true;
     
     this.dataRequest.startDate = this.startDate;
     this.dataRequest.endDate = this.endDate;
      this.dataRequest.sortByFormat.length < 1
       ? (this.dataRequest.sortBy = "_id")
       : (this.dataRequest.sortBy = this.dataRequest.sortByFormat[0]);
     this.dataRequest.descendingFormat.length > 0
       ? (this.dataRequest.descending = this.dataRequest.descendingFormat[0])
       : (this.dataRequest.descending = true);

     this.dataRequest.user_id = user_id;
     this.dataRequest.module_id = module_id;
    await this.$request
       .post({
         url: "invoice",
         data: this.dataRequest,
       })
       .then((res) => {
         if (res.data.code) {
           this.detailData = res.data.data.object;
           this.detailDataCount = res.data.data.objectCount;
           this.getLoadingDetailY = false;
         }else{
           
           this.getLoadingDetailY = false;
         }
       });
   },

   async loadDetailDataCockFight(user_id, module_id,userName){
      this.detailData = [];
      this.detailDataCount = [];
      this.getLoadingDetailCockFight = true;
      
      this.dataRequest.startDate = this.startDate;
      this.dataRequest.endDate = this.endDate;
       this.dataRequest.sortByFormat.length < 1
        ? (this.dataRequest.sortBy = "_id")
        : (this.dataRequest.sortBy = this.dataRequest.sortByFormat[0]);
      this.dataRequest.descendingFormat.length > 0
        ? (this.dataRequest.descending = this.dataRequest.descendingFormat[0])
        : (this.dataRequest.descending = true);

      this.dataRequest.user_id = user_id;
      this.dataRequest.module_id = module_id;
      this.dataRequest.userName = userName;
      this.dataRequest.channelType = this.channelType;
     await this.$request
        .post({
          url: "invoice",
          data: this.dataRequest,
        })
        .then((res) => {
          if (res.data.code) {
            
            this.detailData = res.data.data.object;
            this.detailDataCount = res.data.data.objectCount;
            this.getLoadingDetailCockFight = false;
          }else{
            
            this.getLoadingDetailCockFight = false;
          }
        });
    },

    _goToInvoiceDetail(userName, appId,invoice_id,module_id){
      if(module_id == "62dfdfb85ef1fb28515a1a72"){
        this.loadInvoiceYukiDetailData(userName, appId,invoice_id,module_id);
        this.invoiceDialogYuki = true;
        
      }else if(module_id == "62dfe1d65ef1fb28515a1a75"){
        // lotto plus
        this.loadInvoiceDetailData(userName, appId,invoice_id,module_id);
        this.invoiceDialog = true;
      }
    },
    async loadInvoiceYukiDetailData(userName, appId,invoice_id,module_id){
     
     this.invoiceDetailData = [];
     this.getLoadingInvoiceDetail = true;
    
     this.dataInvoiceRequest.userName = userName;
     this.dataInvoiceRequest.appId = appId;
     this.dataInvoiceRequest.invoice_id = invoice_id;
     this.dataInvoiceRequest.module_id = module_id;
     
    await this.$request
       .post({
         url: "inoiceDetailKH",
         data: this.dataInvoiceRequest,
       })
       .then((res) => {
         if (res.data.code) {
           this.invoiceDetailData = res.data.data;
          //  this.invoiceDetailDataCount = res.data.data;
           this.getLoadingInvoiceDetail = false;
         }else{
           this.invoiceDetailData = false;
         }
       });
   },
   async loadInvoiceDetailData(userName, appId,invoice_id,module_id){
     
     this.invoiceDetailData = [];
     this.getLoadingInvoiceDetail = true;
    
     this.dataInvoiceRequest.userName = userName;
     this.dataInvoiceRequest.appId = appId;
     this.dataInvoiceRequest.invoice_id = invoice_id;
     this.dataInvoiceRequest.module_id = module_id;
     
    await this.$request
       .post({
         url: "inoiceDetailKH",
         data: this.dataInvoiceRequest,
       })
       .then((res) => {
         if (res.data.code) {
           this.invoiceDetailData = res.data.data;
           this.getLoadingInvoiceDetail = false;
          
         }else{
           
           this.invoiceDetailData = false;
         }
       });
   },
    searchq() {
        if (this.defaultSelected && this.defaultSelected._id) this.channel_type = this.defaultSelected._id;
        else this.channel_type = this.defaultSelected;
        this.dataRequest.search = this.v_search;
        this.loadDetailData(this.user_iid)
    },
    updatePageModule() {
        this.getLoadingDetail = true;
       this.loadDetailModule(this.user_iid,this.currency_type);
        this.getLoadingDetail = false;
    },
    updatePage() {
        this.getLoadingDetail = true;
        this.loadDetailData(this.user_iid);
        this.getLoadingDetail = false;
    },
    updatePageCock() {
        this.getLoadingDetail = true;
        this.loadDetailDataCockFight(this.user_iid,this.module_id,this.user_name);
        this.getLoadingDetail = false;
    },
    async loadDetailData(_id) {
        this.detailData = [],
            this.detailDataCount = [],
            this.getLoadingDetail = true;
        if (this.channel_type != null && typeof this.channel_type === 'object') {
            this.dataRequest.channelType = this.channel_type.id;
        }
        if (this.channel_type != null && typeof this.channel_type !== 'object') {
            this.dataRequest.channelType = this.channel_type;
        }
        this.startTime = this.v_startTime;
        this.endTime = this.v_endTime;
        if (this.v_endDate && this.v_startDate) {
            this.startDate = this.v_startTime;
            this.endDate = this.v_endDate;
        }

        let formatStartDate = '';
        let formatEndDate = '';

        if (this.statusTime) {
            formatStartDate = this.startDate + "T" + this.v_startTime + ":00";
            formatEndDate = this.endDate + "T" + this.v_endTime + ":59";
        } else {
            formatStartDate = this.startDate+"T00:00:01";
            formatEndDate = this.endDate+"T23:59:00";
        }
        this.dataRequest.search = this.v_search;
        this.dataRequest.startDate = formatStartDate;
        this.dataRequest.endDate = formatEndDate;
        this.dataRequest.sortByFormat.length < 1
            ? (this.dataRequest.sortBy = "_id._id")
            : (this.dataRequest.sortBy = this.dataRequest.sortByFormat[0]);
        this.dataRequest.descendingFormat.length > 0
            ? (this.dataRequest.descending = this.dataRequest.descendingFormat[0])
            : (this.dataRequest.descending = true);
        await this.$request
            .post({
                url: "adminReport/getViewPlayerDetailSummaryKH/" + _id,
                data: this.dataRequest,
            })
            .then((res) => {
                if (res.data.code) {

                    this.detailData = res.data.data.object;
                    this.detailDataCount = res.data.data.objectCount;
                    this.getLoadingDetail = false;
                } else {

                    this.getLoadingDetail = false;
                }
            });
    },

    currencyFormat(num) {
        num = parseFloat(num);
        // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        return parseInt(num).toLocaleString('en')
    },
  ...mapMutations("$_enableDisablePlayer", [
    "UPDATE_CURRENT_USER",
    "UPDATE_LIMIT_DATA",
    "UPDATE_LOADING_CONFIG"
  ]),
  ...mapActions("$_enableDisablePlayer", [
    "updateUser",
    "updateSuspendUser",
    "fetchListUsers",
    "requestLimitData",
    "updateConfigUser",
    "fetchModule"
  ]),
},

};
</script>

<style lang="scss" scoped>
.users {
width: 50%;
float: left;

h3 {
  font-size: 17px;
}
}

table {
font-family: "khmer mef1" !important;
}

  .custom-menu {
      max-width: 11%;
  }

  .custom-input-time {
      border: 1.5px solid black;
      padding: 2px 10px;
      border-radius: 5px;
      margin-top: 10px;
  }
  .lose-color{
      color:red;
  }
  .win-color{
      color:dodgerblue;
  }
  .WALA{
      color:deepskyblue;
  }
  .MERON{
      color:red;
  }
  .TIE{
      color:forestgreen;
  }
  .to-detail{
  cursor: pointer;
  text-decoration: underline;
}
</style>
