const headers = [
    // { text: 'សកម្មភាព', align: 'start', value: 'actions', sortable: false },
    {
        text: 'ល.រ',
        align: 'start',
        value: 'no',
    },
    { text: 'Company', value: '_id.com' },
    { text: 'Senior', value: '_id.admin' },
    { text: 'Master', value: '_id.master' },
    { text: 'Agent', value: '_id.agent' },
    { text: 'អ្នកប្រើប្រាស់', value: '_id.player' },
    { text: 'គណនី', value: '_id.agent_balance' },
    { text: 'សរុបប្រាក់ភ្នាល់', value: 'amount' },
    { text: 'សរុបឈ្នះ/ចាញ់', value: 'amount_win' },
    // { text: 'ប្រភេទរូបិយប័ណ្ណ', value: 'currency_type' },
];

export default headers;