const getModules = (state) => state.modules;
const getPopUp = (state) => state.popUp;
const getCurrentModule = (state) => state.currentModule;
const getParentModule = (state) => state.moduleParent;
const getDeleteItems = (state) => state.deleteItems;
const getLoading = (state) => state.loading

export default {
	getLoading,
	getModules,
	getPopUp,
	getCurrentModule,
	getDeleteItems,
	getParentModule
};
