import statementMasterUSDAll from '@/modules/statementAll/statementMasterUSD';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/statementMasterUSDAll',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: statementMasterUSDAll,
				name: 'statementMasterUSDAll'
			}
		]
	}
];

export default routes;
