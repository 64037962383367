/* eslint-disable no-unused-vars */
import Request from '@/services/axios'

let request = new Request;

const fetchListStatement = async({ commit }) => {

    const response = await request.get({
        url: 'cockOutstanding',
    });
    if (response.data.code) {
        console.log(response.data.channel1)
        commit('CHANNEL_1', response.data.channel1);
        commit('CHANNEL_2', response.data.channel2);
        commit('CHANNEL_3', response.data.channel3);
        commit('CHANNEL_4', response.data.channel4);
    }

};

const fetchGetCurrentChannel = async({ commit }) => {
    commit('LOADING', response.data);
    const response = await request.get({
        url: 'coChannel/getCurrentChannel',
    });
    if (response.data.code) {
        commit('LIST_CHANNEL', response.data);
    }
};


export default {
    fetchListStatement,
    fetchGetCurrentChannel,
    
};