import liveBettingCard from '@/modules/liveBettingCard';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/liveBettingCard',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: liveBettingCard,
				name: 'liveBettingCard'
			}
		]
	}
];

export default routes;
