<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="getUsers ? getUsers.object : []"
      :footer-props="footerProps"
      item-key="_id"
      class="elevation-1"
      :server-items-length="getUsers ? getUsers.objectCount : null"
      style="font-family: 'khmer mef1'"
      :page.sync="data.page"
      :items-per-page.sync="data.rowsPerPage"
      :sort-desc.sync="data.descendingFormat"
      :sort-by.sync="data.sortByFormat"
      @update:page="getData"
      @update:items-per-page="getData"
      @update:sort-desc="getData"
      show-select
      :loading="getLoading"
      :value="getDeleteItems"
      @input="itemDelete"
      mobile-breakpoint="0"
    >
      <template v-slot:top>
        <Header
          :darkmode="darkmode"
          :params="data"
          :dataDelete="getDeleteItems"
          :userp="userp"
          v-on:child-checkbox="transactionRefresh"
        />
      </template>

      <template v-slot:[`item.enable`]="{ item }">
     
          <v-btn :color="item.enable ? 'success' : 'error'" @click="_openDialogSuspend(item)" :disabled="userp && !userp.enable? true:false">
            {{ item.enable ? "Active" : "Inactive" }}
          </v-btn>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ convertDateTime(item.createdAt) }}
      </template>
      <template v-slot:[`item.balance`]="{ item }">
        <span  v-if="item.role_id ==='612a3fcc1a1fcead8871e822'">
          <div v-if="item.company_balance[0]">
          {{ currencyFormatUsd(item.company_balance[0].balance) +'$, '}} 
          </div>
          <div v-if="item.company_balance[1]">
          {{ currencyFormatKh(item.company_balance[1].balance) +'៛'}} 
         </div>
         </span>
        <span  v-if="item.role_id !=='612a3fcc1a1fcead8871e822' && (item.currency_type===1) " >
          {{ currencyFormatUsd(item.balance) +'$'}}
        </span>
        <span  v-if="item.role_id !=='612a3fcc1a1fcead8871e822' && item.currency_type===2" >
          {{ currencyFormatKh(item.balance) +'៛'}}
        </span>
      </template>
      <template v-slot:[`item.public_ip`]="{ item }">
          <a  @click="_openDialogListIpUser(item)" >
            {{ item.public_ip }}
          </a>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <ActionComponents
          :darkmode="darkmode"
          :item="item"
          :dataDelete="getDeleteItems"
          :params="data"
          :userp="userp"
          :user-permission="userAccessPermission"
          v-on:child-checkbox="transactionRefresh"
        />
      </template>
      <template v-slot:[`item.transaction`]="{ item }">
        <TransactionComponents
          :darkmode="darkmode"
          :item="item"
          :params="data"
          :userp="userp"
          @refresh="transactionRefresh"
          
        />
      </template>
    </v-data-table>
    <DialogListIpUserComponents   
      :dialog="ipDialog"
      :listData="listIpUserData"
      @onCloseDialog="ipDialog = !ipDialog"/>
    <Dialog
      :darkmode="darkmode"
      :loading="loading"
      :user="getCurrentUser"
      :dialog="getPopUp"
      :userp="userp"
      :optionRoles="optionRoles"
      :is-validate="valid"
      :listCompany="getListUserCompany"
      :listModule="getListUserModule"
      :listChildModule="getListUserChildModule"
      :selectedChannel="selectedChannel"
      v-on:submit="handleSubmit"
      v-on:handleListCompany="getListCompany"
      v-on:onCloseDialog="closeDialogCreate"
      
    />

  <v-dialog v-model="suspendDialog" persistent max-width="500px">
      <v-card >
        <v-card-title class="text-h5 py-5">
         do you want to this user and its children
        </v-card-title>
        <v-card-actions>
          <v-btn color="blue-grey"    class="ma-2 white--text"  @click="_closeDialogSuspend">Cancel</v-btn>
          <v-spacer></v-spacer>
          <!-- <v-btn     class="ma-2 white--text" :color="targetChannel && targetChannel.enable ?  'error':'success'" @click="_takeAction">{{targetChannel && targetChannel.enable ? "Inactive Channel" :"Active Channel"}}</v-btn> -->
          <v-btn  class="ma-2 white--text" :color="selectedUser && selectedUser.enable ?  'error':'success'"
          @click="_updateUserSuspendStatus()">{{selectedUser && selectedUser.enable ? "Inactive Channel" :"Active Channel"}}</v-btn>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
    <loader
      v-if="this.waiting == true"
      object="#ff9633"
      color1="#ffffff"
      color2="#17fd3d"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      name="circular"
    ></loader>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    ActionComponents: () => import("./_components/Action"),
    TransactionComponents: () => import("./_components/Transaction"),
    DialogListIpUserComponents: () => import("./_components/DialogListIpUser"),
    Header: () => import("./_components/HeaderTable.vue"),
    Dialog: () => import("./_components/Dialog.vue"),
  },
  data: () => ({
    valid: true,
    search: null,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    data: {
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: ["user_name", "balance", "account_info", "enable", "updatedAt"],
      search: "",
    },
    dialog: false,
    loading: false,
    selectedUser:[],
    suspendDialog : false,
    waiting:false,
    ipDialog:false,
    selectedChannel:["1","2","3","4"],
    listIpUserData:[]
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t("header.user_name"),
          align: "start",
          sortable: false,
          value: "user_name",
        },
        { text: this.$t("header.bank_account"), value: "account_info" },
        { text: this.$t("header.balance"), value: "balance" },
        { text: this.$t("header.role"), value: "role_name" },
        {
          text: this.$t("header.deposit_withdraw"),
          value: "transaction",
          sortable: false,
        },
        { text: this.$t("header.address"), value: "public_ip" },
        { text: this.$t("header.active"), value: "enable" },
        { text: this.$t("header.actions"), value: "actions", sortable: false },
      ];
    },
    ...mapGetters("$_modules", { darkmode: "getDarkMode", userp: "getUser" }),
    ...mapGetters("$_user", [
      "getUsers",
      "getPopUp",
      "getCurrentUser",
      "getDeleteItems",
      "getLoading",
       "getListUserCompany",
       "getListUserModule",
       "getListUserChildModule"
    ]),
    ...mapGetters("$_role", { optionRoles: "getAllRolesDependOnUser" }),
  },
  async created() {
    await this.fetchUserAccessPermission(this.$route.path);
    await this.getListModule();
    await this.getData();
    await this.fetchAllRolesDependOnUser();
    await this.getListCompany();
    await this.getListChildModule();
    
  },
  watch: {
    userp: function() {},
    optionRoles: function() {},
  },
  methods: {
    _openDialogListIpUser(item){
      this.getListIpUserData(item._id);
      this.ipDialog = true;
    },
    async getListIpUserData(_id){
      let data = {
        user_id : _id
      }
       await this.$request
        .post({
          url: "/users/getLoginIpByUser",
          data: data,
        })
        .then((res) => {
          if (res.data.code) {
            console.log("res.data.data",res.data.data);
            this.listIpUserData = res.data.data
            // this.waiting = false;
          }
        });
    },
    async handleSubmit() {
      this.loading = true;
      if (!this.getCurrentUser._id) {
        if (this.getCurrentUser.account_info == null)
          this.getCurrentUser.account_info = "";
        if (this.getCurrentUser.role_id.child_role_name)
          this.getCurrentUser.role_id = this.getCurrentUser.role_id.child_role_id;
        if(this.userp && this.userp.role_id ==   "612a3fcc1a1fcead8871e822"){
         this.getCurrentUser.role_id  == "61829d84e64b1b17688326a3" ?  this.getCurrentUser.currency_type = 1 :  this.getCurrentUser.role_id  == "61829d75e64b1b1768832699" ? 
         this.getCurrentUser.currency_type = 2 :  this.getCurrentUser.role_id  == "61829d93e64b1b17688326ad" ?   this.getCurrentUser.currency_type = 3 :""
       }
       //console.log(this.getCurrentUser);
        await this.createUser({ vm: this, data: this.getCurrentUser });
      } else {
        await this.updateUser({ vm: this, data: this.getCurrentUser });
      }
      await this.getData();
      this.loading = false;
    },
    
    async transactionRefresh() {
      this.loading = true;
      await this.fetchListUsers(this.data);
      this.loading = false;
    },
    currencyFormatKh(num) {
      num = parseFloat(num);
      // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return parseInt(num).toLocaleString("en");
    },
    currencyFormatUsd(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      // return parseInt(num).toLocaleString("en");
    },
    async getData() {
      this.checkUserPermission("VIEW")
        ? await this.fetchListUsers(this.data)
        : [];
    },

    itemDelete(e) {
      const items = e.map(function(e) {
        return { _id: e._id };
      });
      this.DELETE_ITEMS(items);
    },

    async closeDialogCreate() {
      this.fetchPopUp(false);
      await this.fetchAllRolesDependOnUser();
    },
     async getListCompany() {
      await this.fetchListCompany();
    },
    async getListModule() {
      await this.fetchUserModule();
    },
    async getListChildModule() {
      await this.fetchChildModule();
    },
    _openDialogSuspend(item){
      this.selectedUser = item
      this.suspendDialog = true
      
    },
     _closeDialogSuspend(){
      this.selectedUser = []
      this.suspendDialog = false
    },

    async _updateUserSuspendStatus(){
      this.waiting = true;
      let data = {
        enable : !this.selectedUser.enable,
        userId: this.selectedUser._id// channel_type : this.channel_type
      }
      await this.$request
        .post({
          url: "/users/suspendUserTreeDown",
          data: data,
        })
        .then((res) => {
          if (res.data.code) {
            this.getData()
            this._closeDialogSuspend()
            this.waiting = false;
          }else{
            this.getData()
            this._closeDialogSuspend()
            this.waiting = false;
            
          }
        });
      // console.log("_takeAction", this.targetChannel, this.channel_type)
      // this.targetChannel = [];
      // this.channel_type = null
      // this.confirmDialog = false;
    },

    ...mapMutations("$_user", [
      "UPDATE_CURRENT_USER",
      "DELETE_ITEMS",
      "UPDATE_USERS",
      "UPDATE_USER",
    ]),
    ...mapMutations("$_role", ["UPDATE_ROLES_DEPEND_ON_USER"]),
    ...mapActions("$_role", ["fetchAllRolesDependOnUser"]),
    ...mapActions("$_user", [
      "fetchPopUp",
      "createUser",
      "fetchCurrentUser",
      "updateUser",
      "fetchListUsers",
      "fetchListCompany",
      "fetchUserModule",
      "fetchChildModule",
    ]),
  },
  async beforeRouteLeave(to, from, next) {
    this.UPDATE_USERS([]);
    this.UPDATE_USER_ACCESS_PERMISSION([]);
    next();
  },
};
</script>

<style lang="scss" scoped>
.users {
  width: 50%;
  float: left;

  h3 {
    font-size: 17px;
  }
}

table {
  font-family: "khmer mef1" !important;
}
</style>
