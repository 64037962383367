import duplicateTransaction from '@/modules/duplicateTransaction';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/duplicateTransaction',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: duplicateTransaction,
				name: 'duplicateTransaction'
			}
		]
	}
];

export default routes;
