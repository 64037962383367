<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="getAllBanner ? getAllBanner.object : []"
      :footer-props="footerProps"
      item-key="_id"
      class="elevation-1"
      :server-items-length="getAllBanner ? getAllBanner.count : null"
      style="font-family: 'khmer mef1'"
      :page.sync="data.page"
      :items-per-page.sync="data.rowsPerPage"
      :sort-desc.sync="data.descendingFormat"
      :sort-by.sync="data.sortByFormat"
      @update:page="getData"
      @update:items-per-page="getData"
      @update:sort-desc="getData"
      show-select
      :loading="getLoading"
      :value="getDeleteItems"
      @input="itemDelete"
      mobile-breakpoint="0"
    >
      <template v-slot:top>
        <Header
          :darkmode="darkmode"
          :params="data"
          :dataDelete="getDeleteItems"
          :user-permission="userAccessPermission"
        />
      </template>
      <template v-slot:[`item.profile_image`]="{ item }">
        <v-img
          v-bind:src="`${baseUrl + item.profile_image}`"
          height="50"
          width="50"
        ></v-img>
      </template>
      <template v-slot:[`item.is_active`]="{ item }">
          <v-btn :color="item.is_active ? 'success' : 'error'" @click="_openDialogSuspend(item)">
            {{ item.is_active ? "Active" : "Inactive" }}
          </v-btn>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <ActionComponents
          :darkmode="darkmode"
          :item="item"
          :dataDelete="getDeleteItems"
          :params="data"
          :user-permission="userAccessPermission"
        />
      </template>
    </v-data-table>
    <Dialog
      :darkmode="darkmode"
      :loading="loading"
      :dealerData="getCurrenBannerData"
      :dialog="getPopUp"
      :is-validate="valid"
      v-on:submit="handleSubmit"
      v-on:onCloseDialog="closeDialogCreate"
    />
    <v-dialog v-model="suspendDialog" persistent max-width="500px">
      <v-card >
        <v-card-title class="text-h5 py-5">
         do you want to this Dealer
        </v-card-title>
        <v-card-actions>
          <v-btn color="blue-grey"    class="ma-2 white--text"  @click="_closeDialogSuspend">Cancel</v-btn>
          <v-spacer></v-spacer>
          <!-- <v-btn     class="ma-2 white--text" :color="targetChannel && targetChannel.enable ?  'error':'success'" @click="_takeAction">{{targetChannel && targetChannel.enable ? "Inactive Channel" :"Active Channel"}}</v-btn> -->
          <v-btn  class="ma-2 white--text" :color="selectedDealer && selectedDealer.is_active ?  'error':'success'"
          @click="_updateDealerSuspendStatus()">{{selectedDealer && selectedDealer.is_active ? "Inactive Channel" :"Active Channel"}}</v-btn>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    ActionComponents: () => import("./_components/Action"),
    Header: () => import("./_components/HeaderTable.vue"),
    Dialog: () => import("./_components/Dialog.vue"),
  },
  data: () => ({
    valid: true,
    search: null,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    data: {
      currency_type: 1,
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: ["name", "profile_image","barcode","dob"],
      search: "",
    },
    selectedDealer:[],
    suspendDialog : false,
    waiting:false,
    dialog: false,
    loading: false,
    baseUrl: process.env.VUE_APP_API_REPORT_URL
  }),
  computed: {
    headers() {
      return [
        { text: this.$t("input.barcode"), value: "barcode" },
        { text: this.$t("input.dealer_name"), value: "name" },
        { text: this.$t("input.dealer_dob"), value: "dob" },
        { text: this.$t("input.profile_image"), value: "profile_image" },
        { text: this.$t("header.active"), value: "is_active" },
        { text: this.$t("header.actions"), value: "actions", sortable: false },
      ];
    },
    ...mapGetters("$_modules", { darkmode: "getDarkMode" }),
    ...mapGetters("$_dealer", [
      "getAllBanner",
      "getLoading",
      "getDeleteItems",
      "getPopUp",
      "getCurrenBannerData",
    ]),
  },
  async created() {
    await this.fetchUserAccessPermission(this.$route.path);
    await this.getData();
  },
  methods: {
    async handleSubmit() {
      this.loading = true;
      if (!this.getCurrenBannerData._id) {
        let formData = new FormData();
        formData.append("file", this.getCurrenBannerData.profile_image);
        formData.append("name", this.getCurrenBannerData.name);
        formData.append("dob", this.getCurrenBannerData.dob);
        formData.append("barcode", this.getCurrenBannerData.barcode);
        formData.append("position", this.getCurrenBannerData.position);
        formData.append("address", this.getCurrenBannerData.address);
        await this.createBannerData({ vm: this, data: formData });
        this.getCurrenBannerData = [];
        this.fetchPopUp(false);
      } else {
        let formData = new FormData();
        formData.append("file", this.getCurrenBannerData.profile_image);
        formData.append("name", this.getCurrenBannerData.name);
        formData.append("dob", this.getCurrenBannerData.dob);
        formData.append("barcode", this.getCurrenBannerData.barcode);
        formData.append("_id", this.getCurrenBannerData._id);
        formData.append("position", this.getCurrenBannerData.position);
        formData.append("address", this.getCurrenBannerData.address);
        await this.updateLimit({
          vm: this,
          data: formData
        });
        this.fetchPopUp(false);
      }
      await this.getData();
      this.loading = false;
    },

    async getData() {
      this.checkUserPermission("VIEW")
        ? await this.fetchGetAllBanner(this.data)
        : [];
    },

    itemDelete(e) {
      const items = e.map(function (e) {
        return { _id: e._id };
      });
      this.DELETE_ITEMS(items);
    },

    closeDialogCreate() {
      this.getCurrentConfigUserData = {};
      this.fetchPopUp(false);
    },
    async _updateDealerSuspendStatus(){
      this.waiting = true;
      let data = {
        is_active : !this.selectedDealer.is_active,
        dealerId: this.selectedDealer._id// channel_type : this.channel_type
      }
      await this.$request
        .post({
          url: "/dealer/suspendDealer",
          data: data,
        })
        .then((res) => {
          if (res.data.code) {
            this.getData()
            this._closeDialogSuspend()
            this.waiting = false;
          }else{
            this.getData()
            this._closeDialogSuspend()
            this.waiting = false;
            
          }
        });
    },
    _openDialogSuspend(item){
      this.selectedDealer = item
      this.suspendDialog = true
    },
    _closeDialogSuspend(){
      this.selectedDealer = []
      this.suspendDialog = false
    },

    ...mapMutations("$_dealer", ["UPDATE_BANNER_DATA", "DELETE_ITEMS"]),
    ...mapActions("$_dealer", [
      "fetchPopUp",
      "createBannerData",
      "fetchGetAllBanner",
      "updateLimit",
    ]),
  },
  async beforeRouteLeave(to, from, next) {
   // this.UPDATE_USERS([]);
    //this.UPDATE_USER_ACCESS_PERMISSION([]);
    next();
  },
};
</script>

<style lang="scss" scoped>
.users {
  width: 50%;
  float: left;

  h3 {
    font-size: 17px;
  }
}

table {
  font-family: "khmer mef1" !important;
}
</style>
