import Vue from 'vue';
import App from './App.vue';
import store from './services/store.js';
import router from './services/router';
import Request from './services/axios.js';
import VueCookies from 'vue-cookies';
import VueHtmlToPaper from 'vue-html-to-paper';
import i18n from './services/i18n';
import ComponentsInit from '@/components/shared/index.js';
import vuetify from '@/plugins/vuetify';
import mixin from './mixin';
import VueToastr from 'vue-toastr';
import VuetifyMoney from "@/plugins/vuetify-money";

import '@/assets/css/tailwind.css';
import '@mdi/font/css/materialdesignicons.css';
import '@/assets/css/scss/index.scss';
import loader from "vue-ui-preloader";

const options = {
    name: '_blank',
    specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
    styles: ['css/print.css']
};

// set global component
Vue.use(loader);
Vue.mixin(mixin);
Vue.use(ComponentsInit);
Vue.use(VueCookies);

Vue.use(VueHtmlToPaper, options);
Vue.use(VueToastr, { defaultTimeout: 500 });

import OtpInput from "@bachdgvn/vue-otp-input";
Vue.component("v-otp-input", OtpInput);

Vue.config.productionTip = false;
Vue.prototype.$request = new Request();

// import { io } from "socket.io-client";

//set global socket
// Vue.prototype.$connectSocket = (rolee = Vue.$cookies.get('user_role'), token = Vue.$cookies.get('token'), channel_type = Vue.$cookies.get('channel-type')) => {

//     if (rolee && rolee != "61bf5eb0c73d7eea8634fbf4" && rolee != "612a40a51a1fcead8871e833") {
//         let socket;
//         if (parseInt(channel_type) == 1) {
//             if (token) {
//                 socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}lotto-betting`, {
//                     extraHeaders: {
//                         "Content-Type": "application/json",
//                         "Authorization": token,
//                     }
//                 });
//             }

//             socket.on("connect", () => {
//                 console.log('socket: Connectd')
//             });

//             socket.on("connect_error", (err) => {
//                 console.log('socket: Message err == ', err);
//             });

//             socket.on('disconnect', () => {
//                 console.log(`socket: Disconnection`);
//             });

//             socket.on('error', (err) => {
//                 console.log('socket: Error connecting to server', err);
//             });

//             socket.on('reconnect', (number) => {
//                 console.log('socket: Reconnected to server', number);
//             });

//             socket.on('reconnect_attempt', () => {
//                 console.log('socket: Reconnect Attempt', 'alert problem');
//             });

//             socket.on('reconnecting', (number) => {
//                 console.log('socket: Reconnecting to server', number);
//             });
//             socket.on('reconnect_error', (err) => {
//                 console.log('socket: Reconnect Error', err);
//             });
//             socket.on('reconnect_failed', () => {
//                 console.log('socket: Reconnect failed', 'offline');
//             });

//             Vue.prototype.$socket = socket
//         }
//         if (parseInt(channel_type) == 2) {

//             if (token) {
//                 socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}lotto-betting`, {
//                     extraHeaders: {
//                         "Content-Type": "application/json",
//                         "Authorization": token,
//                     }
//                 });
//             }
//             socket.on("connect", () => {
//                 console.log('socket: Connectd')
//             });

//             socket.on("connect_error", (err) => {
//                 console.log('socket: Message err == ', err);
//             });

//             socket.on('disconnect', () => {
//                 console.log(`socket: Disconnection`);
//             });

//             socket.on('error', (err) => {
//                 console.log('socket: Error connecting to server', err);
//             });

//             socket.on('reconnect', (number) => {
//                 console.log('socket: Reconnected to server', number);
//             });

//             socket.on('reconnect_attempt', () => {
//                 console.log('socket: Reconnect Attempt', 'alert problem');
//             });

//             socket.on('reconnecting', (number) => {
//                 console.log('socket: Reconnecting to server', number);
//             });
//             socket.on('reconnect_error', (err) => {
//                 console.log('socket: Reconnect Error', err);
//             });
//             socket.on('reconnect_failed', () => {
//                 console.log('socket: Reconnect failed', 'offline');
//             });

//             Vue.prototype.$socket = socket
//         }
//         if (parseInt(channel_type) == 3) {

//             if (token) {
//                 socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}lotto-betting`, {
//                     extraHeaders: {
//                         "Content-Type": "application/json",
//                         "Authorization": token,
//                     }
//                 });
//             }
//             socket.on("connect", () => {
//                 console.log('socket: Connectd')
//             });

//             socket.on("connect_error", (err) => {
//                 console.log('socket: Message err == ', err);
//             });

//             socket.on('disconnect', () => {
//                 console.log(`socket: Disconnection`);
//             });

//             socket.on('error', (err) => {
//                 console.log('socket: Error connecting to server', err);
//             });

//             socket.on('reconnect', (number) => {
//                 console.log('socket: Reconnected to server', number);
//             });

//             socket.on('reconnect_attempt', () => {
//                 console.log('socket: Reconnect Attempt', 'alert problem');
//             });

//             socket.on('reconnecting', (number) => {
//                 console.log('socket: Reconnecting to server', number);
//             });
//             socket.on('reconnect_error', (err) => {
//                 console.log('socket: Reconnect Error', err);
//             });
//             socket.on('reconnect_failed', () => {
//                 console.log('socket: Reconnect failed', 'offline');
//             });

//             Vue.prototype.$socket = socket
//         }
//         if (parseInt(channel_type) == 4) {

//             if (token) {
//                 socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}lotto-betting`, {
//                     extraHeaders: {
//                         "Content-Type": "application/json",
//                         "Authorization": token,
//                     }
//                 });
//             }
//             socket.on("connect", () => {
//                 console.log('socket: Connectd')
//             });

//             socket.on("connect_error", (err) => {
//                 console.log('socket: Message err == ', err);
//             });

//             socket.on('disconnect', () => {
//                 console.log(`socket: Disconnection`);
//             });

//             socket.on('error', (err) => {
//                 console.log('socket: Error connecting to server', err);
//             });

//             socket.on('reconnect', (number) => {
//                 console.log('socket: Reconnected to server', number);
//             });

//             socket.on('reconnect_attempt', () => {
//                 console.log('socket: Reconnect Attempt', 'alert problem');
//             });

//             socket.on('reconnecting', (number) => {
//                 console.log('socket: Reconnecting to server', number);
//             });
//             socket.on('reconnect_error', (err) => {
//                 console.log('socket: Reconnect Error', err);
//             });
//             socket.on('reconnect_failed', () => {
//                 console.log('socket: Reconnect failed', 'offline');
//             });

//             Vue.prototype.$socket = socket
//         }
//     }
//     if (rolee && rolee == "61bf5eb0c73d7eea8634fbf4") {
//         let socket1, socket2, socket3, socket4;
//         if (token) {

//             socket1 = io(`${process.env.VUE_APP_WEBSOCKET_URL}lotto-betting`, {
//                 extraHeaders: {
//                     "Content-Type": "application/json",
//                     "Authorization": token,
//                 }
//             });
//             socket2 = io(`${process.env.VUE_APP_WEBSOCKET_URL}lotto-betting`, {
//                 extraHeaders: {
//                     "Content-Type": "application/json",
//                     "Authorization": token,
//                 }
//             });
//             socket3 = io(`${process.env.VUE_APP_WEBSOCKET_URL}lotto-betting`, {
//                 extraHeaders: {
//                     "Content-Type": "application/json",
//                     "Authorization": token,
//                 }
//             });
//             socket4 = io(`${process.env.VUE_APP_WEBSOCKET_URL}lotto-betting`, {
//                 extraHeaders: {
//                     "Content-Type": "application/json",
//                     "Authorization": token,
//                 }
//             });

//             socket1.on("connect", () => {
//                 console.log('socket1: Connectd')
//             });

//             socket1.on("connect_error", (err) => {
//                 console.log('socket1: Message err == ', err);
//             });

//             socket1.on('disconnect', () => {
//                 console.log(`socket1: Disconnection`);
//             });

//             socket1.on('error', (err) => {
//                 console.log('socket1: Error connecting to server', err);
//             });

//             socket1.on('reconnect', (number) => {
//                 console.log('socket1: Reconnected to server', number);
//             });

//             socket1.on('reconnect_attempt', () => {
//                 console.log('socket1: Reconnect Attempt', 'alert problem');
//             });

//             socket1.on('reconnecting', (number) => {
//                 console.log('socket1: Reconnecting to server', number);
//             });
//             socket1.on('reconnect_error', (err) => {
//                 console.log('socket1: Reconnect Error', err);
//             });
//             socket1.on('reconnect_failed', () => {
//                 console.log('socket1: Reconnect failed', 'offline');
//             });

//             Vue.prototype.$socket1 = socket1

//             socket2.on("connect", () => {
//                 console.log('socket2: Connectd')
//             });

//             socket2.on("connect_error", (err) => {
//                 console.log('socket2: Message err == ', err);
//             });

//             socket2.on('disconnect', () => {
//                 console.log(`socket2: Disconnection`);
//             });

//             socket2.on('error', (err) => {
//                 console.log('socket2: Error connecting to server', err);
//             });

//             socket2.on('reconnect', (number) => {
//                 console.log('socket2: Reconnected to server', number);
//             });

//             socket2.on('reconnect_attempt', () => {
//                 console.log('socket2: Reconnect Attempt', 'alert problem');
//             });

//             socket2.on('reconnecting', (number) => {
//                 console.log('socket2: Reconnecting to server', number);
//             });
//             socket2.on('reconnect_error', (err) => {
//                 console.log('socket2: Reconnect Error', err);
//             });
//             socket2.on('reconnect_failed', () => {
//                 console.log('socket2: Reconnect failed', 'offline');
//             });

//             Vue.prototype.$socket2 = socket2

//             socket3.on("connect", () => {
//                 console.log('socket3: Connectd')
//             });

//             socket3.on("connect_error", (err) => {
//                 console.log('socket3: Message err == ', err);
//             });

//             socket3.on('disconnect', () => {
//                 console.log(`socket3: Disconnection`);
//             });

//             socket3.on('error', (err) => {
//                 console.log('socket3: Error connecting to server', err);
//             });

//             socket3.on('reconnect', (number) => {
//                 console.log('socket3: Reconnected to server', number);
//             });

//             socket3.on('reconnect_attempt', () => {
//                 console.log('socket3: Reconnect Attempt', 'alert problem');
//             });

//             socket3.on('reconnecting', (number) => {
//                 console.log('socket3: Reconnecting to server', number);
//             });
//             socket3.on('reconnect_error', (err) => {
//                 console.log('socket3: Reconnect Error', err);
//             });
//             socket3.on('reconnect_failed', () => {
//                 console.log('socket3: Reconnect failed', 'offline');
//             });

//             Vue.prototype.$socket3 = socket3

//             socket4.on("connect", () => {
//                 console.log('socket4: Connectd')
//             });

//             socket4.on("connect_error", (err) => {
//                 console.log('socket4: Message err == ', err);
//             });

//             socket4.on('disconnect', () => {
//                 console.log(`socket4: Disconnection`);
//             });

//             socket4.on('error', (err) => {
//                 console.log('socket4: Error connecting to server', err);
//             });

//             socket4.on('reconnect', (number) => {
//                 console.log('socket4: Reconnected to server', number);
//             });

//             socket4.on('reconnect_attempt', () => {
//                 console.log('socket4: Reconnect Attempt', 'alert problem');
//             });

//             socket4.on('reconnecting', (number) => {
//                 console.log('socket4: Reconnecting to server', number);
//             });
//             socket4.on('reconnect_error', (err) => {
//                 console.log('socket4: Reconnect Error', err);
//             });
//             socket4.on('reconnect_failed', () => {
//                 console.log('socket4: Reconnect failed', 'offline');
//             });

//             Vue.prototype.$socket4 = socket4
//         }
//     }
// };

new Vue({
    store,
    router,
    i18n,
    vuetify,
    VuetifyMoney,
    render: (h) => h(App)
}).$mount('#app');