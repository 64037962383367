var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",staticStyle:{"font-family":"'khmer mef1'"},attrs:{"headers":_vm.headers,"items":_vm.getViewTransaction ? _vm.getViewTransaction.object : [],"server-items-length":_vm.getViewTransaction.objectCount,"sort-desc":_vm.data.descendingFormat,"sort-by":_vm.data.sortByFormat,"footer-props":_vm.footerProps,"items-per-page":_vm.data.rowsPerPage,"page":_vm.data.page,"loading":!_vm.getViewTransaction.object,"item-key":"codeName"},on:{"update:sortDesc":function($event){return _vm.$set(_vm.data, "descendingFormat", $event)},"update:sort-desc":[function($event){return _vm.$set(_vm.data, "descendingFormat", $event)},_vm.getData],"update:sortBy":function($event){return _vm.$set(_vm.data, "sortByFormat", $event)},"update:sort-by":function($event){return _vm.$set(_vm.data, "sortByFormat", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.data, "rowsPerPage", $event)},"update:items-per-page":[function($event){return _vm.$set(_vm.data, "rowsPerPage", $event)},_vm.getData],"update:page":[function($event){return _vm.$set(_vm.data, "page", $event)},_vm.getData]},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"text-left mt-4",attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-toolbar-title',{staticClass:"pl-5"},[_vm._v(_vm._s(_vm.$t('title.depositStatement')))]),_c('v-spacer')],1),_c('v-col',{staticClass:"text-right pl-5",attrs:{"cols":"6","sm":"6","md":"2"}},[_c('v-menu',{staticClass:"pl-5",attrs:{"close-on-content-click":false,"nudge-right":100,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('input.start_date'),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.data.startDate),callback:function ($$v) {_vm.$set(_vm.data, "startDate", $$v)},expression:"data.startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.v_startDate),callback:function ($$v) {_vm.v_startDate=$$v},expression:"v_startDate"}},[_c('v-date-picker',{on:{"input":function($event){_vm.v_startDate = false}},model:{value:(_vm.data.startDate),callback:function ($$v) {_vm.$set(_vm.data, "startDate", $$v)},expression:"data.startDate"}})],1)],1),_c('v-spacer'),_c('v-col',{staticClass:"text-right pl-5 ",attrs:{"cols":"6","sm":"6","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":100,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('input.end_date'),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.data.endDate),callback:function ($$v) {_vm.$set(_vm.data, "endDate", $$v)},expression:"data.endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.v_endDate),callback:function ($$v) {_vm.v_endDate=$$v},expression:"v_endDate"}},[_c('v-date-picker',{on:{"input":function($event){_vm.v_endDate = false}},model:{value:(_vm.data.endDate),callback:function ($$v) {_vm.$set(_vm.data, "endDate", $$v)},expression:"data.endDate"}})],1)],1),_c('v-col',{staticClass:"text-right pl-5",attrs:{"cols":"8","sm":"8","md":"2"}},[_c('v-text-field',{staticClass:"pl-5",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('input.search'),"single-line":"","hide-details":"","clearable":""},model:{value:(_vm.data.search),callback:function ($$v) {_vm.$set(_vm.data, "search", $$v)},expression:"data.search"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"text-right pr-5 mt-3",attrs:{"cols":"4","sm":"4","md":"2"}},[_c('v-btn',{staticClass:"white--text btn-custom mr-5",attrs:{"color":"green darken-1","depressed":""},on:{"click":function($event){return _vm._search()}}},[_vm._v(" "+_vm._s(_vm.$t('input.search'))+" ")])],1)],1)]},proxy:true},{key:"item.no",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((_vm.getViewTransaction.object.indexOf(item) + ( (_vm.data.page -1 ) * _vm.data.rowsPerPage))+ 1)+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.currency_type == 1 ? _vm.currencyFormatEn(item.amount) + "$" : item.currency_type == 2 ? _vm.currencyFormatKm(item.amount) + "៛" : 0)+" ")]}},{key:"item.old_balance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.currency_type == 1 ? _vm.currencyFormatEn(item.old_balance) + "$" : item.currency_type == 2 ? _vm.currencyFormatKm(item.old_balance) + "៛" : 0)+" ")]}},{key:"item.new_balance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.currency_type == 1 ? _vm.currencyFormatEn(item.new_balance) + "$" : item.currency_type == 2 ? _vm.currencyFormatKm(item.new_balance)+ "៛" : 0)+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date)+" ")]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',[_c('td',{staticClass:"text-xs-center pl-4 py-2 ",attrs:{"colspan":4}},[_vm._v(" "+_vm._s(_vm.$t("title.total"))+" ")]),_c('td',{staticClass:"text-xs-center total-style pl-4  py-2"},[_vm._v(" "+_vm._s(_vm.getViewTransaction.objAmount && ( _vm.user.currency_type == 1 || _vm.user.currency_type == 3 ) ? _vm.currencyFormatEn(_vm.getViewTransaction.objAmount) : _vm.getViewTransaction.objAmount && _vm.user.currency_type == 2 ? _vm.currencyFormatKm(_vm.getViewTransaction.objAmount) : 0)+" ")])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }