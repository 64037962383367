<template>
  <div>
    <v-row class="justify-center py-5 cm-title">
      Live Betting EE7777
    </v-row>
    <div class="w-100 text-right">
      <v-btn class="white--text mr-4 mt-3" color="green darken-1" depressed @click="searchData">
        <!-- @click="fetchListStatement(params)" -->
        Refresh
      </v-btn>
    </div>
    <!--    Display List Front Page-->
    <v-row style="margin-top:70px" elevation="24">
      <v-col cols="12" md="12" sm="12" class="my-5 w-100 text-center">
        <!-- <span class="text-h5">Channel:</span> <span class="channel_number_style"> {{ channel_1.channel_type }}</span> -->
        <br />
        fight no:
        <span class="fino">{{ channel_1.fight_no ? channel_1.fight_no : "Loading" }}</span>
        <v-data-table :headers="headers1" :items="StatementObj" :server-items-length="3" item-key="currency"
          class="elevation-1 table-style" mobile-breakpoint="0" hide-default-header hide-default-footer>
          <template v-slot:header="{ props }">
            <template v-for="(headers1, i1) in [processTableHeaders(props.headers)]">
              <tr :key="i1">
                <th></th>
                <th><span class="num-1">NUMBER-1</span></th>
                <th><span class="num-2">NUMBER-2</span></th>
                <th><span class="num-3">NUMBER-3</span></th>
                <th><span class="num-4">NUMBER-4</span></th>
                <th><span class="num-5">NUMBER-5</span></th>
                <th><span class="num-6">SMALL</span></th>
                <th><span class="num-7">LARGE</span></th>
              </tr>
            </template></template>
           
          <template v-slot:[`item`]="{ item }">
            <tr v-if="item">

              <td class="text-center">
                {{ item.currency }}
              </td>
              <td class="text-center num-1  text-decoration-underline row-pointer" @click="_goToDetail(item.num1_1, channel_1)">
                {{
                    item.num1_1.currency_type == 1
                      ? currencyFormatEn(item.num1_1.amount)
                      : currencyFormatKm(item.num1_1.amount)
                }}
              </td>
              <td class="text-center num-2  text-decoration-underline row-pointer" @click="_goToDetail(item.num2_1, channel_1)">
                {{
                    item.num2_1.currency_type == 1
                      ? currencyFormatEn(item.num2_1.amount)
                      : currencyFormatKm(item.num2_1.amount)
                }}
              </td>
              <td class="text-center num-3 text-decoration-underline row-pointer" @click="_goToDetail(item.num3_1, channel_1)">
                {{
                    item.num3_1.currency_type == 1
                      ? currencyFormatEn(item.num3_1.amount)
                      : currencyFormatKm(item.num3_1.amount)
                }}
              </td>
              <td class="text-center num-4  text-decoration-underline row-pointer" @click="_goToDetail(item.num4_1, channel_1)">
                {{
                    item.num4_1.currency_type == 1
                      ? currencyFormatEn(item.num4_1.amount)
                      : currencyFormatKm(item.num4_1.amount)
                }}
              </td>
              <td class="text-center num-5  text-decoration-underline row-pointer" @click="_goToDetail(item.num5_1, channel_1)">
                {{
                    item.num5_1.currency_type == 1
                      ? currencyFormatEn(item.num5_1.amount)
                      : currencyFormatKm(item.num5_1.amount)
                }}
              </td>
              <td class="text-center num-6 text-decoration-underline row-pointer" @click="_goToDetail(item.small_1, channel_1)">
                {{
                    item.small_1.currency_type == 1
                      ? currencyFormatEn(item.small_1.amount)
                      : currencyFormatKm(item.small_1.amount)
                }}
              </td>
              <td class="text-center num-7  text-decoration-underline row-pointer" @click="_goToDetail(item.large_1, channel_1)">
                {{
                    item.large_1.currency_type == 1 
                      ? currencyFormatEn(item.large_1.amount)
                      : currencyFormatKm(item.large_1.amount)
                }}
              </td>
  
            </tr>
          </template>
          <template slot="body.append">
            <tr>
              <td style="text-align:center " colspan="9" v-if="loader">
                <div class="lds-roller my-3">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!--    Detail Each Match -->
    <v-dialog :value="dialog" max-width="1200" persistent transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="pl-5">
            <v-col cols="12" sm="12" md="12" class="text-left mt-5">
              <v-row>
              <v-toolbar-title>Detail Player Statement
               
                <!-- <span style="margin-left:20px;"></span> ( Channel Type:
                <span class="detail_ch_number">{{
                    dataRequest.channel_type == 1 ? "1" : dataRequest.channel_type == 2 ? "2" : dataRequest.channel_type
                }}
                </span>) -->
   
       
         
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn class="white--text btn-custom " color="primary darken-1" style="margin-right:28px" depressed @click="searchq"
                :disabled="this.loadingGG">
                <!-- @click="fetchListStatement(params)" -->
                Refresh
              </v-btn>
            </v-row>
            </v-col>

            <v-col cols="8" sm="8" md="8" class="text-right mb-4">
              <v-text-field v-model="v_search" append-icon="mdi-magnify" label="Search" single-line hide-details
                clearable class="mr-5" />
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="2" sm="2" md="2" class="text-right mb-4">
              <v-btn class="white--text btn-custom mr-4 mt-3" color="green darken-1" depressed @click="searchq"
                :disabled="loadingGG">
                <!-- @click="fetchListStatement(params)" -->
                SEARCH
              </v-btn>
            </v-col>

          </v-row>
          <v-data-table style="font-family: 'khmer mef1'" class="elevation-1" item-key="date" :headers="headers"
            :footer-props="footerProps" :items="detailData ? detailData : []" :server-items-length="
              detailDataCount ? parseInt(detailDataCount.count) : null
            " :sort-desc.sync="dataRequest.descendingFormat" :sort-by.sync="dataRequest.sortByFormat"
            :page.sync="dataRequest.page" :items-per-page.sync="dataRequest.rowsPerPage" @update:page="updatePage"
            @update:items-per-page="updatePage" @update:sort-desc="updatePage" :loading="loadingGG"
            mobile-breakpoint="0">
            <template v-slot:[`item`]="{ item }">
              <tr>
                <td>
                  {{
                      detailData
                        ? detailData.indexOf(item) +
                        (dataRequest.page - 1) * dataRequest.rowsPerPage +
                        1
                        : "loading ..."
                  }}
                </td>
                <td>{{ item.date }}</td>
                <td>{{ item.user_name }}</td>
                <td>{{ item.public_ip }}</td>
                <td>{{ item.fight_no }}</td>
                <td :class="item.bet_type">
                  {{ item.bet_type }}
                </td>
                <td class="amount-bet-color">
                  {{
                      dataRequest && dataRequest.currency_type == 2
                        ? currencyFormatKm(item.amount)
                        : dataRequest.currency_type &&
                          dataRequest.currency_type != 2
                          ? currencyFormatEn(item.amount)
                          : "Loading ..."
                  }}
                </td>
                <td class="font-weight-bold">
                  {{ currencyFormatEn(item.payout) }}
                </td>
              </tr>
            </template>
            <template slot="body.append">
              <td :colspan="6" class="text-xs-center pl-4 py-2 ">
                {{ $t("title.total") }}
              </td>
              <td class="text-xs-center total-style pl-4  py-2">
                {{
    dataRequest &&
      dataRequest.currency_type == 2 &&
      detailDataCount
      ? currencyFormatKm(detailDataCount.amount)
      : dataRequest.currency_type &&
        dataRequest.currency_type != 2 &&
        detailDataCount
        ? currencyFormatEn(detailDataCount.amount)
        : "Loading ..."
                }}
              </td>
            </template>
            <template slot="body.append">
            <tr>

              <td style="text-align:center " colspan="9" v-if="loadingGG">
                <div class="lds-roller my-3">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </td>
            </tr>
          </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";

export default {
  components: {},

  data: () => ({
    v_search: null,
    getLoading: false,
    headers: [
      {
        text: "ល.រ",
        align: "start",
        value: "no",
      },
      { text: "កាលបរិច្ឆេទ", value: "date" },
      { text: "ឈ្មោះ", value: "user_name" },
      { text: "Public Ip", value: "public_ip" },
      { text: "ប្រកួតលេខ", value: "fight_no" },
      { text: "ប្រភេទចាក់", value: "co_type_of_betting.name" },
      { text: "ប្រាក់ចាក់", value: "amount" },
      { text: "Payout", value: "payout" },
    ],
    headers1: [
      {
        text: "Meron",
        value: "Meron_Ch1",
        format: (v) => v && Number(v).toFixed(2),
        align: "left",
      },
      {
        text: "Wala",
        value: "Wala_Ch1",
        format: (v) => v && Number(v).toFixed(2),
        align: "left",
      },
    ],
    headers2: [
      {
        text: "Meron",
        value: "Meron_Ch2",
        format: (v) => v && Number(v).toFixed(2),
        align: "left",
      },
      {
        text: "Wala",
        value: "Wala_Ch2",
        format: (v) => v && Number(v).toFixed(2),
        align: "left",
      },
    ],
    headers3: [
      {
        text: "Meron",
        value: "Meron_Ch3",
        format: (v) => v && Number(v).toFixed(2),
        align: "left",
      },
      {
        text: "Wala",
        value: "Wala_Ch3",
        format: (v) => v && Number(v).toFixed(2),
        align: "left",
      },
    ],
    headers4: [
      {
        text: "Meron",
        value: "Meron_Ch4",
      },
      {
        text: "Wala",
        value: "Wala_Ch4",
      },
    ],
    StatementObj: [
      {
        currency: "$",
        num1_1: 0, 
        num2_1: 0, 
        num3_1: 0, 
        num4_1: 0, 
        num5_1: 0, 
        small_1: 0, 
        large_1: 0, 
      },
      {
        currency: "៛",
        num1_1: 0, 
        num2_1: 0, 
        num3_1: 0, 
        num4_1: 0, 
        num5_1: 0, 
        small_1: 0, 
        large_1: 0,  
      },
    ],
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    dataRequest: {
      descendingFormat: [true],
      sortByFormat: ["_id._id"],
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: [
        "user_name",
        "public_ip"
      ],
      search: "",
      fight_no: null,
    },
    dialog: false,
    loading: false,
    loader: false,
    detailData: [],
    detailDataCount: [],
    ch: [],
    channel_type: null,
    loadingGG: false,
    reloadingDetail :false,
  }),
  watch: {
    channel_1: function (newV) {
      if (newV) this.getFirstData();
    },
    StatementObj: function () { },
    ch: function () { },
    userp: function () { }
  },
  computed: {
    ...mapGetters("$_modules", { user: "getUser" }),
    ...mapGetters("$_liveBettingEE7777", {
      channel_1: "getChannel_1",
    }),
  },
  async created() {
    await this.getData();
  },
  methods: {
    processTableHeaders(headers) {
      const nested = !!headers.some((h) => h.children);

      if (nested) {
        let children = [];
        const h = headers.map((h) => {
          if (h.children && h.children.length > 0) {
            children.push(...h.children);

            return {
              rowspan: 1,
              colspan: h.children.length,
              text: h.text,
              align: h.align,
            };
          }
          return {
            rowspan: 2,
            colspan: 1,
            text: h.text,
            align: h.align,
          };
        });

        return {
          children: children,
          parents: h,
        };
      }
      return {
        parents: headers,
      };
    },
    moment,
    currencyFormatEn(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    currencyFormatKm(num) {
      num = parseFloat(num);
      return num.toLocaleString("en");
    },
    _goToDetail(selectedData, channel ) {
      this.dataRequest.search = "";
      this.v_search = "";
      this.detailData = [];
      this.detailDataCount = [];
      this.dataRequest.bet_type = selectedData.bet_type;
      this.dataRequest.currency_type = selectedData.currency_type;
      // this.dataRequest.channel_type = channel.channel_type;
      this.dataRequest.fight_no = channel.fight_no;
      this.dataRequest.group_no = channel.group_no;
      this.dialog = true;
      this.loadDetailData();
    },
    async loadDetailData() {
      this.loadingGG = true;
      this.dataRequest.sortByFormat.length < 1
        ? (this.dataRequest.sortBy = "_id")
        : (this.dataRequest.sortBy = this.dataRequest.sortByFormat[0]);
      this.dataRequest.descendingFormat.length > 0
        ? (this.dataRequest.descending = this.dataRequest.descendingFormat[0])
        : (this.dataRequest.descending = true);
      await this.$request
        .post({
          url: "getViewOutStandingLottoDetail",
          data: this.dataRequest,
        })
        .then((res) => {
          if (res.data.code) {
            this.detailData = res.data.data.object;
            this.detailDataCount = res.data.data.objectCount;
          } 
        });
        this.loadingGG = false;
    },
    async getData() {
      try {
        this.loader = true;
        await this.fetchListStatement();
        this.loader = false;
      } catch (error) {
        // console.log(error)
      }


    },
    getFirstData() {
      if (this.channel_1) {
        // currcency usd
        this.StatementObj[0].num1_1 = {
          amount: this.channel_1.balance_usd[0].amount,
          bet_type: this.channel_1.balance_usd[0].betting_type,
          currency_type: this.channel_1.balance_usd[0].currency_type,
        };
        this.StatementObj[0].num2_1 = {
          amount: this.channel_1.balance_usd[1].amount,
          bet_type: this.channel_1.balance_usd[1].betting_type,
          currency_type: this.channel_1.balance_usd[1].currency_type,
        };
        this.StatementObj[0].num3_1 = {
          amount: this.channel_1.balance_usd[2].amount,
          bet_type: this.channel_1.balance_usd[2].betting_type,
          currency_type: this.channel_1.balance_usd[2].currency_type,
        };
        this.StatementObj[0].num4_1 = {
          amount: this.channel_1.balance_usd[3].amount,
          bet_type: this.channel_1.balance_usd[3].betting_type,
          currency_type: this.channel_1.balance_usd[3].currency_type,
        };
        this.StatementObj[0].num5_1 = {
          amount: this.channel_1.balance_usd[4].amount,
          bet_type: this.channel_1.balance_usd[4].betting_type,
          currency_type: this.channel_1.balance_usd[4].currency_type,
        };
        this.StatementObj[0].small_1 = {
          amount: this.channel_1.balance_usd[5].amount,
          bet_type: this.channel_1.balance_usd[5].betting_type,
          currency_type: this.channel_1.balance_usd[5].currency_type,
        };
        this.StatementObj[0].large_1 = {
          amount: this.channel_1.balance_usd[6].amount,
          bet_type: this.channel_1.balance_usd[6].betting_type,
          currency_type: this.channel_1.balance_usd[6].currency_type,
        };

        // currcency khmer
        this.StatementObj[1].num1_1 = {
          amount: this.channel_1.balance_kh[0].amount,
          bet_type: this.channel_1.balance_kh[0].betting_type,
          currency_type: this.channel_1.balance_kh[0].currency_type,
        };
        this.StatementObj[1].num2_1 = {
          amount: this.channel_1.balance_kh[1].amount,
          bet_type: this.channel_1.balance_kh[1].betting_type,
          currency_type: this.channel_1.balance_kh[1].currency_type,
        };
        this.StatementObj[1].num3_1 = {
          amount: this.channel_1.balance_kh[2].amount,
          bet_type: this.channel_1.balance_kh[2].betting_type,
          currency_type: this.channel_1.balance_kh[2].currency_type,
        };
        this.StatementObj[1].num4_1 = {
          amount: this.channel_1.balance_kh[3].amount,
          bet_type: this.channel_1.balance_kh[3].betting_type,
          currency_type: this.channel_1.balance_kh[3].currency_type,
        };
        this.StatementObj[1].num5_1 = {
          amount: this.channel_1.balance_kh[4].amount,
          bet_type: this.channel_1.balance_kh[4].betting_type,
          currency_type: this.channel_1.balance_kh[4].currency_type,
        };
        this.StatementObj[1].small_1 = {
          amount: this.channel_1.balance_kh[5].amount,
          bet_type: this.channel_1.balance_kh[5].betting_type,
          currency_type: this.channel_1.balance_kh[5].currency_type,
        };
        this.StatementObj[1].large_1 = {
          amount: this.channel_1.balance_kh[6].amount,
          bet_type: this.channel_1.balance_kh[6].betting_type,
          currency_type: this.channel_1.balance_kh[6].currency_type,
        };
      }

    },
    searchq() {
      this.dataRequest.search = this.v_search;
      this.updatePage();
    },
    updatePage() {
      this.loadingGG = true;
      this.loadDetailData();
      this.loadingGG = false;
    },
    searchData() {
      this.getData()
    },

    ...mapMutations("$_liveBettingEE7777", ["CHANNEL_1"]),
    ...mapActions("$_liveBettingEE7777", ["fetchListStatement"]),
  },
};
</script>

<style lang="scss" scoped>
.fino {
  color: #6699cc;
  font-size: 24px;
  font-weight: bold;
  margin-left: 10px;
}

.cm-title {
  color: black;
  font-weight: 900;
  font-size: 32px;
  position: absolute;
  top: 105px;
  left: 50%;
  margin-left: -90px;
}

.row-pointer:hover {
  cursor: pointer;
  background-color: honeydew;
}

.users {
  width: 50%;
  float: left;

  h3 {
    font-size: 17px;
  }
}

table {
  font-family: "khmer mef1" !important;
}

.amount-bet-color {
  color: goldenrod !important;
}

.wala {
  color: rgb(57, 175, 214);
  font-weight: bold;
  text-transform:uppercase
}
.win-color {
  color: rgb(57, 175, 214);
  font-weight: bold;
  text-transform:uppercase
}

.meron {
  color: #ba2d4a;
  font-weight: bold;
  text-transform:uppercase
}
.lose-color {
  color: #ba2d4a;
  font-weight: bold;
  text-transform:uppercase
}

.tie {
  color: green;
  font-weight: bold;
  text-transform:uppercase
}

.tab-filter {
  display: flex;
  flex-direction: row;
}

.row {
  display: flex;
  flex-direction: row;
}

.between {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.w-full {
  width: 100%;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #cef;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.tie-color {
  color: green;
}

.channel_number_style {
  font-size: 1.8rem;
  color: white;
  font-weight: bold;
  padding: 5px 14px;
  margin-left: 5px;
  background-color: #6699cc;
  border-radius: 100px;
}

.table-style {
  font-family: 'khmer mef1';
  border: 1px solid black;
}

.detail_ch_number {
  margin-left: 10px;
  color: #6699cc;
  font-size: 24px;
  font-weight: bold
}
.num-1{
  color: #8aa000;
  font-weight: bold;
  text-transform:uppercase
}
.num-2{
  color: #ff5f30;
  font-weight: bold;
  text-transform:uppercase
}
.num-3{
  color: #fe9808;
  font-weight: bold;
  text-transform:uppercase
}
.num-4{
  color: #715b06;
  font-weight: bold;
  text-transform:uppercase
}
.num-5{
  color: #548c2b;
  font-weight: bold;
  text-transform:uppercase
}
.num-6{
  color: #66ab44;
  font-weight: bold;
  text-transform:uppercase
}
.num-7{
  color: #FF00FF;
  font-weight: bold;
  text-transform:uppercase
}
.num-8{
  color: #fbb700;
  font-weight: bold;
  text-transform:uppercase
}
.num-9{
  color: #0000FF;
  font-weight: bold;
  text-transform:uppercase
}
.cancel{
  color: #CBCEC9;
  font-weight: bold;
  text-transform:uppercase
}
</style>
