<template>

  <v-card >
    <div style="width:100%">
      <v-spacer></v-spacer>
      <v-alert type="success" :value="alertS"  style="width:30%">
        Password Successfully Change
    </v-alert>
    </div>
    
    <v-alert type="danger" :value="alertE" style="width:30%; text-align:center " class="text-right">
        Something When wrong, fail to change password
    </v-alert>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card-text>
        <v-row>
          <v-col cols-12>
            <v-text-field
                v-model="password"
                :counter="10"
                :rules="rules"
                :type="show ? 'text' : 'password'"
                label="Change Password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show = !show"
                autocomplete="on"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-btn text  @click="save">
        SAVE
      </v-btn>
    </v-form>

  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data: () => {
    return {
      show: false,
      valid: true,
      rules: [
        v => !!v || 'Password is required',
        v => (v && v.length <= 10 && v.length >= 6) || 'Password must be less than 10 and grater 6'
      ],
      password:null,
      alertS : false,
      alertE : false
    }
  },
  computed: {
   ...mapGetters("$_modules", {darkmode: "getDarkMode", userp: "getUser"}),
  },
   methods: {
     save() {
      let data = {'password': this.password};
      let user_id = this.userp._id
      this.$request
        .put({
          url: 'users/changePassword/'+ user_id,
          data
        })
        .then(res => {
         if (res.data.code) {
           this.alertS = true
            setTimeout(()=> {
            this.alertS = false;
          }, 1500);
          } else {
            this.alertE = true;
            setTimeout(()=> {
            this.alertE = false;
          }, 1500);}
        });
    }
    
  
   }}
</script>

<style lang="scss" scoped>

</style>
