import statementMasterUSDM from '@/modules/machineGameReport/statementMasterUSD';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/statementMasterUSDM',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: statementMasterUSDM,
				name: 'statementMasterUSDM'
			}
		]
	}
];

export default routes;
