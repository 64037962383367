<template>
    <v-app id="inspire" class="parent-app">
      <v-navigation-drawer v-model="drawer" app clipped style="font-family: 'khmer mef1'" class="el1">
        <v-list flat>
          <v-subheader style=""><b>Welcome</b></v-subheader>
        </v-list>
        <v-list class="side-menu">
          <v-list-group v-for="item in menu" :key="item.name" :prepend-icon="item.action" no-action mandatory
            color="black">
            <template v-slot:activator>
              <v-list-item-content> 
                <v-list-item-title v-text="item.descr"></v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="child in item.items" :key="child.name">
              <v-list-item-content  style="padding:0px!important">
                <v-list-item-title >
                  <v-list-item :to="child.url" v-if="!child.items" style="padding:0px!important">
                    <router-link :to="child.url" >
                    <a style="padding:15px!important">
                      <v-icon>{{child.icon}}</v-icon> {{ child.descr }}
                    </a>
                  </router-link>
                  </v-list-item>
                  <v-list class="side-menu" v-else style="padding:0px!important">
                    <v-list-group :key="child.name" no-action style="padding:0px!important">
                      <template v-slot:activator>
                        <v-list-item-content style="padding:0px!important; margin-left:-16px">
                          <v-list-item-title v-text="child.descr" style="padding:0px!important; "></v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <v-list-item v-for="sublink in child.items" :to="sublink.url" :key="sublink.name" style="padding:0px!important">
                        <v-list-item-title style="padding:0px!important">
                          <router-link :to="sublink.url">
                            <a style="padding:15px!important">
                              <v-icon>{{sublink.icon}}</v-icon> {{ sublink.descr }}
                            </a>
                          </router-link>
                        </v-list-item-title>
                      </v-list-item>
  
                    </v-list-group>
                  </v-list>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-navigation-drawer>
  
      <v-app-bar app clipped-left dense>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="mr-5"></v-app-bar-nav-icon>
        <v-btn text @click="changeLocale" style="font-family: 'khmer mef1' !important" class="welcome-title lang-btn">
          {{ $t("lang." + $i18n.locale) }}
        </v-btn>
  
        <template>
          <v-menu bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on" style="font-family: 'khmer mef1' !important" close-delay="mr-5"
                class="welcome-title">
                <span style="font-weight: bold; text-transform: uppercase !important"
                  v-if="user && user.role_id != '612a3fcc1a1fcead8871e822'">
                  {{ $t("title.welcome")
                  }}{{
      user && user.currency_type === 1
        ? " " +
        user.user_name +
        "(" +
        currencyFormatUsd(user.balance) +
        " $ )"
        : user && user.currency_type === 2
          ? " " +
          user.user_name +
          "(" +
          currencyFormatKm(user.balance) +
          " ៛ )"
          : user && user.currency_type === 3
            ? " " +
            user.user_name +
            "(" +
            currencyFormatUsd(user.balance) +
            " Baht )"
            : user.role_id == "612a40c71a1fcead8871e835"
              ? " " + user.user_name + " "
              : user.user_name
  }}</span>
                <span style="font-weight: bold; text-transform: uppercase !important"
                  v-if="user && user.role_id == '612a3fcc1a1fcead8871e822'">
                  {{ $t("title.welcome")
                  }}{{
      " " +
      user.user_name +
      " (" +
      currencyFormatUsd(user.companyBalances[0].balance) +
      "$, " +
      currencyFormatKm(user.companyBalances[1].balance) +
      "៛, " +
      currencyFormatUsd(user.companyBalances[2].balance) +
      "฿" +
      " )"
  }}</span>
              </v-btn>
            </template>
            <v-card>
              <v-list-item-content class="justify-center">
                <div class="mx-auto text-center" v-if="user">
                  <span class="red darken-3 h4 font-weight-bold text-white px-3">{{
                      user && !user.enable
                        ? "You has been suspend please contact your upline"
                        : ""
                  }}</span>
                  <h3 style="margin-top: 5px !important; font-family: 'khmer mef1'">
                    {{ $t("title.welcome") }}
                    <span style="
                        color: blue;
                        font-weight: bold;
                        text-transform: uppercase !important;
                      ">{{ user ? user.user_name : "" }}</span>
                  </h3>
                  <p class="text-caption mt-2" style="
                      margin-bottom: 5px !important;
                      font-family: 'khmer mef1' !important;
                    ">
                    {{ $t("title.account_balance") }}:
                    <span style="color: green; font-weight: bold; font-size: 16px">
                      {{ currencyFormat(user.balance) }}</span>
                  </p>
                  <p class="text-caption" style="font-family: 'khmer mef1' !important">
                    {{ $t("title.user_role") }}: {{ user.role_name }}
                  </p>
                  <v-divider class="my-2"></v-divider>
                  <v-btn depressed rounded small text to="change-password" style="font-family: 'khmer mef1'">
                    <v-icon>mdi-account-lock</v-icon>
                    {{ $t("button.change_password") }}
                  </v-btn>
  
                  <v-btn depressed rounded small text @click="onLogout" style="font-family: 'khmer mef1'">
                    <v-icon>mdi-lock-open-variant</v-icon>
                    {{ $t("button.logout") }}
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-card>
          </v-menu>
        </template>
        <v-spacer></v-spacer>
        <span class="red darken-3 h4 font-weight-bold text-white px-3">{{
            user && !user.enable
              ? "You has been suspend please contact your upline"
              : ""
        }}</span>
        <v-spacer></v-spacer>
        <!--        <template v-if="user && (user.role_id=='612a40c71a1fcead8871e835' || user.role_id=='611532b19c6e77ad0fd50c1c') ">-->
        <!--          <v-row class="d-none d-sm-block" style="max-width:300px; margin-right:20px">-->
        <!--          <v-select-->
        <!--            v-if="user"-->
        <!--            v-model="channelType"-->
        <!--            :items="items"-->
        <!--            item-value="id" item-text="name"-->
        <!--            label="Channel #  "-->
        <!--            class="channel-type   "-->
        <!--            style="min-width:100%"-->
        <!--            :disabled="user.role_id=='612a40c71a1fcead8871e835'"-->
        <!--            @input="selectChannelType(channelType)"-->
        <!--            solo-->
        <!--          ><template v-slot:prepend>-->
        <!--            <v-icon color="green darken-2">mdi-television</v-icon>-->
        <!--          </template>-->
        <!--          </v-select>-->
  
        <!--          </v-row>-->
  
        <!--        </template>-->
        <template>
          <!-- <a href="https://t.me/suportsbc2888" class="btn btn-info" style="font-weight: bold; color: white; background-color: chocolate;padding: 10px;"
          v-if="user && (user.role_id == '61829dc7e64b1b17688326d5' || user.role_id == '61829dd1e64b1b17688326df')">
            <v-icon style="font-size: 30px; padding-right: 3px;color: white;">mdi-facebook-messenger</v-icon>
            SUPPORT 24/7</a> -->
          <v-btn depressed rounded small text @click="onLogout" class="welcome-title" style="font-family: 'khmer mef1'">
            <v-icon style="font-size: 11px; padding-right: 3px">mdi-lock-open-variant</v-icon>
            {{ $t("button.logout") }}
          </v-btn>
        </template>
      </v-app-bar>
      <v-main>
        <v-container fluid class="min-h-full">
          <v-row>
            <v-col>
              <router-view></router-view>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
  </template>
  
  <script>
  import { mapGetters, mapActions } from "vuex";
  
  export default {
    data: () => ({
      drawer: null,
      selectedItem: null,
      lang: ["en", "kh"],
      noTree: [{ text: "ទំព័រដើម", icon: "mdi-home", action: "dashboard" }],
      items: [
        { name: "ប៉ុស្តិ៌ 1", id: 1 },
        { name: "ប៉ុស្តិ៌ 2", id: 2 },
        { name: "ប៉ុស្តិ៌ 3", id: 3 },
        { name: "ប៉ុស្តិ៌ 4", id: 4 },
      ],
      channelType: { name: "1", id: 1 },
    }),
    beforeMount() {
      this.$nextTick(() => {
        if (
          this.$cookies.get("local") !== undefined &&
          this.$cookies.get("local") !== null
        ) {
          this.$i18n.locale = this.$cookies.get("local");
        } else {
          this.$i18n.locale = "kh";
        }
      });
    },
    computed: {
      ...mapGetters("$_modules", {
        menu: "getMenu",
        user: "getUser",
      }),
    },
    created() {
      this.connectSocket();
    },
    watch: {
      user() { },
    },
    async mounted() {
      if (this.$cookies.get("channel-type")) {
        this.channelType = {
          name: this.$cookies.get("channel-type"),
          id: parseInt(this.$cookies.get("channel-type")),
        };
      }
      if (this.$cookies.get("token")) {
        await this.fetchUser();
        await this.fetchUserAccessMenu();
      }
  
      if (this.user && this.user.role_id == "612a40c71a1fcead8871e835") {
        this.$cookies.set("channel-type", this.user.channel_post);
        this.channelType = {
          name: this.$cookies.get("channel-type"),
          id: parseInt(this.$cookies.get("channel-type")),
        };
      }
    },
    methods: {
      onLogout() {
        this.$cookies.remove("token");
        this.$router.push("/login");
      },
      changeLocale() {
        if (this.$i18n.locale === "kh") {
          this.$cookies.set("local", "en");
          this.$i18n.locale = "en";
        } else {
          this.$cookies.set("local", "kh");
          this.$i18n.locale = "kh";
        }
      },
      selectChannelType(channelType) {
        this.$cookies.set("channel-type", channelType);
        this.channelType = {
          name: this.$cookies.get("channel-type"),
          id: parseInt(this.$cookies.get("channel-type")),
        };
        if (
          parseInt(this.$cookies.get("channel-type")) == 2 ||
          parseInt(this.$cookies.get("channel-type")) == 3 ||
          parseInt(this.$cookies.get("channel-type")) == 4
        )
          this.$connectSocket(
            this.$cookies.get("token"),
            this.$cookies.get("channel-type")
          );
        this.$router.go();
      },
      currencyFormat(num) {
        num = parseFloat(num);
        // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        return parseInt(num).toLocaleString("en");
      },
      currencyFormatKm(num) {
        num = parseFloat(num);
        // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        return parseInt(num).toLocaleString("en");
      },
      currencyFormatUsd(num) {
        num = parseFloat(num);
        return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      },
      connectSocket() {
        this.$connectSocket(
          this.$cookies.get("user_role"),
          this.$cookies.get("token")
        );
      },
      ...mapActions("$_modules", [
        "fecthDarkMode",
        "fetchUserAccessMenu",
        "fetchUser",
      ]),
    },
  };
  </script>
  
  <style>
  .v-list-item .v-list-item__subtitle,
  .v-list-item .v-list-item__title {
    line-height: 1.9 !important;
  }
  
  .v-item-group theme--light,
  .v-list-item-group {
    height: 38px !important;
  }
  
  .v-application--is-ltr,
  .v-list-item__icon:first-child {
    margin-right: 5px !important;
  }
  
  .v-menu__content theme--light,
  .v-menu__content--fixed,
  .menuable__content__active {
    max-width: 300px !important;
    width: 300px !important;
  }
  
  .v-list-item__content {
    overflow: inherit !important;
  }
  
  .v-application--is-ltr .v-list-group--no-action>.v-list-group__items>.v-list-item {
    padding-left: 50px !important;
  }
  
  .channel-type {
    margin-top: 19px !important;
    max-width: 15% !important;
    min-height: 45px;
    padding: 0;
    margin-right: 20px !important;
  }
  
  @media (max-width: 768px) {
    .welcome-title {
      font-size: 11px !important;
      padding: 0px !important;
    }
  
    .v-btn:not(.v-btn--round).v-size--default {
      height: 36px;
      min-width: 30px !important;
      padding: 0px !important;
      margin-right: 10px !important;
    }
  }
  
  .parent-app .el1 {
    background-image: linear-gradient(70deg, deepskyblue -140%, white 55%);
  }
  
  .parent-app>.v-application--wrap>header {
    background: rgb(21, 76, 121);
    background: linear-gradient(90deg, rgba(21, 76, 121, 1) 0%, rgba(127, 158, 184, 1) 48%, rgba(21, 76, 121, 1) 100%);
  }
  
  .parent-app>.v-application--wrap>header>div>button:nth-child(2)>span,
  .parent-app>.v-application--wrap>header>div>button:nth-child(4)>span>span {
    color: white;
  }
  
  .parent-app>.v-application--wrap>header>div>button:last-child>span {
    color: white;
    font-weight: bold;
  }
  
  .parent-app>.v-application--wrap>header>div>button:first-child>span {
    color: white;
    font-weight: bold;
  }
  
  .parent-app .v-navigation-drawer__content .v-subheader {
    width: 100%;
    justify-content: center;
    text-transform: uppercase;
    font-weight: bolder;
    background: #39688e;
    color: white;
    margin-top: -10px;
  }
  
  .parent-app .v-data-table__wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .parent-app .tbl-style table,
  .parent-app table {
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
  }
  
  .parent-app .tbl-style table tr,
  .parent-app table tr {
    background-image: linear-gradient(180deg, whitesmoke, white, whitesmoke);
  }
  
  .parent-app .side-menu .v-list-group:hover .v-list-group__header:not(.v-list-item--active) {
    /* border-left: 1px solid red;
    border-right: 1px solid red;
    background: green; */
    transition: 100ms;
  }
  
  .parent-app .side-menu .v-list-group .v-list-group__header:not(.v-list-item--active) {
    border-left: 0px solid gray;
    border-top: 0px solid gray;
    transition: 100ms;
  }
  
  .parent-app .side-menu .v-list-group .v-list-group__items>a:hover {
    border-left: 1px solid deepskyblue;
    border-right: 1px solid deepskyblue;
    /* border-top: 1px solid lightskyblue;
              border-bottom: 1px solid lightskyblue; */
    background: #76b5c5;
    transition: 100ms;
  }
  
  .parent-app .side-menu .v-list-group .v-list-group__items>a {
    border-left: 0px solid gray;
    /* border-top: 0px solid gray; */
    transition: 100ms;
  }
  </style>
  