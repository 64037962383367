/* eslint-disable no-unused-vars */
import Request from '@/services/axios'

let request = new Request;

const fetchListMenus = async({ commit }, data) => {
    commit('UPDATE_LOADING', true)
    data.sortByFormat.length < 1 ? data.sortBy = '_id' : data.sortBy = data.sortByFormat[0]
    data.descendingFormat.length > 0 ? data.descending = data.descendingFormat[0] : data.descending = true
    const response = await request.post({
        url: 'menus/getAll',
        data
    });
    if (response.data.code) {
        commit('UPDATE_MENUS', response.data.data);
        commit('UPDATE_LOADING', false)
    }
};

const createMenu = async({ commit }, { vm, data }) => {
    const response = await request.post({
        url: 'menus',
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`Successfully created menu.`)
        commit('UPDATE_POPUP', false)
    } else vm.$toastr.e(`Fail created menu.`)
};

const updateMenu = async({ commit }, { vm, data }) => {
    const response = await request.put({
        url: 'menus/' + data._id,
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`Successfully updated menu.`)
        commit('UPDATE_POPUP', false)
    } else vm.$toastr.e(`Fail updated menu.`)
};

const deleteMenu = async({ commit }, { vm, data }) => {
    const response = await request.post({
        url: 'menus/deleteMany',
        data
    });
    if (response.data.code) {
        commit('DELETE_ITEMS', [])
        vm.$toastr.s(`successfully deleted menu.`)
    } else vm.$toastr.e(`'failed delete menu.`)
};

const fetchPopUp = async({ commit }, payload) => {
    commit('UPDATE_POPUP', payload);
}

const fetchCurrentMenu = async({ commit }, payload) => {
    commit('UPDATE_CURRENT_MENU', payload);
};

const fetchParentMenu = async({ commit }) => {
    const response = await request.get({
        url: 'menus/getAllMenu',
    });
    if (response.data.code) {
        commit('UPDATE_PARENT_MENU', response.data.data);
    }
}

const fetchTreeMenu = async({ commit }) => {
    const response = await request.get({
        url: 'menus/getSelectTreeMenu',
    });
    if (response.data.code) {
        commit('UPDATE_TREE_MENU', response.data.data.object);
    }
}

export default {
    fetchListMenus,
    fetchPopUp,
    createMenu,
    updateMenu,
    deleteMenu,
    fetchCurrentMenu,
    fetchParentMenu,
    fetchTreeMenu
};
