import Vue from 'vue';
import axios from 'axios';
import { handleError } from './hedleError.js';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
class Request {
    async post({ url, data }) {
        return this._request({ url, method: 'post', data });
    }

    async get({ url, params }) {
        return this._request({ url, method: 'get', params });
    }

    async put({ url, data }) {
        return this._request({ url, method: 'put', data });
    }

    async delete({ url, data }) {
        return this._request({ url, method: 'delete', data });
    }

    async _request({ url, method, params = null, data = null, headers }) {
        if (!headers) {
            headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'channel-type': 'application/json'
            };
        }

        const token = Vue.$cookies.get('token');
        const uuid = Vue.$cookies.get('uuid');
        const channel_type = Vue.$cookies.get('channel-type');
        const currecy_type = Vue.$cookies.get('currency-type');

        if (token) {
            headers['Authorization'] = token;
        } else {
            headers['Authorization'] = null;
        }
        if (channel_type) {
            headers['channel-type'] = channel_type;
        }
        if (currecy_type) {
            headers['currency-type'] = currecy_type;
        }
        if (uuid) headers['uuid'] = uuid;
        try {
            const response = await axios({
                url,
                method,
                headers,
                data,
                params
            });
            return response;
        } catch (error) {
            const errorResponse = Vue.$cookies.get('error');
            if (errorResponse === 'false' || errorResponse === null) {
                Vue.$cookies.set('error', 'true');
                if (
                    !error.status ||
                    !error.response ||
                    (error.response && typeof error.response.status === 'undefined')
                ) {
                    const status = error.response ? error.response.status : error.response;
                    handleError(status);
                } else {
                    handleError(error.response.status);
                }
            }
            setTimeout(() => {
                Vue.$cookies.remove('error');
            }, 3000);
            throw error;
        }
    }
}

export default Request;
