// update data over statement

const CHANNEL = (state, data) => {
    state.channel = data;
};

// const CHANNEL_2 = (state, data) => {
//     state.channel_2 = data;
// };

// const CHANNEL_3 = (state, data) => {
//     state.channel_3 = data;
// };

// const CHANNEL_4 = (state, data) => {
//     state.channel_4 = data;
// };

const UPDATE_LOADING = (state, data) => {
    state.loading = data
}


export default {
    CHANNEL,
    // CHANNEL_2,
    // CHANNEL_3,
    // CHANNEL_4,
    UPDATE_LOADING
};