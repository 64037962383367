import statementCompanyKhCock from '@/modules/cockFightReport/statementCompanyKh';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/statementCompanyKhCock',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: statementCompanyKhCock,
				name: 'statementCompanyKhCock'
			}
		]
	}
];

export default routes;
