const headers = [
    // { text: 'សកម្មភាព', align: 'start', value: 'actions', sortable: false },
    {
        text: 'ល.រ',
        align: 'start',
        value: 'no',
    },
    { text: 'អ្នកប្រើប្រាស់', value: '_id.company' },
    { text: 'គណនី', value: '_id.company_balance' },
    { text: 'សរុបប្រាក់ភ្នាល់', value: 'amount' },
    { text: 'សរុបឈ្នះ/ចាញ់', value: 'amount_win' },
];

export default headers;