export default {
    permission: 'Permission',
    role: 'Role',
    description: 'Description',
    menu_name: 'Menu Name',
    icon: 'Icon',
    order: 'Order',
    active: 'Active',
    actions: 'Actions',
    balance: 'Balance',
    created_at: 'Created At',
    bank_account: 'Bank Account',
    user_name: 'Username',
    deposit_withdraw: 'Deposit/Withdraw',
    address :"Address Ip",
    no :"No",
    suspend:"Suspend",
    public_ip:"Public Ip",
    name: "Name",
    sub_link: "Sub Link",
    app_id: "App Id",
    is_enable: "Is Enable",
    sync_date: "Sync Date",
    riel: "Riel",
    dollar: "Dollar",
    baht: "Baht",
    front_player_link: "Front player link",
    api_player_link: "API player link",
    api_admin_link: "API admin link",
    userName: "User Name",
    moduleName: "Module Name",
    status: "Status",
    background_icon: "Background icon",
    tranc_type: "Transaction Type",
    amount: "Amount",
    old_balance: "Old Balance",
    new_balance: "New Balance",
    module_bet_id: "Module Bet ID",
    channel_type: "Channel Type",
    sync_module: "Sync Module",
    parentName: "Parent Name",
    withdrawAmount :"Withdraw Amount",
    depositAmount :"Deposit Amount",
    oldBalance :"Old Balance",
    newBalance :"New Balance",
    date:"Datetime",
    module_name: "កម្មវិធី",
    company: "Company",
    senior: "Senior",
    master: "Master",
    agent: "Agent",
    reportDW : "Report Deposit/Widthdrow",
    reportBetting : "Report Betting",
    is_withdraw : "Is Withdraw",
};
