<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="getPermission && getPermission.object ? getPermission.object : []"
        :footer-props="footerProps"
        :loading="!getPermission"
        class="elevation-1"
        
        item-key="_id"
        :server-items-length="getPermission ? getPermission.objectCount : null"
        style="font-family: 'khmer mef1'"
        :page.sync="data.page"
        :items-per-page.sync="data.rowsPerPage"
        :sort-desc.sync="data.descendingFormat"
        :sort-by.sync="data.sortByFormat"
        @update:page="getData"
        @update:items-per-page="getData"
        @update:sort-desc="getData"
        show-select
        :value="getDeleteItems"
        @input="itemDelete"
        mobile-breakpoint="0"
    >

      <template v-slot:top>
        <Header :darkmode="darkmode"
                :params="data"
                :dataDelete="getDeleteItems"
                :user-permission="userAccessPermission"
        />
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <ActionComponents :darkmode="darkmode"
                          :item="item"
                          :dataDelete="getDeleteItems"
                          :params="data"
                          :user-permission="userAccessPermission"
        />
      </template>
    </v-data-table>
    <Dialog :darkmode="darkmode"
            :loading="loading"
            :permission="getCurrentPermission"
            :dialog="getPopUp"
            :is-validate.sync="valid"
            v-on:submit="handleSubmit"
            v-on:onCloseDialog="fetchPopUp(false)"
    />
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
  components: {
    ActionComponents: () => import("./_components/action"),
    Header: () => import("./_components/header.vue"),
    Dialog: () => import("./_components/dialog.vue"),
  },

  data: () => ({
    valid: true,
    search: null,
    footerProps: {
      'items-per-page-options': [10, 20, 50, 100, 200]
    },
    data: {
      descendingFormat: [true],
      sortByFormat: ['_id'],
      descending: true,
      sortBy: '_id',
      page: 1,
      rowsPerPage: 10,
      fields: ['permission_name', 'descr', 'order', 'status', 'updatedAt'],
      search: ''
    },
    loading: false
  }),
  computed: {
    headers() {
      return [
        {text: this.$t('header.permission'), align: 'start', value: 'permission_name', sortable: false},
        {text: this.$t('header.description'), value: 'descr', sortable: false},
        {text: this.$t('header.order'), value: 'order', sortable: false},
        {text: this.$t('header.actions'), value: 'actions', sortable: false},
      ];
    },
    ...mapGetters("$_modules", {darkmode: "getDarkMode"}),
    ...mapGetters("$_permission", ["getPermission", "getPopUp", "getCurrentPermission", "getDeleteItems"]),
  },
  async created() {
    await this.fetchUserAccessPermission(this.$route.path)
    await this.getData()
  },

  methods: {
    async handleSubmit() {
      this.loading = true
      if (!this.getCurrentPermission._id) {
        await this.createPermission({vm: this, data: this.getCurrentPermission})
      } else {
        await this.updatePermission({vm: this, data: this.getCurrentPermission})
      }
      await this.getData()
      this.loading = false
    },

    async getData() {
      this.checkUserPermission('VIEW') ? await this.fetchListPermission(this.data) : []
    },

    itemDelete(e) {
      const items = e.map(function (e) {
        return {_id: e._id}
      })
      this.DELETE_ITEMS(items)
    },

    ...mapMutations("$_permission", ["DELETE_ITEMS", "UPDATE_PERMISSION"]),
    ...mapActions("$_permission", [
      "fetchListPermission",
      "createPermission",
      "fetchPopUp",
      "updatePermission",
    ]),
  },
  async beforeRouteLeave(to, from, next) {
    this.UPDATE_PERMISSION([])
    this.UPDATE_USER_ACCESS_PERMISSION([])
    next()
  },
}
</script>

<style lang="scss" scoped>

</style>
