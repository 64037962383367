const headers = [
    // { text: 'សកម្មភាព', align: 'start', value: 'actions', sortable: false },
    {
        text: 'ល.រ',
        align: 'start',
        value: 'no',
    },
    { text: 'អ្នកប្រើប្រាស់', value: '_id.player' },
    { text: 'ក្រុមទិ', value: '_id.group_no' },
    { text: 'គូរប្រកួត', value: '_id.fight_no' },
    { text: 'Shoe No', value: '_id.shoe_no' },
    { text: 'សរុបប្រាក់ភ្នាល់', value: 'amount' },
    { text: 'សរុបឈ្នះ/ចាញ់', value: 'amount_win' },
];

export default headers;