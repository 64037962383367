// update data over statement

const LIST_STATEMENT = (state, data) => {
    state.statement = data;
};

const COUNT_STATEMENT = (state, data) => {
    state.countStatement = data;
};

const UPDATE_LOADING = (state, data) => {
    state.loading = data
}
const LIST_MODULE = (state, data) => {
    let obj = {
        _id: null,
        name: "All"
    }
    data.unshift(obj);
    state.listModule = data
}

export default {
    LIST_STATEMENT,
    COUNT_STATEMENT,
    UPDATE_LOADING,
    LIST_MODULE
};