import winLoseKlakloukStatement from '@/modules/winLoseSNKStatement/klakloukStatement';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/winLoseKlakloukStatement',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: winLoseKlakloukStatement,
				name: 'winLoseKlakloukStatement'
			}
		]
	}
];

export default routes;
