import statementPlayerUSD from '@/modules/statementPlayerUSD';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/statementPlayerUSD',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: statementPlayerUSD,
				name: 'statementPlayerUSD'
			}
		]
	}
];

export default routes;
