const getChannel_1 = (state) => state.channel_1
const getChannel_2 = (state) => state.channel_2
const getChannel_3 = (state) => state.channel_3
const getChannel_4 = (state) => state.channel_4


const getLoading = (state) => state.loading

export default {
    getChannel_1,
    getChannel_2,
    getChannel_3,
    getChannel_4,
    getLoading
};