<template>
  <div>
    <!-- sort-by="user_name" -->
    <v-data-table
      :headers="headers"
      :items="getStatement ? getStatement.object : []"
      
      :server-items-length="getCountStatement.count"
      item-key="_id._id"
      class="elevation-1"
      style="font-family: 'khmer mef1'"
      :footer-props="footerProps"
      :page.sync="data.page"
      :items-per-page.sync="data.rowsPerPage"
      :sort-desc.sync="data.descendingFormat"
      :sort-by.sync="data.sortByFormat"
      @update:page="getData"
      @update:items-per-page="getData"
      @update:sort-desc="getData"
       :loading="getLoading"
      mobile-breakpoint="0"
    >
      <template v-slot:top>
        <Header
          :darkmode="darkmode"
          :params="data"
          :listModule="getModule"
          :user="user"
          :time="time"
          @child-checkbox="checkboxValue"
          v-on:child-checkbox="updateParent"
        />
        <div class="between w-full mb-3 mt-0 pr-3 ">
          <v-btn
            v-for="(item, i) in defaultFilterDates"
            small
            color="success"
            class="mx-4 mb-4"
            :key="i"
            @click="selectDate(item)"
          >
            <span style="color: white">{{ item.name }}</span>
          </v-btn>
        </div>
      </template>

      <template v-slot:[`item`]="{ item }">
        <tr class="to-detail" @click="_goToDetail(item.player_id,item.module_id,item.player)">
          <td>{{ getStatement.object.indexOf(item) + 1 }}</td>
          <td>{{ item.module_name }}</td>
          <td>{{ item.com }}</td>
          <td>{{ item.admin }}</td>
          <td>{{ item.master }}</td>
          <td>{{ item.agent }}</td>
          <td>{{ item.player }}</td>
          <td>{{ currencyFormat(item.player_balance) }} ៛ </td>
          <td class="to-detail" style="color: goldenrod;">{{ currencyFormat(item.amount) }} ៛ </td>
          <!-- <td :class="item.amount_win < 0 ? 'lose-color' : 'win-color'">{{ currencyFormat(item.amount_win)}} ៛ </td> -->
          <td :style="item.amount_win < 0 ? 'color:#BA2D4A': 'color:#39afd6'">{{ currencyFormat(item.amount_win)}} ៛</td>
          
        </tr>
      </template>
      <template slot="body.append">
        <tr>
          <td :colspan="8" class="text-xs-center pl-4 py-2 ">
            {{ $t("title.total") }}
          </td>
          <td class="text-xs-center total-style pl-4  py-2">
            {{
              getCountStatement.amount
                ? currencyFormat(getCountStatement.amount)
                : 0
            }} ៛
          </td>
          <td
            class="text-xs-center pl-4 total-style font-weight-bold  py-2"
            :style="
              getCountStatement.amount_win < 0
                ? 'color:#BA2D4A'
                : 'color:#39afd6'
            "
          >
            {{
              getCountStatement.amount_win
                ? currencyFormat(
                    getCountStatement.amount_win
                  )
                : 0
            }}
            ៛
          </td>
          <td class="text-xs-center total-style pl-4  py-2">
            
          </td>
        </tr>
      </template>
    </v-data-table>

<!-- Detail data for Lotto80, Apong and Cock fight #2steps -->

    <v-dialog
        :value="dialogN"
        max-width="1200"
        persistent
        transition="dialog-bottom-transition"
    >
      <v-card >
        <v-toolbar>
          <v-btn icon  @click="dialogN=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="pl-5">
            <v-col cols="6" sm="6" md="3" class="text-left mt-4">
              <v-toolbar-title>{{title}}</v-toolbar-title>
            </v-col>
            <v-col cols="6" sm="6" md="3" class="text-left mt-4">
                
            </v-col>
              <v-col cols="8" sm="8" md="4" class="text-right">
                <v-text-field
                    v-model="v_search"
                    append-icon="mdi-magnify"
                    label="Search ( Fight no, គូ ប្រកួត )"
                    single-line
                    hide-details
                    clearable
                    class="mr-5"
                />
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="4" sm="4" md="2" class="text-right mb-5">
                <v-btn
                    class="white--text btn-custom mr-4 mt-3"
                    color="green darken-1"
                    depressed
                    @click="searchq"
                >
                  <!-- @click="fetchListStatement(params)" -->
                  SEARCH
                </v-btn>
              </v-col>
          </v-row>
          <v-data-table
              style="font-family: 'khmer mef1'"
              class="elevation-1"
              item-key="date"
              :headers="headersDetailN"
              :footer-props="footerProps"
              :items="detailData ? detailData : []"
              :server-items-length="detailDataCount ? parseInt(detailDataCount.count): null"
              :sort-desc.sync="dataRequest.descendingFormat"
              :sort-by.sync="dataRequest.sortByFormat"
              :page.sync="dataRequest.page"
              :items-per-page.sync="dataRequest.rowsPerPage"
              @update:page="updatePage"
              @update:items-per-page="updatePage"
              @update:sort-desc="updatePage"
              :loading="getLoadingDetailCockFight"
              mobile-breakpoint="0"
          >
            <template v-slot:[`item`]="{ item }">
              <tr>
                <td>{{ detailData ? (detailData.indexOf(item) + ( (dataRequest.page -1 ) *  dataRequest.rowsPerPage)) + 1 :"loading ..." }}</td>
                <td>{{ item.date }}</td>
                <td>{{ item.channel_type }}</td>
                <td v-if="module_id == '62e48485abc48561de3a470f'">{{ item.no }}</td>
                <td v-else>{{ item.fight_no }}</td>
                <td v-if="module_id == '62dfdf855ef1fb28515a1a71'">{{ item.co_type_of_betting.name }}</td>
                <td v-if="module_id == '62f92808016e8046cccee5bc'">{{ item.sn_number.name }}</td>
                <td v-if="module_id == '62e48485abc48561de3a470f'">{{ item.type_of_betting.name }}</td>
                <td v-if="module_id == '62dfdf855ef1fb28515a1a71'">{{ item.co_result.name }}</td>
                <td v-if="module_id == '62f92808016e8046cccee5bc'">{{ item.sn_result }}</td>
                <td v-if="module_id == '62e48485abc48561de3a470f'">{{ item.result.name }}</td>
                <td class="to-detail" style="color: goldenrod;">{{item.amount? currencyFormat(item.amount) :0 }} ៛ </td>
                <td :style="item.amount_win < 0 ? 'color:#BA2D4A': 'color:#39afd6'">{{item.amount_win? currencyFormat(item.amount_win) :0}} ៛ </td>
                 <td>{{ item.cast? currencyFormat(item.cast) : 0}} ៛ </td>
              </tr>
            </template>
            <template slot="body.append">
        <tr>
          <td :colspan="6" class="text-xs-center pl-4 py-2 ">
            {{ $t("title.total") }}
          </td>
          <td class="text-xs-center total-style pl-4  py-2">
            {{
              detailDataCount.amount
                ? currencyFormat(detailDataCount.amount)
                : 0
            }} ៛
          </td>
          <td
            class="text-xs-center pl-4 total-style font-weight-bold  py-2"
            :style="
              detailDataCount.amount_win < 0
                ? 'color:#BA2D4A'
                : 'color:#39afd6'
            "
            v-if="module_id == '62dfdf855ef1fb28515a1a71'"
          >
            {{
              detailDataCount.amount_win
                ? currencyFormat(
                  detailDataCount.amount_win
                  )
                : 0
            }}
            ៛
          </td>
          <td
            class="text-xs-center pl-4 total-style font-weight-bold  py-2"
            :style="
              detailDataCount.amount_win + detailDataCount.amount_lose < 0
                ? 'color:#BA2D4A'
                : 'color:#39afd6'
            "
            v-if="module_id == '62f92808016e8046cccee5bc'"
          >
            {{
              detailDataCount.amount_win
                ? currencyFormat(
                  detailDataCount.amount_win + detailDataCount.amount_lose
                  )
                : 0
            }}
            ៛
          </td>
          <td
            class="text-xs-center pl-4 total-style font-weight-bold  py-2"
            :style="
              detailDataCount.amount_win + detailDataCount.amount_lose < 0
                ? 'color:#BA2D4A'
                : 'color:#39afd6'
            "
            v-if="module_id == '62e48485abc48561de3a470f'"
          >
            {{
              detailDataCount.amount_win
                ? currencyFormat(
                  detailDataCount.amount_win + detailDataCount.amount_lose
                  )
                : 0
            }}
            ៛
          </td>
          <td class="text-xs-center total-style pl-4  py-2">
            
          </td>
        </tr>
      </template>
          </v-data-table>

          
        </v-card-text>
      </v-card>
    </v-dialog>

<!-- Detail data for Lotto plus and Yuki #3steps -->
    <v-dialog
        :value="dialog"
        max-width="1200"
        persistent
        transition="dialog-bottom-transition"
    >
      <v-card >
        <v-toolbar>
          <v-btn icon  @click="dialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="pl-5">
            <v-col cols="6" sm="6" md="3" class="text-left mt-4">
              <v-toolbar-title>{{title}}</v-toolbar-title>
            </v-col>
            <v-col cols="6" sm="6" md="3" class="text-left mt-4">
                
            </v-col>
              <v-col cols="8" sm="8" md="4" class="text-right">
                <v-text-field
                    v-model="v_search"
                    append-icon="mdi-magnify"
                    label="Search ( Fight no, គូ ប្រកួត )"
                    single-line
                    hide-details
                    clearable
                    class="mr-5"
                />
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="4" sm="4" md="2" class="text-right mb-5">
                <v-btn
                    class="white--text btn-custom mr-4 mt-3"
                    color="green darken-1"
                    depressed
                    @click="searchq"
                >
                  <!-- @click="fetchListStatement(params)" -->
                  SEARCH
                </v-btn>
              </v-col>
          </v-row>
          <v-data-table
              style="font-family: 'khmer mef1'"
              class="elevation-1"
              item-key="date"
              :headers="headersDetail"
              :footer-props="footerProps"
              :items="detailData ? detailData : []"
              :server-items-length="detailDataCount ? parseInt(detailDataCount.count): null"
              :sort-desc.sync="dataRequest.descendingFormat"
              :sort-by.sync="dataRequest.sortByFormat"
              :page.sync="dataRequest.page"
              :items-per-page.sync="dataRequest.rowsPerPage"
              @update:page="updatePage"
              @update:items-per-page="updatePage"
              @update:sort-desc="updatePage"
              :loading="getLoadingDetail"
              mobile-breakpoint="0"
          >
            <template v-slot:[`item`]="{ item }">
              <tr class="to-detail" @click="_goToInvoiceDetail(item.user_name,item.app_id,item.invoice_id,item.module_id)">
                <td>{{ detailData ? (detailData.indexOf(item) + ( (dataRequest.page -1 ) *  dataRequest.rowsPerPage)) + 1 :"loading ..." }}</td>
                <td>{{ item.time }}</td>
                <td>{{ item.invoice_id }}</td>
                <td>{{ item.channel_type }}</td>
                <td>{{ item.fight_no }}</td>
                <td class="to-detail" style="color: goldenrod;">{{ currencyFormat(item.amount) }} ៛ </td>
                <td :style="item.amount_win < 0 ? 'color:#BA2D4A': 'color:#39afd6'">{{ currencyFormat(item.amount_win)}} ៛ </td>
                <!-- <td>{{ currencyFormat(item.amount_win)}} ៛ </td> -->
                 <td>{{ currencyFormat(item.cast)}} ៛ </td>
              </tr>
            </template>
            
          </v-data-table>

          
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
        :value="invoiceDialog"
        max-width="1200"
        persistent
        transition="dialog-bottom-transition"
    >
      <v-card >
        <v-toolbar>
          <v-btn icon  @click="invoiceDialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="pl-5">
            <v-col cols="6" sm="6" md="3" class="text-left mt-4">
              <v-toolbar-title>Invoice Detail</v-toolbar-title>
            </v-col>
            <v-col cols="6" sm="6" md="3" class="text-left mt-4">
                
            </v-col>
          </v-row>
          <v-data-table
              style="font-family: 'khmer mef1'"
              class="elevation-1"
              item-key="date"
              :headers="invoiceDetail"
              :footer-props="footerProps"
              :items="invoiceDetailData ? invoiceDetailData : []"
              :server-items-length="invoiceDetailData ? parseInt(invoiceDetailData.length): null"
              :sort-desc.sync="dataRequest.descendingFormat"
              :sort-by.sync="dataRequest.sortByFormat"
              :page.sync="dataRequest.page"
              :items-per-page.sync="dataRequest.rowsPerPage"
              :loading="getLoadingInvoiceDetail"
              mobile-breakpoint="0"
          >
            <template v-slot:[`item`]="{ item }">
              <tr>
                <td>{{ invoiceDetailData ? (invoiceDetailData.indexOf(item) + ( (dataRequest.page -1 ) *  dataRequest.rowsPerPage)) + 1 :"loading ..." }}</td>
                <td>{{ item.date }}</td>
                <td>{{ item.invoice_no }}</td>
                <td>{{ item.no }}</td>
                <td>
                  <v-chip label :color=item.type_of_betting.color style="padding:15px;color:#ffffff; font-size:13px;height:22px">
                      <span class="white--text">{{item.type_of_betting.name}} : {{item.type_of_betting.number_label}}</span>
                  </v-chip>
                </td>
                <td>
                  <v-btn
                    class="mx-1"
                    fab
                    dark
                    x-small
                    :color="item.result_a.color"
                  >
                    {{item.result_a.number_label}}
                  </v-btn>
                  <v-btn
                    class="mx-1"
                    fab
                    dark
                    x-small
                    :color="item.result_b.color"
                  >
                    {{item.result_b.number_label}}
                  </v-btn>
                  <v-btn
                    class="mx-1"
                    fab
                    dark
                    x-small
                    :color="item.result_c.color"
                  >
                    {{item.result_c.number_label}}
                  </v-btn>
                   <v-btn
                    class="mx-1"
                    fab
                    dark
                    x-small
                    :color="item.result_d.color"
                  >
                    {{item.result_d.number_label}}
                  </v-btn>
                   <v-btn
                    class="mx-1"
                    fab
                    dark
                    x-small
                    :color="item.result_e.color"
                  >
                    {{item.result_e.number_label}}
                  </v-btn>
                </td>
                <td class="to-detail" style="color: goldenrod;">
                   {{currencyFormat(item.amount)}} ៛
                </td>
                <td  >{{ item.payout }}</td>
                <td :class="item.amount_win < 0 ? 'lose-color font-weight-bold' :'win-color font-weight-bold'">{{item.amount_win ? currencyFormat(item.amount_win) : 0 }} ៛</td>
                <td>{{item.cast ?  currencyFormat(item.cast) : 0}} ៛</td>
              </tr>
            </template>
            
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    
  <v-dialog
        :value="invoiceDialogYuki"
        max-width="1200"
        persistent
        transition="dialog-bottom-transition"
    >
      <v-card >
        <v-toolbar>
          <v-btn icon  @click="invoiceDialogYuki=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="pl-5">
            <v-col cols="6" sm="6" md="3" class="text-left mt-4">
              <v-toolbar-title>Detail Player Statement</v-toolbar-title>
            </v-col>
            <v-col cols="6" sm="6" md="3" class="text-left mt-4">
                
            </v-col>
            
              <v-spacer></v-spacer>
             
          </v-row>
          <v-data-table
            style="font-family: 'khmer mef1'"
            class="elevation-1"
            item-key="date"
            :headers="headersInvoiceDetail"
            :footer-props="footerProps"
            :items="invoiceDetailData ? invoiceDetailData : []"
            :server-items-length="invoiceDetailData ? parseInt(invoiceDetailData.count): null"
            :sort-desc.sync="dataRequest.descendingFormat"
            :sort-by.sync="dataRequest.sortByFormat"
            :page.sync="dataRequest.page"
            :items-per-page.sync="dataRequest.rowsPerPage"
            @update:page="updatePage"
            @update:items-per-page="updatePage"
            @update:sort-desc="updatePage"
            :loading="getLoadingInvoiceDetail"
            mobile-breakpoint="0"
          >
            <template v-slot:[`item`]="{ item }">
              <tr>
                <td>{{ invoiceDetailData ? (invoiceDetailData.indexOf(item) + ( (dataRequest.page -1 ) *  dataRequest.rowsPerPage)) + 1 :"loading ..." }}</td>
                <td>{{ item.date }}</td>
                <td>{{ item.no }}</td>
                <td :class="item.type_of_betting.name">
                  {{ item.type_of_betting.name }}
                </td>
                <td :class="item.result.name">
                  {{ item.result.name }}
                </td>
                <td class="to-detail" style="color: goldenrod;">
                   
                   {{currencyFormat(item.amount)}} ៛
                </td>
                <td  >{{ item.payout }}</td>
                <td :class="item.amount_win < 0 ? 'lose-color font-weight-bold' :'win-color font-weight-bold'">{{item.amount_win ? currencyFormatEn(item.amount_win) : 0 }} ៛</td>
                <td>{{item.cast ?  currencyFormat(item.cast) : 0}}</td>
              </tr>
            </template>
            <!-- <template slot="body.append">
              <td :colspan="5" class="text-xs-center pl-4 py-2 ">
                {{ $t("title.total") }}
              </td>
              <td :class=" invoiceDetailDataCount.amount < 0 ? 'lose-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold' :'win-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold'">
               
                {{ invoiceDetailDataCount ? (invoiceDetailDataCount.amount) : "loading ..."}}  
              </td>
              <td></td>
              <td :class=" invoiceDetailDataCount.amount_win + invoiceDetailDataCount.amount_lose< 0 ? 'lose-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold' :'win-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold'">
                
                {{ invoiceDetailDataCount ? currencyFormatEn(invoiceDetailDataCount.amount_win + invoiceDetailDataCount.amount_lose) : "loading ..."}}  
              </td>
              
            </template> -->
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import headers from "./_api/columns";
import moment from "moment";
import headersDetail from "./_api/columns_detail";
import headersDetailN from "./_api/columns_detail_n";
import invoiceDetail from "./_api/invoice_detail";
import headersInvoiceDetail from "./_api/invoice_yuki_detail";

export default {
  components: {
    // ActionComponents: () => import("./_components/Action"),
    Header: () => import("./_components/HeaderTable.vue"),
  },

  data: () => ({
    v_search:null,
    valid: true,
    headers: headers,
    headersDetail:headersDetail,
    headersDetailN:headersDetailN,
    invoiceDetail: invoiceDetail,
    headersInvoiceDetail:headersInvoiceDetail,
    search: null,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    dataRequest:{
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      user_id: "",
      module_id: null,
      fields: ["module_bet_id","fight_no","group_no"],
      search: "",
      channelType:  0,
    },
    data: {
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: ["_id.module_id","_id.module_name","_id.player"],
      search: "",
      startDate:  moment().format("YYYY-MM-DD"),
      endDate:  moment().format("YYYY-MM-DD"),
      module_id: null,
    },
    dataInvoiceRequest:{
      userName: "",
      appId: "",
      invoice_id: "",
      module_id:null
    },
    dialog: false,
    dialogN: false,
    invoiceDialog: false,
    invoiceDialogYuki: false,
    loading: false,
    loader: false,
    user_id: null,
    detailData:[],
    detailDataCount:[],
    invoiceDetailData: [],
    title:'',
    //module_id: '',
    // invoiceDetailDataCount: [],
    check: {
      startDate: moment().format("YYYY-MM-DD"),
      endDate:  moment().format("YYYY-MM-DD"),
    },
    format: "YYYY-MM-DD",
    defaultFilterDates: [
      {
        name: "Today",
        active: false,
      },
      {
        name: "Yesterday",
        active: false,
      },
      {
        name: "This Week",
        active: false,
      },
      {
        name: "Last Week",
        active: false,
      },
      {
        name: "This Month",
        active: false,
      },
      {
        name: "Last Month",
        active: false,
      },
    ],
    totalItems: 0,
    time: null,
    getLoadingDetail:false,
    getLoadingDetailCockFight:false,
    getLoadingInvoiceDetail:false,
    user_iid: null,
    module_id: null
  }),
  watch: {
    user: function() {
      this.getData();
    },
    data: function() {
      this.getData();
    },
    getStatement: function() {},
  },
  computed: {
    ...mapGetters("$_modules", { darkmode: "getDarkMode", user: "getUser" }),
    reportUrl: function() {
      if (this.user) {
        if (this.user.role_name == "DEVELOPER" || this.user.role_name == "MANAGER") return "getViewStatement";
        else if (this.user.role_name == "COMPANY-ACC") return "getViewStatement";
        else if (this.user.role_name == "COMPANY" || this.user.role_name == "SUB-COMPANY") return "getViewStatement";
        else if (this.user.role_name == "SENIOR-D" || this.user.role_name == "SUB-SENIOR-D") return "getViewStatement";
        else if (this.user.role_name == "MASTER-D" || this.user.role_name == "SUB-MASTER-D") return "getViewStatement";
        else if (this.user.role_name == "AGENT-D" || this.user.role_name == "SUB-AGENT-D") return "getViewStatement";
        else return null;
      } else return null;
    },
    ...mapGetters("$_statementKh", ["getStatement", "getCountStatement","getLoading","getModule"]),
  },
  async created() {
    this.$cookies.set("currency-type", 2);
    await this.getData();
    await this.fetchModule();
  },
  methods: {
    moment,
    _goToDetail(user_id, module_id, userName){
       this.user_iid = user_id;
       this.module_id = module_id;
       if(module_id == "62dfe1d65ef1fb28515a1a75" || module_id == "62dfdfb85ef1fb28515a1a72"){
        this.loadDetailData(user_id, module_id);
        this.dialog = true;
       }else{
        this.loadDetailDataCockFight(user_id, module_id,userName);
        this.dialogN = true;
       }
       this.changeTittle(module_id);
       
      
    },
    changeTittle(module_id){
      if(module_id == "62dfe1d65ef1fb28515a1a75"){
        this.title = "Lotto Plus Invoice";
      }else if(module_id == "62f92808016e8046cccee5bc"){
        this.title = "Apong Invoice";
      }else if(module_id == "62dfdfb85ef1fb28515a1a72") {
        this.title = "Yuki Invoice";
      }else if(module_id == "62dfdf855ef1fb28515a1a71") {
        this.title = "Cock Fight Invoice";
      }else{
        this.title = "Lotto80 Invoice";
      }
    },
    _goToInvoiceDetail(userName, appId,invoice_id,module_id){
      if(module_id == "62dfdfb85ef1fb28515a1a72"){
        this.loadInvoiceYukiDetailData(userName, appId,invoice_id,module_id);
        this.invoiceDialogYuki = true;
        
      }else if(module_id == "62dfe1d65ef1fb28515a1a75"){
        // lotto plus
        this.loadInvoiceDetailData(userName, appId,invoice_id,module_id);
        this.invoiceDialog = true;
      }
      //  this.loadInvoiceDetailData(userName, appId,invoice_id,module_id);
      // this.invoiceDialog = true;
    },
    searchq() {
      this.dataRequest.search = this.v_search;
      this.loadDetailData(this.user_iid, this.module_id);
    },
    updatePage() {
      this.getLoadingDetail = true;
      this.loadDetailData(this.user_iid, this.module_id);
      this.getLoadingDetail = false;
    },
    updateParent(payload){
      this.data.startDate = payload.startDate;
      this.data.endDate = payload.endDate;
      this.data.module_id = payload.module_id;
    },
    async loadDetailData(user_id, module_id){
     
      this.detailData = [];
      this.detailDataCount = [];
      this.getLoadingDetail = true;
      
      this.dataRequest.startDate = this.data.startDate;
      this.dataRequest.endDate = this.data.endDate;
       this.dataRequest.sortByFormat.length < 1
        ? (this.dataRequest.sortBy = "_id")
        : (this.dataRequest.sortBy = this.dataRequest.sortByFormat[0]);
      this.dataRequest.descendingFormat.length > 0
        ? (this.dataRequest.descending = this.dataRequest.descendingFormat[0])
        : (this.dataRequest.descending = true);

      this.dataRequest.user_id = user_id;
      this.dataRequest.module_id = module_id;
      
     await this.$request
        .post({
          url: "invoice",
          data: this.dataRequest,
        })
        .then((res) => {
          if (res.data.code) {
            this.detailData = res.data.data.object;
            this.detailDataCount = res.data.data.objectCount;
            this.getLoadingDetail = false;
          }else{
            
            this.getLoadingDetail = false;
          }
        });
    },
    async loadDetailDataCockFight(user_id, module_id,userName){
      this.detailData = [];
      this.detailDataCount = [];
      this.getLoadingDetailCockFight = true;
      
      this.dataRequest.startDate = this.data.startDate;
      this.dataRequest.endDate = this.data.endDate;
       this.dataRequest.sortByFormat.length < 1
        ? (this.dataRequest.sortBy = "_id")
        : (this.dataRequest.sortBy = this.dataRequest.sortByFormat[0]);
      this.dataRequest.descendingFormat.length > 0
        ? (this.dataRequest.descending = this.dataRequest.descendingFormat[0])
        : (this.dataRequest.descending = true);

      this.dataRequest.user_id = user_id;
      this.dataRequest.module_id = module_id;
      this.dataRequest.userName = userName;
      this.dataRequest.channelType = 0;
     await this.$request
        .post({
          url: "invoice",
          data: this.dataRequest,
        })
        .then((res) => {
          if (res.data.code) {
            
            this.detailData = res.data.data.object;
            this.detailDataCount = res.data.data.objectCount;
            this.getLoadingDetailCockFight = false;
          }else{
            
            this.getLoadingDetailCockFight = false;
          }
        });
    },
    async loadInvoiceDetailData(userName, appId,invoice_id,module_id){
     
      this.invoiceDetailData = [];
      // this.invoiceDetailDataCount = [];
      this.getLoadingInvoiceDetail = true;
     
      this.dataInvoiceRequest.userName = userName;
      this.dataInvoiceRequest.appId = appId;
      this.dataInvoiceRequest.invoice_id = invoice_id;
      this.dataInvoiceRequest.module_id = module_id;
      
     await this.$request
        .post({
          url: "inoiceDetailKH",
          data: this.dataInvoiceRequest,
        })
        .then((res) => {
          if (res.data.code) {
            this.invoiceDetailData = res.data.data;
            // this.invoiceDetailDataCount = res.data.data;
            this.getLoadingInvoiceDetail = false;
           
          }else{
            
            this.invoiceDetailData = false;
          }
        });
    },
    async loadInvoiceYukiDetailData(userName, appId,invoice_id,module_id){
     
     this.invoiceDetailData = [];
    //  this.invoiceDetailDataCount = [];
     this.getLoadingInvoiceDetail = true;
    
     this.dataInvoiceRequest.userName = userName;
     this.dataInvoiceRequest.appId = appId;
     this.dataInvoiceRequest.invoice_id = invoice_id;
     this.dataInvoiceRequest.module_id = module_id;
     
    await this.$request
       .post({
         url: "inoiceDetailKH",
         data: this.dataInvoiceRequest,
       })
       .then((res) => {
         if (res.data.code) {
           this.invoiceDetailData = res.data.data;
          //  this.invoiceDetailDataCount = res.data.data;
           this.getLoadingInvoiceDetail = false;
         }else{
           this.invoiceDetailData = false;
         }
       });
   },
    selectDate({ name }) {
      const { format } = this;
      switch (name) {
        case "Today":
          this.startDate = this.moment().format(format);
          this.endDate = this.moment().format(format);
          break;
        case "Yesterday":
          this.startDate = this.moment()
            .subtract(1, "days")
            .format(format);
          this.endDate = this.moment().subtract(1, "days").format(format);
          break;
        case "This Week":
          this.startDate = this.moment()
            .startOf("isoweek")
            .format(format);
          this.endDate = this.moment()
            .endOf("isoWeek")
            .format(format);
          break;
        case "Last Week":
          this.startDate = this.moment()
            .subtract(1, "isoweek")
            .startOf("isoweek")
            .format(format);
          this.endDate = this.moment()
            .subtract(1, "isoweek")
            .endOf("isoweek")
            .format(format);
          break;
        case "This Month":
          this.startDate = this.moment().format("YYYY-MM-01");
          this.endDate = this.moment().format(format);
          break;
        case "Last Month":
          this.startDate = this.moment()
            .subtract(1, "months")
            .startOf("month")
            .format(format);
          this.endDate = this.moment()
            .subtract(1, "months")
            .endOf("month")
            .format(format);
          break;
        default:
          break;
      }
      const { startDate, endDate } = this;
      this.time = { startDate: startDate, endDate: endDate };
      this.data.startDate = startDate;
      this.data.endDate = endDate;
      this.getData();
      this.check = { startDate, endDate };
    },
    // Gets the checkbox information from the child component
    checkboxValue: function(params) {
      this.check = params;
      this.data.startDate = params.startDate;
      this.data.endDate = params.endDate;
    },
    currencyFormatEn(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    currencyFormat(num) {
      num = parseFloat(num);
      return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      // return parseInt(num).toLocaleString('en')
    },
    async getData() {
      try {
        this.loader = true;
      
          let preData = {
            data: this.data,
            reportUrl: this.reportUrl,
          };
          await this.fetchListStatement(preData);
         
          this.loader = false;
          
      } catch (error) {
        // console.log("")
      }
    },
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
          begin = dc.indexOf(prefix);
          if (begin != 0) return null;
      }
      else
      {
          begin += 2;
          var end = document.cookie.indexOf(";", begin);
          if (end == -1) {
          end = dc.length;
          }
      }
      // because unescape has been deprecated, replaced with decodeURI
      //return unescape(dc.substring(begin + prefix.length, end));
      return decodeURI(dc.substring(begin + prefix.length, end));
  } ,
    ...mapMutations("$_statementKh", ["LIST_STATEMENT", "COUNT-STATEMENT","LIST_MODULE"]),
    ...mapActions("$_statementKh", ["fetchListStatement","fetchModule"]),
  },
};
</script>

<style lang="scss" scoped>
.users {
  width: 50%;
  float: left;

  h3 {
    font-size: 17px;
  }
}

table {
  font-family: "khmer mef1" !important;
}
.amount-bet-color {
  color: goldenrod !important;
}
.win-color {
  color: rgb(57, 175, 214);
  font-weight: bold;
}
.lose-color {
  color: #ba2d4a;
  font-weight: bold;
}
.tab-filter {
  display: flex;
  flex-direction: row;
}
.row {
  display: flex;
  flex-direction: row;
}
.between {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.w-full {
  width: 100%;
}
.to-detail{
  cursor: pointer;
  // text-decoration: underline;
}

</style>
