var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"font-family":"'khmer mef1'"},attrs:{"headers":_vm.headers,"items":_vm.getUsers ? _vm.getUsers.object : [],"footer-props":_vm.footerProps,"item-key":"_id","server-items-length":_vm.getUsers ? _vm.getUsers.objectCount : null,"page":_vm.data.page,"items-per-page":_vm.data.rowsPerPage,"sort-desc":_vm.data.descendingFormat,"sort-by":_vm.data.sortByFormat,"show-select":"","loading":_vm.getLoading,"value":_vm.getDeleteItems,"mobile-breakpoint":"0"},on:{"update:page":[function($event){return _vm.$set(_vm.data, "page", $event)},_vm.getData],"update:itemsPerPage":function($event){return _vm.$set(_vm.data, "rowsPerPage", $event)},"update:items-per-page":[function($event){return _vm.$set(_vm.data, "rowsPerPage", $event)},_vm.getData],"update:sortDesc":function($event){return _vm.$set(_vm.data, "descendingFormat", $event)},"update:sort-desc":[function($event){return _vm.$set(_vm.data, "descendingFormat", $event)},_vm.getData],"update:sortBy":function($event){return _vm.$set(_vm.data, "sortByFormat", $event)},"update:sort-by":function($event){return _vm.$set(_vm.data, "sortByFormat", $event)},"input":_vm.itemDelete},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('Header',{attrs:{"darkmode":_vm.darkmode,"params":_vm.data,"dataDelete":_vm.getDeleteItems,"userp":_vm.userp},on:{"child-checkbox":_vm.transactionRefresh}})]},proxy:true},{key:"item.enable",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":item.enable ? 'success' : 'error',"disabled":_vm.userp && !_vm.userp.enable? true:false},on:{"click":function($event){return _vm._openDialogSuspend(item)}}},[_vm._v(" "+_vm._s(item.enable ? "Active" : "Inactive")+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertDateTime(item.createdAt))+" ")]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [(item.role_id ==='612a3fcc1a1fcead8871e822')?_c('span',[(item.company_balance[0])?_c('div',[_vm._v(" "+_vm._s(_vm.currencyFormatUsd(item.company_balance[0].balance) +'$, ')+" ")]):_vm._e(),(item.company_balance[1])?_c('div',[_vm._v(" "+_vm._s(_vm.currencyFormatKh(item.company_balance[1].balance) +'៛')+" ")]):_vm._e()]):_vm._e(),(item.role_id !=='612a3fcc1a1fcead8871e822' && (item.currency_type===1) )?_c('span',[_vm._v(" "+_vm._s(_vm.currencyFormatUsd(item.balance) +'$')+" ")]):_vm._e(),(item.role_id !=='612a3fcc1a1fcead8871e822' && item.currency_type===2)?_c('span',[_vm._v(" "+_vm._s(_vm.currencyFormatKh(item.balance) +'៛')+" ")]):_vm._e()]}},{key:"item.public_ip",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm._openDialogListIpUser(item)}}},[_vm._v(" "+_vm._s(item.public_ip)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('ActionComponents',{attrs:{"darkmode":_vm.darkmode,"item":item,"dataDelete":_vm.getDeleteItems,"params":_vm.data,"userp":_vm.userp,"user-permission":_vm.userAccessPermission},on:{"child-checkbox":_vm.transactionRefresh}})]}},{key:"item.transaction",fn:function(ref){
var item = ref.item;
return [_c('TransactionComponents',{attrs:{"darkmode":_vm.darkmode,"item":item,"params":_vm.data,"userp":_vm.userp},on:{"refresh":_vm.transactionRefresh}})]}}],null,true)}),_c('DialogListIpUserComponents',{attrs:{"dialog":_vm.ipDialog,"listData":_vm.listIpUserData},on:{"onCloseDialog":function($event){_vm.ipDialog = !_vm.ipDialog}}}),_c('Dialog',{attrs:{"darkmode":_vm.darkmode,"loading":_vm.loading,"user":_vm.getCurrentUser,"dialog":_vm.getPopUp,"userp":_vm.userp,"optionRoles":_vm.optionRoles,"is-validate":_vm.valid,"listCompany":_vm.getListUserCompany,"listModule":_vm.getListUserModule,"listChildModule":_vm.getListUserChildModule,"selectedChannel":_vm.selectedChannel},on:{"submit":_vm.handleSubmit,"handleListCompany":_vm.getListCompany,"onCloseDialog":_vm.closeDialogCreate}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.suspendDialog),callback:function ($$v) {_vm.suspendDialog=$$v},expression:"suspendDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 py-5"},[_vm._v(" do you want to this user and its children ")]),_c('v-card-actions',[_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"blue-grey"},on:{"click":_vm._closeDialogSuspend}},[_vm._v("Cancel")]),_c('v-spacer'),_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":_vm.selectedUser && _vm.selectedUser.enable ?  'error':'success'},on:{"click":function($event){return _vm._updateUserSuspendStatus()}}},[_vm._v(_vm._s(_vm.selectedUser && _vm.selectedUser.enable ? "Inactive Channel" :"Active Channel"))])],1)],1)],1),(this.waiting == true)?_c('loader',{attrs:{"object":"#ff9633","color1":"#ffffff","color2":"#17fd3d","size":"5","speed":"2","bg":"#343a40","objectbg":"#999793","opacity":"80","name":"circular"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }