const UPDATE_MODULES = (state, data) => {
    state.modules = data;
};

const UPDATE_POPUP = (state, data) => {
    if (!data) {
        state.currentModule =  {
            name: null,
            desc: null,
            is_enable: null,
            front_player_link: null,
            api_player_link: null,
            api_admin_link: null,
            background_icon: null,
            app_id: null,
            parent_id: null,
            small_icon: null,
            large_icon: null,
            order: null
        }
    }
    state.popUp = data;
};

const UPDATE_CURRENT_MODULE = (state, data) => {
    state.currentModule = data;
};


const DELETE_ITEMS = (state, data) => {
    state.deleteItems = data
}


const UPDATE_LOADING = (state, data) => {
    state.loading = data
}
const UPDATE_PARENT_MODULE = (state, data) => {
    console.log("data",data);
	state.moduleParent = data;
};

export default {
    UPDATE_LOADING,
    UPDATE_MODULES,
    UPDATE_POPUP,
    UPDATE_CURRENT_MODULE,
    DELETE_ITEMS,
    UPDATE_PARENT_MODULE
};
